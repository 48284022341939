import React from 'react';
import styled from "styled-components";

const BodyWrapper = styled.div`
display: flex;
  flex-direction: column;
  width: 100%;
  
`

const RevealColumnWrapper = (props) => {

    if (props.expanded) {
        return (
            <BodyWrapper>
                {props.children}
            </BodyWrapper>
        )
    } else {
        return (
            <>

            </>
        )
    }



};

export default RevealColumnWrapper;
