import React, {useEffect, useState, useCallback} from 'react'
import styled from "styled-components"
import ListFilterDropdown from "../ListFilterDropdown/ListFilterDropdown";
import useFilterListSet from "../../../../library/helpers/hooks/useFilterListSet";

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const defaultListArray = [
    {id: 'abc123xxx', name: 'Some Name 1', checked: true, job: 'Job 1'},
    {id: 'abc234xxx', name: 'Some Name 2', checked: false, job: 'Job 2'},
    {id: 'abc345xxx', name: 'Some Name 3', checked: true, job: 'Job 3'},
] // can be any list of objects

const defaultFilterFieldList = [
    {field: 'name', label: 'Display Name #1', filter: '', type: 'string'},
    {field: 'job', label: 'Display Name #2'} // filter and type optional
]

const ListFilterDropdownSet = ({
       listArrayIn = defaultListArray,
       fieldFiltersArrayIn = defaultFilterFieldList,
       handleChangeOut = () => {}
    }) => {

    const [dropdownLists, setDropdownLists] = useState([])

    const [filteredList, setFilterStrings] = useFilterListSet(listArrayIn, fieldFiltersArrayIn)

    useEffect(() => {
        handleChangeOut(filteredList)
    }, [filteredList, handleChangeOut])

    useEffect(() => {
        setDropdownLists(prev => {
            return fieldFiltersArrayIn.map((fieldFiltersObject) => {
                return {
                    label: fieldFiltersObject.label,
                    data: listArrayIn.map((listObject, i) => {
                        return {
                            value: i,
                            label: listObject[fieldFiltersObject.field],
                            data: listObject,
                        }
                    })
                }
            })
        })
    }, [fieldFiltersArrayIn, listArrayIn])


    return dropdownLists.map((dropdownObject, i) => {

        const handleSelectFilter = (index) => {
            if (index === -1) {
                setFilterStrings('', i)
            } else {
                setFilterStrings(dropdownObject.data[index].label, i)
            }
        }

        return (
            <ListWrapper
                key={dropdownObject.data.id}
            >
                <ListFilterDropdown
                    labelStringIn={dropdownObject.label}
                    listArrayIn={dropdownObject.data}
                    onSelectOut={handleSelectFilter}
                />
            </ListWrapper>
        )
    })


}

export default ListFilterDropdownSet