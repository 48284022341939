import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DragDropContext} from "react-beautiful-dnd";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import LoaderWrapper from "../../../../templates/wrappers/LoaderWrapper";
import TaskItemColumn from "./components/TaskItemColumn";
import TaskGroupColumn from "./components/TaskGroupColumn";
import SimpleDropdown from "../../../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import useRESTCRUD from "../../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import {mapTaskGroupProcessServerToTaskGroupProcessObject} from "../../../../../library/helpers/functions/dataMaps";
import filterFilterLists from "../../../Document/library/helpers/filterFilterLists";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const DraggableWrapper = styled.div`
  display: flex;
  
`

const CenteredDialogButtonWrapper = styled.div`
  width: 100%;
  padding: 10px;
display: flex;
  flex-direction: row;
  justify-content: center;
  .MuiDialogActions-root {
    width: 100%;
    justify-content: center;
  }

`

const InnerWrapper = styled.div`
  width: 1600px;
  padding: 0 20px;
`

const TaskItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px;
border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 20px;
`


const TaskGroupsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 30px;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 20px;
`

const Title = styled.h3`
  width: 100%;
  text-align: center;
    padding: 8px;
`

const initData = {
    tasks: {
        'task-1': {id: 'task-1', content: 'Eat'},
        'task-2': {id: 'task-2', content: 'Sleep'},
        'task-3': {id: 'task-3', content: 'Shit'},
        'task-4': {id: 'task-4', content: 'Fuck'},
        'task-5': {id: 'task-5', content: 'Die'},
    },
    columns: {
        'column-1': {
            id: 'column-1',
            title: 'Task Items',
            taskIds: [
                'task-1',
                'task-2',
                'task-3',
                'task-4',
                'task-5',
            ]
        },
        'column-2': {
            id: 'column-2',
            title: 'Task Groups',
            taskIds: []
        },

    },
    columnOrder: ['column-1', 'column-2']
}

const taskItemInit = [
    {
        id: 'uuid1',
        task: 'Task Item One',
    },
    {
        id: 'uuid2',
        task: 'Task Item Two',
    },
    {
        id: 'uuid3',
        task: 'Task Item Three',
    },
    {
        id: 'uuid4',
        task: 'Task Item Four',
    },
    {
        id: 'uuid5',
        task: 'Task Item Five',
    },
]

const taskGroupInit = [
    {
        id: 'uuid6',
        task: 'Task Group One',
    },
    {
        id: 'uuid7',
        task: 'Task Group Two',
    },
    {
        id: 'uuid8',
        task: 'Task Group Three',
    },
    {
        id: 'uuid9',
        task: 'Task Group Four',
    },
    {
        id: 'uuid10',
        task: 'Task Group Five',
    },
]

const tagList = [
    {value: 'lalala', data: {id: 'qwertyuio'}, label: 'hahaha'}
]

const ReOrderTasks = (props) => {

    const {
        todoObjectIn = initData,
        showDialogIn = false,
        closeDialogOut = () => {}
    } = props

    const [taskItemServer, hydrateTaskItemServer, setTaskItemServer, isFetchingItemServer] = useRESTCRUD({
        endpoint: 'taskItem',
    })

    const [taskProcessServer, hydrateTaskProcessServer, setTaskProcessServer, isFetchingTaskProcessServer] = useRESTCRUD({
        endpoint: 'taskProcess',
    })

    const [taskItemRefProcDocServer, hydrateTaskItemRefProcDocServer, setTaskItemRefProcDocServer, isFetchingTaskItemRefProcDocServer] = useRESTCRUD({
        endpoint: 'tasksItemRefDocument',
    })

    const [taskGroupData, setTaskGroupData] = useState([])
    const [taskItemData, setTaskItemData] = useState([])
    const [taskGroupDisplay, setTaskGroupDisplay] = useState([])
    const [taskItemDisplay, setTaskItemDisplay] = useState([])
    const [pageLoadInt, setPageLoadInt] = useState(0)
    const [isDragging, setIsDragging] = useState('')
    const [itemTaskGroupFilterList, setItemTaskGroupFilterList] = useState([])
    const [itemDocumentFilterList, setItemDocumentFilterList] = useState([])
    const [groupProjectFilterList, setGroupProjectFilterList] = useState([])
    const [groupProcessFilterList, setGroupProcessFilterList] = useState([])
    const [itemTaskGroupFilterSelection, setItemTaskGroupFilterSelection] = useState('')
    const [itemDocumentFilterSelection, setItemDocumentFilterSelection] = useState('')
    const [groupProjectFilterSelection, setGroupProjectFilterSelection] = useState('')
    const [groupProcessFilterSelection, setGroupProcessFilterSelection] = useState('')

    useEffect(() => {
        hydrateTaskItemServer().then()
        hydrateTaskProcessServer().then()
    },[showDialogIn])

    useEffect(() => {
        if (taskItemServer && taskItemRefProcDocServer && taskProcessServer) {
            const mappedTaskItems = (taskItemServer.map((taskItem) => {
                let procDoc = {
                    docName: '',
                    docId: ''
                }
                taskItemRefProcDocServer.forEach((taskItemRefProcDoc) => {
                    if (taskItem.id === taskItemRefProcDoc.task_item) {
                        procDoc = {
                            docName: taskItemRefProcDoc.document_process.document.name,
                            docId: taskItemRefProcDoc.document_process.document.id
                        }
                    }
                })
                let taskGroupName = ''
                taskProcessServer.forEach((taskProcess) => {
                    if (taskProcess.task_group.id === taskItem.task_group) {
                        taskGroupName = taskProcess.task_group.name
                    }
                })
                let taskGroupId = ''
                if (taskItem.task_group === null) {
                    taskGroupId = 'UNASSIGNED'
                } else {
                    taskGroupId = taskItem.task_group
                }
                return {
                    id: taskItem.id,
                    taskString: taskItem.task,
                    taskGroupId: taskGroupId,
                    taskGroup: taskGroupName,
                    isCheckedBool: taskItem.completed,
                    docName: procDoc.docName,
                    docId: procDoc.docId
                }
            }))
            mappedTaskItems.sort((a, b) => {
                if (a.taskString.toUpperCase() < b.taskString.toUpperCase()) return -1
                if (a.taskString.toUpperCase() > b.taskString.toUpperCase()) return 1
                return 0
            })
            setTaskItemData(mappedTaskItems)
            setTaskItemDisplay(mappedTaskItems)
            setPageLoadInt(prev => prev+1)
            const mappedTaskGroups = (taskProcessServer.map((taskGroup) => {
                return mapTaskGroupProcessServerToTaskGroupProcessObject(taskGroup)
            }))
            mappedTaskGroups.sort((a, b) => {
                if (a.headerString.toUpperCase() < b.headerString.toUpperCase()) return -1
                if (a.headerString.toUpperCase() > b.headerString.toUpperCase()) return 1
                return 0
            })
            setTaskGroupData(mappedTaskGroups)
            setTaskGroupDisplay(mappedTaskGroups)
        }

    }, [taskItemServer, taskItemRefProcDocServer, taskProcessServer])
    //
    // useEffect(() => {
    //     if (taskProcessServer) {
    //         const mappedTaskGroups = (taskProcessServer.map((taskGroup) => {
    //             return mapTaskGroupProcessServerToTaskGroupProcessObject(taskGroup)
    //         }))
    //         setTaskGroupData(mappedTaskGroups)
    //         setTaskGroupDisplay(mappedTaskGroups)
    //     }
    // }, [taskGroupDisplay])

    useEffect(() => {
        if (taskItemRefProcDocServer) {
            const dupSet = []
            const mappedGroupProcDocFilter = []
            let index = 0
            taskItemRefProcDocServer.forEach((taskItemProcDoc, i) => {
                if (taskItemProcDoc?.document_process?.document?.id) {
                    if (!dupSet.includes(taskItemProcDoc.document_process.document.id)) {
                        mappedGroupProcDocFilter.push({
                            label: taskItemProcDoc.document_process.document.name,
                            value: index,
                            data: taskItemProcDoc,
                        })
                        index++
                        dupSet.push(taskItemProcDoc.document_process.document.id)
                    }
                }

            })
            setItemDocumentFilterList(mappedGroupProcDocFilter)
        }
    }, [taskItemRefProcDocServer])

    useEffect(() => {
        if (taskItemData && taskGroupData) {
            const unassignedEntry = {
                label: '<Unassigned>',
                value: 0,
                data: {taskGroupId: 'UNASSIGNED'},
            }
            const mappedTaskItemGroupFilter = []
            const dupSet = []
            let index = 1
            taskItemData.forEach((taskItem, i) => {
                taskGroupData.forEach((taskGroup, j) => {
                    if (taskItem.taskGroupId === taskGroup.taskGroupId) {
                        if (!dupSet.includes(taskGroup.taskGroupId)) {
                            mappedTaskItemGroupFilter.push({
                                label: taskGroup.headerString,
                                value: index,
                                data: taskGroup,
                            })
                            index++
                            dupSet.push(taskGroup.taskGroupId)
                        }
                    }
                })
                setPageLoadInt(prev => prev+1)
            })
            setItemTaskGroupFilterList([unassignedEntry, ...mappedTaskItemGroupFilter])
        }
    }, [taskItemData, taskGroupData])


    useEffect(() => {
        if (taskGroupData) {
            const {
                mappedProjectList,
                mappedProcessList,
            } = filterFilterLists(taskGroupData)
            setGroupProjectFilterList(mappedProjectList)
            setGroupProcessFilterList(mappedProcessList)
        }
    }, [taskGroupData])

    useEffect(() => {

        const filteredRows = taskItemData
            .filter((option) => {
                if (itemTaskGroupFilterSelection) {
                    return option.taskGroupId === itemTaskGroupFilterSelection
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (itemDocumentFilterSelection) {
                    return option.docId === itemDocumentFilterSelection
                } else {
                    return true
                }
            })
        setTaskItemDisplay(filteredRows.sort((a, b) => {
            if (a.taskString.toUpperCase() < b.taskString.toUpperCase()) return -1
            if (a.taskString.toUpperCase() > b.taskString.toUpperCase()) return 1
            return 0
        }))

    }, [itemTaskGroupFilterSelection, itemDocumentFilterSelection, taskItemData])

    useEffect(() => {

        const filteredRows = taskGroupData
            .filter((option) => {
                if (groupProjectFilterSelection) {
                    return option.projectId === groupProjectFilterSelection
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (groupProcessFilterSelection) {
                    return option.processId === groupProcessFilterSelection
                } else {
                    return true
                }
            })
        setTaskGroupDisplay(filteredRows.sort((a, b) => {
            if (a.headerString.toUpperCase() < b.headerString.toUpperCase()) return -1
            if (a.headerString.toUpperCase() > b.headerString.toUpperCase()) return 1
            return 0
        }))

    }, [groupProjectFilterSelection, groupProcessFilterSelection, taskGroupData])

    const handleDragStart = (result) => {
        setIsDragging(result.draggableId)
    }

    const handleDragEnd = async (result) => {
        setIsDragging('')
        const {destination, source, draggableId} = result
        if (!destination) {
            return
        }
        if (destination.droppableId === source.droppableId) {
            return
        }
        await setTaskItemServer({task_group: destination.droppableId}, taskItemDisplay[source.index].id)
        await hydrateTaskItemServer()
        await hydrateTaskProcessServer()
    }

    const handleCloseDialogEditDocument = () => {
        closeDialogOut()
    }

    const handleItemTaskGroupFilterSelection = (index) => {
        if (index === -1) {
            setItemTaskGroupFilterSelection('')
        } else {
            setItemTaskGroupFilterSelection(itemTaskGroupFilterList[index].data.taskGroupId)
        }
    }

    const handleItemDocumentFilterSelection = (index) => {
        if (index === -1) {
            setItemDocumentFilterSelection('')
        } else {
            setItemDocumentFilterSelection(itemDocumentFilterList[index].data.document_process.document.id)
        }
    }

    const handleGroupProjectFilterSelection = (index) => {
        if (index === -1) {
            setGroupProjectFilterSelection('')
        } else {
            setGroupProjectFilterSelection(groupProjectFilterList[index].data.projectId)
        }
    }

    const handleGroupProcessFilterSelection = (index) => {
        if (index === -1) {
            setGroupProcessFilterSelection('')
        } else {
            setGroupProcessFilterSelection(groupProcessFilterList[index].data.processId)
        }
    }

    return (
        <BodyWrapper>
            <Dialog open={showDialogIn} onClose={() => {}} aria-labelledby="form-dialog-title" maxWidth="xl">
                <CenteredDialogTitleWrapper id="form-dialog-title" >ORGANISE TASKS</CenteredDialogTitleWrapper>
                <LoaderWrapper
                    isLoaded={pageLoadInt >= 2}
                >
                    <InnerWrapper>
                        <DraggableWrapper>
                            <DragDropContext
                                onDragEnd={handleDragEnd}
                                onDragStart={handleDragStart}
                            >
                                <TaskItemsWrapper>
                                    <Title>{'Task Items'}</Title>
                                    <SimpleDropdown
                                        title={'Task Group'}
                                        selectNone={true}
                                        inputArray={itemTaskGroupFilterList}
                                        onSelect={handleItemTaskGroupFilterSelection}
                                    />
                                    <SimpleDropdown
                                        title={'Document'}
                                        selectNone={true}
                                        inputArray={itemDocumentFilterList}
                                        onSelect={handleItemDocumentFilterSelection}
                                    />
                                    <TaskItemColumn
                                        tasks={taskItemDisplay}
                                        isDragging={isDragging}
                                    />
                                </TaskItemsWrapper>
                                <TaskGroupsWrapper>
                                    <Title>{'Task Groups'}</Title>
                                    <SimpleDropdown
                                        title={'Project'}
                                        selectNone={true}
                                        inputArray={groupProjectFilterList}
                                        onSelect={handleGroupProjectFilterSelection}
                                    />
                                    <SimpleDropdown
                                        title={'Process'}
                                        selectNone={true}
                                        inputArray={groupProcessFilterList}
                                        onSelect={handleGroupProcessFilterSelection}
                                    />
                                    <TaskGroupColumn
                                        tasks={taskGroupDisplay}
                                        isDragging={isDragging}
                                    />
                                </TaskGroupsWrapper>

                            </DragDropContext>
                        </DraggableWrapper>
                    </InnerWrapper>

                    <CenteredDialogButtonWrapper>
                        <Button onClick={handleCloseDialogEditDocument} color="primary">
                            CLOSE
                        </Button>
                    </CenteredDialogButtonWrapper>
                </LoaderWrapper>
            </Dialog>
        </BodyWrapper>
    )

};

export default ReOrderTasks;