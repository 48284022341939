import React from 'react';
import styled from "styled-components";
import {Droppable} from "react-beautiful-dnd";
import TaskItem from "./TaskItem";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid lightgrey;
  width: 100%;
  height: 400px;
  margin-top: 20px;
  background-color: lightgrey;
  border-radius: 5px;
`

const RelativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const TaskList = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: scroll;
  padding: 30px;
`

const TaskItemColumn = (props) => {

    const MappedTasks = Object.keys(props.tasks).map((taskKey, i) => {
        return (
            <TaskItem
                key={props.tasks[taskKey].id}
                task={props.tasks[taskKey]}
                index={i}
                isDragging={props.isDragging}
            />
        )
    })

    return (
        <BodyWrapper>

            <Droppable
                droppableId={'TaskItems'}
            >
                {(provided, snapshot) => {
                    return (
                        <RelativeWrapper>
                            <TaskList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                {MappedTasks}
                            </TaskList>
                        </RelativeWrapper>

                    )
                }}
            </Droppable>
        </BodyWrapper>
    )


};

export default TaskItemColumn;