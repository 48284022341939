import React from 'react';
import styled from "styled-components";

const WrapperDiv = styled.div`
  
`;

const StyledH1 = styled.h1`
  color: #373737;
  font-size: 22px;
`

const BannerTitle = ({className = '', text = ''}) => {

    return (
        <WrapperDiv className={`BannerTitle ${className}`}>
            <StyledH1 style={{whiteSpace: 'noWrap'}}>{text}</StyledH1>
        </WrapperDiv>
    )

}

export default BannerTitle;