import React, {useState} from 'react';
import styled from "styled-components";
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import {Button, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import SimpleDropdown from "../../dropdowns/SimpleDropdown/SimpleDropdown";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`



const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const CenteredDialogButtonWrapperChooseDialog = styled.div`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 50px;
  .MuiDialogActions-root {
    //width: 100%;
    justify-content: center;
  }

`



const DialogContentWrapperColumnChooseDialog = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px 20px 30px;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .MuiFormControl-root {
    width: 250px;
   
  }
  
`


const DialogContentWrapperRow = styled.div`
  //width: 300px;
  height: 100%;
  //padding: 10px 30px;
display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  //.MuiFormControl-root {
  //  width: 100%;
  //  margin-bottom: 20px;
  //
  //}

`


const TagCell = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #acacac;
  margin-right: 10px;

`

const StyledP = styled.p`
  padding: 3px;
  margin: 0;
  font-size: 16px;
  font-style: italic;
  font-weight: bold;
  color: #FFFFFF;
`

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {

    height: 18px;
    width: 18px;
    fill: white;
  }

  :hover {
    .MuiSvgIcon-root {
      fill: #cbcbcb;
    }
  }

  margin-left: 5px;
`

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .MuiSvgIcon-root {

    height: 25px;
    width: 25px;
    fill: #484848;
  }

  :hover {
    .MuiSvgIcon-root {
      fill: #aeaeae;
    }
  }

`

const AlertButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`


const TagBox = (props) => {

    const {
        tagArrayInput = [],
        fetchTagsOut = () => {},
        addTagOut = (tagUUID) => {},
        deleteTagOut = (tagUUID) => {},
    } = props

    const [isTagsLoaded, setIsTagsLoaded] = useState(false)
    const [tagArray, setTagArray] = useState([])
    const [showAddTagDialog, setShowAddTagDialog] = useState(false)
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
    const [tagObject, setTagObject] = useState(null)
    const [deleteTagId, setDeleteTagId] = useState(null)

    const handleClickAddTag = async () => {
        setIsTagsLoaded(false)
        const updatedTags = await fetchTagsOut()
        await setTagArray(updatedTags)
        setShowAddTagDialog(true)
        await setIsTagsLoaded(true)
    }

    const handleSelectDialogChooseTag = (index) => {
        const uuid = tagArray[index].data.id
        setTagObject(uuid)
    }

    const handleAddChooseTag = () => {
        if (tagObject) {
            addTagOut(tagObject)
            setShowAddTagDialog(false)
        }
    }

    const handleCloseDialogChooseTag = () => {
        setShowAddTagDialog(false)
    }

    const handleClickDeleteTag = (uuid) => {
        setDeleteTagId(uuid)
        setDeleteAlertOpen(true)
    }

    const handleDeleteAlertConfirm = () => {
        deleteTagOut(deleteTagId)
        setDeleteAlertOpen(false)
    }
    const handleDeleteAlertCancel = () => {
        setDeleteAlertOpen(false)
    }


    const TagElements = tagArrayInput.map((tag, i) => {
        return (
            <TagCell
                key={`${tag.id}_${i}`}
            >
                <StyledP># {tag.tag}</StyledP>
                <DeleteButtonWrapper
                    onClick={() => handleClickDeleteTag(tag.id)}
                >
                    <ClearIcon/>
                </DeleteButtonWrapper>
            </TagCell>
        )
    })

    return (
        <BodyWrapper>
            {TagElements}
            <AddButtonWrapper
                onClick={handleClickAddTag}
            >
                <AddIcon/>
            </AddButtonWrapper>
            <Dialog open={showAddTagDialog} aria-labelledby="form-dialog-title">
                <CenteredDialogTitleWrapper id="form-dialog-title" >ADD TAG</CenteredDialogTitleWrapper>
                <DialogContent>
                    <DialogContentWrapperColumnChooseDialog>
                        <SimpleDropdown
                            title={'Tag'}
                            inputArray={tagArray}
                            onSelect={handleSelectDialogChooseTag}
                            isLoaded={isTagsLoaded}
                        />
                    </DialogContentWrapperColumnChooseDialog>
                </DialogContent>
                {isTagsLoaded && (
                    <CenteredDialogButtonWrapperChooseDialog>
                        <DialogContentWrapperRow>
                            <Button onClick={handleCloseDialogChooseTag} color="primary">
                                CLOSE
                            </Button>
                        </DialogContentWrapperRow>
                        <DialogContentWrapperRow>
                            <Button onClick={handleAddChooseTag} color="primary">
                                ADD
                            </Button>
                        </DialogContentWrapperRow>
                    </CenteredDialogButtonWrapperChooseDialog>
                )}
            </Dialog>
            <Dialog
                open={deleteAlertOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Tag?"}</DialogTitle>
                <AlertButtonsWrapper>
                    <Button onClick={handleDeleteAlertCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteAlertConfirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </BodyWrapper>
    )

};

export default TagBox;