import React, {useState, useEffect} from "react";
import styled from "styled-components";
import {withRouter} from "react-router";

import colors from "../../../library/objects/theme/definitions/colors";
import sizes from "../../../library/objects/theme/definitions/sizes";
import effects from "../../../library/objects/theme/definitions/effects";


const BodyDiv = styled.div`

  height: 100%;
  width: 100%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  `


const ListDiv = styled.div`
    //padding: 6px 10px;
    p {
            font-size: ${sizes.mainPane[1].text[1].contentListTextLarge[0]};
            //font-family: Heebo;
            color: ${colors.mainPane[1].text[1].contentListItemUp[0]};
            font-weight: bold;
            cursor: default;
    }
    

  
 .ListItem {
   width: 100%;
  padding: 8px 45px;
  margin: 10px 0;
  cursor: default;
  transition: background-color 75ms;
 }
 
 .ListGroup-0 {
.ListItemP {
    font-family: Poppins,sans-serif;
    font-size: ${sizes.sideBar[1].text[1].headerText[0]};
    font-weight: 500;
    color: ${colors.sideBar[1].text[1].navHeaderUp[0]};
    transition: color 75ms, font-weight 0ms;
}

  .ListItem.HIGHLIGHTED {
   background-color: #666666;
   box-shadow: 0 4px 8px 0 rgba(30, 30, 30, 0.03), 0 6px 20px 0 rgba(30, 30, 30, 0.03);
         //border-left: 20px solid black;
        p {
          font-weight: 600;
          letter-spacing: -.4px;
          color: ${colors.sideBar[1].text[1].navLinksUp[0]};
        }
       
        :active {
          background-color: ${colors.sideBar[1].buttons[1].navButtonDown[0]};
        p {
          color: ${colors.sideBar[1].text[1].navHeaderDown[0]};
        }
 }
}
  .ListItem.SELECTED {
   background-color: ${colors.sideBar[1].buttons[1].navButtonActive[0]};
   p {
            color: ${colors.sideBar[1].text[1].navHeaderActive[0]};
          font-weight: 600;
          letter-spacing: -.3px;
   }
   }
 

}

.ListGroup-1 {
.ListItemP {
    font-family: Poppins,sans-serif;
    font-size: ${sizes.sideBar[1].text[1].linkText[0]};
    font-weight: 400;
    color: ${colors.sideBar[1].text[1].navLinksUp[0]};
    transition: color 75ms, font-weight 0ms;
}

  .ListItem.HIGHLIGHTED {
   background-color: #666666;
   box-shadow: 0 4px 8px 0 rgba(30, 30, 30, 0.03), 0 6px 20px 0 rgba(30, 30, 30, 0.03);
   //border-left: 20px solid black;
   p {
            color: ${colors.sideBar[1].text[1].navLinksUp[0]};
          font-weight: 600;
          letter-spacing: -.4px;
   }
   
         :active {
        background-color: ${colors.sideBar[1].buttons[1].navButtonDown[0]};
        p {
          color: ${colors.sideBar[1].text[1].navLinksDown[0]};
        }
 }
}
  .ListItem.SELECTED {
   background-color: ${colors.sideBar[1].buttons[1].navButtonActive[0]};
   p {
            color: ${colors.sideBar[1].text[1].navLinksActive[0]};
          font-weight: 600;
          letter-spacing: -.3px;
   }
   }
 
}

.ListGroup-1 {
.ListGroup-DividerTop {
  height: 0.5px;
  width: calc(100% - 60px);
  background-color: ${colors.sideBar[1].frames[1].divider[0]};
  margin: 6px 30px;
}
}

.ListItem.SELECTED.HIGHLIGHTED {
background-color: ${colors.sideBar[1].buttons[1].navButtonOver[0]};


   p {
            color: ${colors.sideBar[1].text[1].navHeaderUp[0]};
            }
}


`


const navValues = [
    [
        { value: '/', label: 'DASHBOARD' },
    ],
    [
        // { value: '/project/list', label: 'PROJECTS' },
        // { value: '/process/list', label: 'PROCESSES' },
        { value: '/task/list/', label: 'ACTIONS' },
        { value: '/contact/list', label: 'CONTACTS' },
        { value: '/document/list/', label: 'DOCUMENTS' },

    ],
];


const SideNavLinks = (props) => {

    const [selected, setSelected] = useState('0_0')
    const [highlighted, setHighlighted] = useState(0)

    useEffect(() => {
        if (props.location?.pathname) {
            navValues.forEach((outerNavValue, i) => {
                outerNavValue.forEach((innerNavValue, j) => {
                    const innerNavPathArray = innerNavValue.value.split('/')
                    if (props.location.pathname.includes(innerNavPathArray[1])) {
                        setSelected(`${i}_${j}`)
                    }
                })
            })
        }
    }, [props])

    // task: integrate this object with the ContentRoute goings on

    const setHistory = (link) => {
        props.history.push(link)
    };

    const handleClickItem = (i, j) => {
        setSelected(`${i}_${j}`)
        setHistory(navValues[i][j].value)
    }

    const handleOverItem = (i, j) => {
        setHighlighted(`${i}_${j}`)
    }

    const handleOutList = (event) => {
        setHighlighted(``)
    }

    const SideNavList = navValues.map((sideNavBlock, i) => {
        return (
            <div
                className={`ListGroup-${i}`}
                key={`sidebarwrapper_${i}`}
            >
                <div className={'ListGroup-DividerTop'}/>
            {
                sideNavBlock.map((sideNavItem, j) => {
                    let selectCSS = ''
                    let highlightCSS = ''
                    if (`${i}_${j}` === selected) {
                        selectCSS = ' SELECTED '
                    }
                    if (`${i}_${j}` === highlighted) {
                        highlightCSS = ' HIGHLIGHTED '
                    }
                    return (
                        <div
                            className={`ListItem${selectCSS}${highlightCSS}`}
                            key={`sidebar_${i}_${j}`}
                            onMouseOver={() => handleOverItem(i, j)}
                            onClick={() => handleClickItem(i, j)}
                        >
                            <p className={'ListItemP'} key={`sidebarp_${i}_${j}`}>{sideNavItem.label}</p>
                        </div>
                    )
                })
            }
                <div className={'ListGroup-DividerBottom'}/>
            </div>
        )
    })

    return (
        <BodyDiv>
            <ListDiv onMouseOut={handleOutList}>
                {SideNavList}
            </ListDiv>
        </BodyDiv>
    )

};

export default withRouter(SideNavLinks)