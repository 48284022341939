import React, {useState} from 'react';
import styled from "styled-components";
import {Droppable} from "react-beautiful-dnd";
import TaskGroup from "./TaskGroup";
import GamesIcon from '@material-ui/icons/Games';


const RelativeWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`



const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 400px;
  margin-top: 20px;
  background-color: lightgrey;
  border-radius: 5px;
  position: absolute;
  overflow-y: scroll;
  padding: 30px;

`


const TaskGroupWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  background-color: ${props => props.isDraggingOver ? '#969696' : 'white'};
  padding: 16px;
  position: relative;
`

const DroppyWrapper = styled.div`
display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 65px;
  padding: 0;

`

const DroppyDiv = styled.div`
  height: ${props => props.isDraggingOver ? '55px' : '45px'};
  width: ${props => props.isDraggingOver ? '55px' : '45px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.isDraggingOver ? '#676767' : '#cacaca'};
  padding: 0;
  .MuiSvgIcon-root{
    height: 30px;
    width: 30px;
    fill: white;
  }
  border-radius: 5px;

`
const TaskGroupColumn = (props) => {

    const MappedTaskGroup = Object.keys(props.tasks).map((taskKey, i) => {
        return (

            <Droppable
                droppableId={props.tasks[taskKey].taskGroupId}
                key={props.tasks[taskKey].taskGroupId}
            >

                {(provided, snapshot) => {
                    return (
                        <TaskGroupWrapper
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                        <DroppyWrapper>
                            <DroppyDiv
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                            >
                                <GamesIcon/>
                                {provided.placeholder}
                            </DroppyDiv>
                            </DroppyWrapper>

                            <TaskGroup
                                key={props.tasks[taskKey].taskGroupId}
                                task={props.tasks[taskKey]}
                                index={i}
                                isDraggingOver={snapshot.isDraggingOver}
                            />
                        </TaskGroupWrapper>
                )
                }}

            </Droppable>



        )

    })


    return (
        <RelativeWrapper>
            <BodyWrapper>
                {MappedTaskGroup}
            </BodyWrapper>
        </RelativeWrapper>
    )


};

export default TaskGroupColumn;