import React, {useState, useEffect} from 'react'
import {DragDropContext} from "react-beautiful-dnd";
import styled from "styled-components"
import ListColumn from "./components/ListColumn";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

const DefaultListFrameComponent = (props) => {
    return (
        <div>
            {props.children}
        </div>
    )
}

const DefaultListItemComponent = (props) => {
    return (
        <div>
            Default List Item
        </div>
    )
}

const DraggableList = ({
        listArrayIn = [],
        ListItemComponent = DefaultListItemComponent,
        ListFrameComponent = DefaultListFrameComponent,
        handleDragOutList = () => {},
        handleDragOutMap = () => {},
        handleDragOutIndex = () => {}
    }) => {

    const [baseListMap, setBaseListMap] = useState({})
    const [orderedListArray, setOrderedListArray] = useState([])

    useEffect(() => {
        setBaseListMap(prev => {

            const returnBaseMap = {...prev}
            Object.keys(returnBaseMap).forEach((baseMapKey) => {
                returnBaseMap[baseMapKey].render = false
            })
            listArrayIn.forEach((listItem, i) => {
                if (listItem) {
                    returnBaseMap[listItem.id] = {
                        baseKey: listItem.id,
                        inputIndex: i,
                        orderedIndex: prev[listItem.id]?.orderedIndex !== undefined ? prev[listItem.id]?.orderedIndex : i,
                        render: true,
                        data:listItem
                    }
                }

            })
            return returnBaseMap
        })
    }, [listArrayIn])

    useEffect(() => {
        const returnOrderedArray = []
        Object.keys(baseListMap).forEach((mapKey) => {
            if (baseListMap[mapKey].orderedIndex !== undefined) {
                returnOrderedArray[baseListMap[mapKey].orderedIndex] = baseListMap[mapKey]
            }
        })
        setOrderedListArray(returnOrderedArray)
    }, [baseListMap])

    const handleDragEnd = (result) => {
        const returnOrderedList = [...orderedListArray]
        const sourceObject = {...orderedListArray[result.source.index], orderedIndex: result.destination.index}
        returnOrderedList.splice(result.source.index, 1)
        returnOrderedList.splice(result.destination.index, 0, {...sourceObject})
        const returnMap = {}
        const returnList = []
        returnOrderedList.forEach((listItem, i) => {
            const newItem = {
                ...listItem,
                orderedIndex: i
            }
            returnMap[listItem.baseKey] = newItem
            if (newItem.render) {
                returnList.push(newItem.data)
            }
        })
        setOrderedListArray(returnOrderedList)
        handleDragOutList(returnList)
        handleDragOutMap(returnMap)
        handleDragOutIndex(result.source.index, result.destination.index)
        setBaseListMap(returnMap)
    }

    return (
        <BodyWrapper
            className={'DraggableList'}
        >
            <DragDropContext
                className={'DragDropContext'}
                onDragEnd={handleDragEnd}
            >
                <ListColumn
                    orderedListArray={orderedListArray}
                    ListFrameComponent={ListFrameComponent}
                    ListItemComponent={ListItemComponent}
                />
            </DragDropContext>

        </BodyWrapper>
    )

}

export default DraggableList