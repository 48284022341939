import React from "react";
import styled from "styled-components";

const EditButtonFloating = styled.div`
display: flex;
justify-content: center;
align-items: center;
    height: 40px;
    width: 40px;
    position: absolute;
    background-color: #FFFFFF;
    bottom: 25px;
    right: 30px;
    border-radius: 50% 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    cursor: default;
  padding: 8px;
    
    :hover {
    background-color: #bababa;
    p {
    color: #FFFFFF;
    }
}
  svg {
    height: 35px;
    width: 35px;
  }
`;

export default (props) => {
    return (
        <EditButtonFloating onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#606060" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
        </EditButtonFloating>
    )
}