import {useContext, useState} from 'react'
import {StateBotContext} from "../../../StateBot";
import fetchAsync from "../functions/fetchAsync";

const useSession = () => {

    const {sessionObject, authObject} = useContext(StateBotContext)

    // for now just standard rest... later config wahter // all in StateBot config

    const {endpoints, server} = authObject
    const {setSession} = sessionObject

    let headers = {
        'Content-type': 'application/json',
    }

    const authFetch = (endpoint, method) => {

        const fetchObject = {
            apiBaseUrl: `${server.protocol}://${server.address}:${server.port}`,
            requestUrl: endpoint,
            method: method,
            headers,
            body: ''
        }

        const innerFunc = async (body) => {
            const fetchBodyObject = {...fetchObject, body: JSON.stringify(body)}
            return await fetchAsync(fetchBodyObject)
        }

        return innerFunc

    }

    const authEndpoints = {
        token: authFetch(endpoints.token, 'POST'),
        tokenRefresh: authFetch(endpoints.tokenRefresh, 'POST'),
        passwordReset: authFetch(endpoints.passwordReset, 'PATCH'),
        passwordValidation: authFetch(endpoints.passwordValidation, 'POST'),
        registration: authFetch(endpoints.registration, 'POST'),
        tokenVerify: authFetch(endpoints.tokenVerify, 'POST'),
    }

    const setAuthToken = (tokens) => {
        setSession(prev => {
            return {...prev, isAuth: true, token: tokens}
        })
    }



    return {
        setAuthToken,
        authEndpoints
    }

}

export default useSession