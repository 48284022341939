import React, {useState, useEffect} from 'react'
import styled from "styled-components"
import {Draggable} from "react-beautiful-dnd";
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
`

const ItemBodyWrapper = styled(Draggable)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const DragHandleWrapper = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ListItem = ({
        listItem = {},
        itemIndex = 0,
        baseKey = 0,
        ListItemComponent = <div/>
    }) => {

    if (listItem.render) {
        return (
            <ItemBodyWrapper
                className={'ItemBodyWrapper'}
                draggableId={`${baseKey}`}
                index={itemIndex}
            >
                {(provided) => {
                    return (
                        <ItemWrapper
                            className={'ItemWrapper'}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            <DragHandleWrapper

                                {...provided.dragHandleProps}
                            >
                                <DragIndicatorIcon/>
                            </DragHandleWrapper>
                            <ListItemComponent listItem={listItem.data} itemIndex={itemIndex}/>
                        </ItemWrapper>
                    )
                }}

            </ItemBodyWrapper>

        )
    } else {
        return <div/>
    }


}

export default ListItem