import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import {Button, FormControl, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import HeaderWrapper from "../../templates/wrappers/HeaderWrapper";
import SimpleDropdown from "../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import ShadowBox from "../../templates/boxes/ShadowBox";
import "../../templates/wrappers/FlexRowWrapper";
import FlexRowWrapper from "../../templates/wrappers/FlexRowWrapper";
import SortableTableList from "../../templates/tables/SortableTableList/SortableTableList";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../templates/wrappers/LoaderWrapper";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '23%',

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PageWrapper = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 20px;

`

const FilterWrapper = styled.div`
  width: 100%;
  height: 70px;
  margin: 20px 0;

`;

const FilterInnerWrapper = styled.div`
width: 100%;
  height: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`;

const ListWrapper = styled.div`
  height: calc(100% - 130px);
  width: 100%;
  //margin: 20rem 0;
`;

const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

function createData(name, project, process, processType, parentDoc, proxyFile) {
    return { name, project, process, processType, parentDoc, proxyFile };
}

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Document' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project' },
    { id: 'process', numeric: false, disablePadding: false, label: 'Process' },
    { id: 'processType', numeric: false, disablePadding: false, label: 'Process Type' },
    { id: 'parentDoc', numeric: false, disablePadding: false, label: 'Parent Doc' },
];


const ProjectList = (props) => {

    const [state, hydrateState, setState, isFetching] = useRESTCRUD({
        endpoint: 'document',
        // instance: '69f8ec01-e950-4606-89ba-aabb51c3d5ae'
    })


    const [bodyRows, setBodyRows] = useState([])
    const [isTableLoaded, setIsTableLoaded] = useState(false)

    useEffect(() => {
        if (state) {
            setBodyRows(prev => {
                return state.map((document, i) => {
                    return createData(
                        document['name'] ? document['name'] : '',
                        document.project ? document.project: '',
                        '',
                        '',
                        document['parent_doc'] ? document['parent_doc'] : '',
                        document['proxy_file']
                    )
                })
            })
            setIsTableLoaded(true)
        }
    }, [state])

    const classes = useStyles()

    const [inputArray, setInputArray] = React.useState([
        {value: 'blah1', label: 'In Development'},
        ]);

    const handleFilterSelection = (docObject) => {
    }

    const handleTableSelection = (docObject) => {
        props.history.push(`/document/view/${docObject.id}`, docObject)
    }

    return (
        <DocumentWrapper className={'DocumentWrapper'}>
            <HeaderWrapper title={'Projects'}/>
            <PageWrapper className={'PageWrapper'}>
                <FilterWrapper className={'FilterWrapper'}>
                    <ShadowBox className={'ShadowBox'} >
                        <FlexRowWrapper divHeight={'100%'} divWidth={'100%'}>
                            <FilterInnerWrapper>
                            <SimpleDropdown
                                classes={classes}
                                title={'Project'}
                                inputArray={inputArray}
                                onSelect={handleFilterSelection}
                            />
                            <SimpleDropdown
                                classes={classes}
                                title={'Process'}
                                inputArray={inputArray}
                                onSelect={handleFilterSelection}
                            />
                            <SimpleDropdown
                                classes={classes}
                                title={'Process Type'}
                                inputArray={inputArray}
                                onSelect={handleFilterSelection}
                            />
                            <SimpleDropdown
                                classes={classes}
                                title={'Parent Doc'}
                                inputArray={inputArray}
                                onSelect={handleFilterSelection}
                            />
                            </FilterInnerWrapper>
                        </FlexRowWrapper>
                    </ShadowBox>
                </FilterWrapper>
                <ListWrapper className={'ListWrapper'}>
                    <ShadowBox className={'ShadowBox'}>
                        <LoaderWrapper isLoaded={isTableLoaded}>
                            <InnerListWrapper>

                                    <SortableTableList
                                        headCells={headCells}
                                        bodyRows={bodyRows}
                                        onSelect={handleTableSelection}
                                    />

                            </InnerListWrapper>
                        </LoaderWrapper>
                    </ShadowBox>
                </ListWrapper>
            </PageWrapper>
        </DocumentWrapper>
    )
}

export default withRouter(ProjectList)