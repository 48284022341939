import React, {useState} from 'react';
import styled from "styled-components";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import RevealColumnWrapper from "../../../templates/wrappers/RevealColumnWrapper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {withRouter} from "react-router";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const HeaderWrapper = styled.div`
  width: 100%;
    display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  
`

const ExpandIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .MuiSvgIcon-root {
    height: 30px;
    width: 30px;
  }
`

const DocRefWrapper = styled.div`
  height: 30px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
`

const StyledH1 = styled.h1`
  font-size: 20px;
  font-weight: bold;

  :hover {
    color: #797979;
  }
`


const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0 18px 0 50px;
`

const TaskItemWrapper = styled.div`
  padding: 10px;
`

const StyledH2 = styled.h2`
  font-size: 18px;
  font-weight: normal;
`

const DocRefItem = (props) => {

    const {
        docRefObj
    } =props

    const [expanded, setExpanded] = useState(false)


    const handleToggleExpanded = () => {
        setExpanded(prev => !prev)
    }

    const TaskItems = docRefObj.taskItems.map((taskItem, i) => {
        return (
            <TaskItemWrapper
                key={`${taskItem.taskString}_${i}`}
            >
                <StyledH2>{taskItem.taskString}</StyledH2>
            </TaskItemWrapper>

        )
    })

    const handleHeaderClick = () => {
        props.history.push(`/document/view/${docRefObj.processDoc.id}`)
    }

    return (
        <BodyWrapper>
            <HeaderWrapper>
                <ExpandIconWrapper>
                    {docRefObj.taskItems.length > 0 &&
                        (expanded ? <ExpandMoreIcon onClick={handleToggleExpanded}/> : <ChevronRightIcon onClick={handleToggleExpanded}/>)
                    }
                </ExpandIconWrapper>
                <DocRefWrapper
                    onClick={handleHeaderClick}
                >
                    <StyledH1>{docRefObj.document.name}</StyledH1>
                </DocRefWrapper>
            </HeaderWrapper>
            <ListWrapper>
                <RevealColumnWrapper
                    expanded={expanded}
                >
                    {TaskItems}
                </RevealColumnWrapper>
            </ListWrapper>
        </BodyWrapper>
    )

};

export default withRouter(DocRefItem);