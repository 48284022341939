import React, {createContext, useEffect, useState} from 'react';
import AuthGate from "./modules/AuthGate/AuthGate";

export const StateBotContext = createContext()

const StateBot = (props) => {

    const {
        restObject = null,
        graphObject = null,
        liveObject = null,
        options = {
            isLoadedInitial: true
        }
    } = props

    const [session, setSession] = useState({
        session: {
            id: ''
        },
        user: {},
        log: [],
        isAuth: false,
        token: {},
    })

    const [sessionState, setSessionState] = useState({
        session: {
            id: ''
        },
        user: {},
        log: [],
        isAuth: false,
        token: {},
        data: {
            _cache: true
        },
    })

    const requestObject = {
            restObject,
            graphObject,
            liveObject,
    }

    const sessionObject = {
        session,
        setSession,
    }

    const authObject = {
        server: props.authObject.server,
        endpoints: props.authObject.auth.endpoints
    }

    const valueObject = {
        requestObject,
        sessionObject,
        authObject,
        sessionState,
        setSessionState
    }

    if (props.authObject) {
        return <StateBotContext.Provider value={valueObject}>
            <AuthGate>
                {props.children}
            </AuthGate>
        </StateBotContext.Provider>
    } else {
        return <StateBotContext.Provider value={valueObject}>
                {props.children}
        </StateBotContext.Provider>
    }


    // later if for if authObject

}

export default StateBot
