import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import styled from "styled-components";
import EnhancedTableHead from "./components/TableHead";
import EnhancedTableBody from "./components/TableBody";


const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .MuiTableCell-body {
    cursor: default;
  }
  p {
    text-align: left;
  }
`




const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 30,
    },
    tableCell: {
        pointer: 'default',
        // padding: 5,
        width: 30,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


export default function SortableTableList(props) {

    const classes = useStyles(props.styles);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleClick = (selectedObject) => {
        props.onSelect(selectedObject)
    };

    return (
        <InnerListWrapper className={classes.root}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                        aria-label="enhanced table"
                        stickyHeader
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.bodyRows.length}
                            headCells={props.headCells}
                        />
                        <EnhancedTableBody
                            classes={classes}
                            headCells={props.headCells}
                            bodyRows={props.bodyRows}
                            order={order}
                            orderBy={orderBy}
                            onSelect={handleClick}
                        />
                    </Table>
                </TableContainer>
        </InnerListWrapper>
    );
}
