import React, {useState} from 'react';
import styled from "styled-components";

const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;
`

const NameWrapper = styled.div`
  height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledH1 = styled.h1`
font-size: 45px;
  font-weight: bold;
`

const UpperDataBlockLineSpacer = styled.div`
  height: 1px;
  background-color: #a9a9a9;
  width: 75%;
  margin: 20px 0;
`


const TextPairWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;

  h5 {
    //width: 10%;
    width: 180px;
    text-align: right;
    padding-right: 10px;
    font-size: 25px;
  }
  
  p {
    width: 85%;
    font-size: 25px;
  }
  //background-color: #c65151;
  margin: 10px 0;
`

const StyledH5 = styled.h5`
  font-size: 25px;
  font-weight: normal;
`

const StyledTextArea = styled.textarea`
  resize: none;
  border: none;
`

const ContactFeature = (props) => {

    if (props.contact) {
        return (
            <BodyWrapper>
                <NameWrapper>
                    <StyledH1>{props.contact.fullName}</StyledH1>
                </NameWrapper>
                <UpperDataBlockLineSpacer/>
                <InfoWrapper>
                    <TextPairWrapper>
                        <h5>Position: </h5>
                        <p>{props.contact.position}</p>
                    </TextPairWrapper>
                    <TextPairWrapper>
                        <h5>Employer: </h5>
                        <p>{props.contact.employer}</p>
                    </TextPairWrapper>
                </InfoWrapper>
                <UpperDataBlockLineSpacer/>
                <ContactWrapper>
                    <TextPairWrapper>
                        <h5>Phone: </h5>
                        <p>{props.contact.phone}</p>
                    </TextPairWrapper>
                    <TextPairWrapper>
                        <h5>Email: </h5>
                        <p>{props.contact.email}</p>
                    </TextPairWrapper>
                </ContactWrapper>
                <UpperDataBlockLineSpacer/>
                <AddressWrapper>
                    <StyledH5>{props.contact.address}</StyledH5>
                    <StyledH5>{props.contact.suburb}</StyledH5>
                    <StyledH5>{props.contact.postcode}</StyledH5>
                </AddressWrapper>
                {props.contact.description &&
                <>
                    <UpperDataBlockLineSpacer/>
                    <StyledTextArea
                    />
                </>

                }

            </BodyWrapper>
        )
    } else {
        return <div/>
    }



};

export default ContactFeature;