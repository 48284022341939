import React from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  padding: 5px;
`;

const ButtonWrapper = ({className = '', elementName = '', clickHandler = null, children}) => {

    return (
        <WrapperDiv
            className={`ButtonWrapper ButtonWrapper-${elementName} ${className}`}
            onClick={clickHandler}
        >
            {children}
        </WrapperDiv>
    )

};

export default ButtonWrapper;