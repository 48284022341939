import {useState, useContext} from 'react';
import {StateBotContext} from "../../../StateBot";
import fetchAsync from "../functions/fetchAsync";
import fetchRawAsync from "../functions/fetchRawAsync";
import generateFilterString from "../functions/generateFilterString";

const useFetch = (request) => {

    const {requestObject, sessionObject} = useContext(StateBotContext)

    const defaultOptions = {
        returnPromise: false,
        authRequired: true,
        refreshInterval: null,
        rawRequest: false
    }

    const {
        server = 'primary',
        endpoint,
        instance = '',
        filterObject = null,      // filters is really on the list
        options = defaultOptions
    } = request


    let headers = {
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionObject.session.token.access}`,
    }

    const compiledOptions = {...defaultOptions, ...options}

    const restObject = requestObject.restObject
    // debugger
    let requestHeaders
    if (restObject[server].endpoints.fetch[endpoint].header) {
        requestHeaders = restObject[server].endpoints.fetch[endpoint].header
    }

    const fetchObject = {
        apiBaseUrl: `${restObject[server].server.protocol}://${restObject[server].server.address}:${restObject[server].server.port}`,
        requestUrl: `${restObject[server].endpoints.fetch[endpoint].endpoint}`,
        method: `${restObject[server].endpoints.fetch[endpoint].method}`,
        headers: requestHeaders ? {...headers, ...requestHeaders} : headers
    }

    const [isFetching, setIsFetching] = useState(true)

    const paramsDefault = {query_params: filterObject, instance: instance}


    const requestFunc = async (request = {}, params = paramsDefault) => {
        let queryParamString = generateFilterString(params.query_params)
        let requestEndpointString = `${params.instance}${queryParamString && '/'}${queryParamString}`
        const convergedFetchObject = {
            ...fetchObject,
            requestUrl: `${fetchObject.requestUrl}${requestEndpointString}`,
            ...request
        }
        // task: one request func for useREST and useFetch
        // task: do all json encoding here of body (if type) => begin to get in on the type handling front and back
        await setIsFetching(true)
        let serverResponse
        if (compiledOptions.rawRequest) {
            serverResponse = await fetchRawAsync(convergedFetchObject)
        } else {
            serverResponse = await fetchAsync(convergedFetchObject)

        }
        await setIsFetching(false)
        return serverResponse
    }

    return [requestFunc, isFetching]
}

export default useFetch

// now if generic => setState will ask more info and will complete individual requests
// later later... but needed
