import React from "react";
import styled from "styled-components";

import ContentHeaderNav from "./ContentHeaderNav";
import ContentFooterNav from "./ContentFooterNav";
import sizes from "../../../library/objects/theme/definitions/sizes";

const WrapperDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;


  .BannerTitle {
  //   h1 {
  //     cursor: default;
  //     font-size: ${sizes.mainPane[1].text[1].bannerTitle[0]};
  //     color: #cdcdcd;
  //   }
  // }
  //
  // h2 {
  //   cursor: default;
  //   font-size: ${sizes.mainPane[1].text[1].bannerButton[0]};
  //   color: #c8c8c8;
  //   font-weight: 500;
  // }



`

const StyledH1 = styled.h1`
  cursor: default;
  font-size: ${sizes.mainPane[1].text[1].bannerTitle[0]};
  color: #cdcdcd;
`

const StyledH2 = styled.h1`
  cursor: default;
  font-size: ${sizes.mainPane[1].text[1].bannerButton[0]};
  color: #c8c8c8;
  font-weight: 500;
`

const ContentBanners = ({
    className = '',
    headerTitle,
    TopLeftElement,
    TopRightElement,
    BottomLeftElement,
    BottomCenterElement,
    BottomRightElement,
    topLeftOut,
    topRightOut,
    bottomLeftOut,
    bottomCenterOut,
    bottomRightOut,
    children
}) => {

    return (
        <WrapperDiv className={`ContentFormBodyWrapper ${className}`}>
            <ContentHeaderNav
                title={headerTitle}
                TopLeftElement={TopLeftElement}
                TopRightElement={TopRightElement}
            />
                {children}
            {/*<ContentFooterNav*/}
            {/*    BottomLeftElement={BottomLeftElement}*/}
            {/*    BottomCenterElement={BottomCenterElement}*/}
            {/*    BottomRightElement={BottomRightElement}*/}
            {/*/>*/}
        </WrapperDiv>
    )

};

export default ContentBanners;