import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core";
import HeaderWrapper from "../../templates/wrappers/HeaderWrapper";
import SimpleDropdown from "../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import ShadowBox from "../../templates/boxes/ShadowBox";
import "../../templates/wrappers/FlexRowWrapper";
import SortableTableList from "../../templates/tables/SortableTableList/SortableTableList";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../templates/wrappers/LoaderWrapper";
import "../../templates/wrappers/FlexColumnWrapper";
import FlexColumnWrapper from "../../templates/wrappers/FlexColumnWrapper";
import {
    mapContactObjectToContactServer,
    mapContactServerToContactObject
} from "../../../library/helpers/functions/dataMaps";
import ContactFeature from "./ContactFeature";
import EditContactForm from "../../templates/tables/ContactTable/components/EditContactForm";
import AddButtonFloating from "../../templates/buttons/AddButtonFloating";
import EditButtonFloating from "../../templates/buttons/EditButtonFloating";
import SVGButton from "../../templates/buttons/SVGButton";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PdfGeneratorList from "../../templates/pdf/PdfGeneratorList/PdfGeneratorList";
import ContentBanners from "../../frames/content/ContentBanners";
import {Document, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import useFetch from "../../../appModules/StateBot/library/helpers/hooks/useFetch";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '48%',

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PageWrapper = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: start;
`

const FilterListWrapper = styled.div`
  width: 60%;
  height: 100%;
  margin: 20px 0;
  //display: flex;
  //flex-direction: column;
  //justify-content: start;

`


const ListWrapper = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  //margin: 20rem 0;
`;

const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TodoItemWrapper = styled.div`
  height: 100%;
  width: 40%;
  padding: 20px 0 20px 20px;
`

const TopRightElementWrapper = styled.div`
  height: 100%;
  display: flex;
  
`

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const headCells = [
    { id: 'fullName', numeric: false, disablePadding: false, label: 'Name', width: '30%' },
    { id: 'position', numeric: false, disablePadding: false, label: 'Position', width: '20%' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone', width: '20%' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email', width: '30%' },
];

const pdfStyles = StyleSheet.create({
    page: {
        padding: 75,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    header: {
        padding: '10px 0',

    },
    headerText: {
        fontWeight: 'bold'
    },
    section: {
        // margin: 10,
        // padding: 10,
        // flexGrow: 1
    }
})

const filterFieldList = [
    {field: 'position', label: 'Position', filter: '', type: 'string'},
]

const PdfListElement = ({pdfListObjectIn, listIndexIn = null}) => {
    return (
        <View style={pdfStyles.section}>
            <Text style={pdfStyles.headerText}>{`${pdfListObjectIn.fullName}`}</Text>
        </View>
    )
}

const PdfFrame = ({children}) => {
    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={pdfStyles.header}>
                    <Text>CONTACT LIST</Text>
                </View>
                {children}
            </Page>
        </Document>
    )
}

const PdfListDiv = (displayObject, instanceIndex) => {
    return (
        <ListItemWrapper>
            <p>{displayObject.fullName}</p>
        </ListItemWrapper>
    )
}


const TodoList = (props) => {

    const [contactServer, hydrateContactServer, setContactServer, isFetching] = useRESTCRUD({
        endpoint: 'contact',
    })

    const sendEmailRequest = {
        endpoint: 'emailBlobFile',
    }

    const [sendEmail, isFetchingSendEmail] = useFetch(sendEmailRequest)

    const [bodyRows, setBodyRows] = useState([])
    const [isTableLoaded, setIsTableLoaded] = useState(false)
    const [selectedContact, setSelectedContact] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [addNewContact, setAddNewContact] = useState(false)
    const [showExportDialog, setShowExportDialog] = useState(false)


    useEffect(() => {
        if (contactServer) {
            setBodyRows(prev => {
                return contactServer.map((contact, i) => {

                    return mapContactServerToContactObject(contact)
                })
            })
            setIsTableLoaded(true)
        }
    }, [contactServer])

    const handleTableSelection = (contactObject) => {
        setSelectedContact(contactObject)
    }

    const handleAddContactDialog = async () => {
        await setAddNewContact(true)
        setShowEditDialog(true)
    }

    const handleEditContactDialog = async () => {
        await setAddNewContact(false)
        setShowEditDialog(true)
    }

    const handleCreateNewContact = async (newContact) => {
        setShowEditDialog(false)
        await setContactServer(mapContactObjectToContactServer(newContact))
        await hydrateContactServer()
        setShowEditDialog(false)
    }

    const handleUpdateNewContact = async (uuid, updatedContact) => {
        await setContactServer(mapContactObjectToContactServer(updatedContact), uuid)
        await hydrateContactServer()
        setShowEditDialog(false)
    }

    const handleDeleteNewContact = async () => {
        setShowEditDialog(false)
        await setContactServer(null, selectedContact.id)
        await hydrateContactServer()
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false)
    }

    const handleClickOpenDialog = () => {
        setShowExportDialog(true)
    }

    const handleFetchContacts = async () => {
        const updatedContacts = await hydrateContactServer()
        if (contactServer) {
            return await updatedContacts.map((contact, i) => {
                // debugger
                if (contact.details.given_names) {
                    const givenNames = contact.details.given_names.join(' ')
                    return {
                        label: `${givenNames} ${contact.details.surname}`,
                        value: i,
                        data: contact
                    }
                } else {
                    return {
                        label: undefined,
                        value: undefined,
                    }
                }
            })
        }
        return []
    }

    const handleSendEmail = async (formData) => {
        await sendEmail({body: formData})
    }

    const TopRightElement = (props) => {
        return (
            <TopRightElementWrapper>
                <SVGButton
                    handleClickOut={handleClickOpenDialog}
                    SVGIconIn={PresentToAllIcon}
                />
            </TopRightElementWrapper>
        )
    }

    return (
        <DocumentWrapper className={'DocumentWrapper'}>
            <ContentBanners
                headerTitle={'Contacts Browser'}
                TopRightElement={{component: TopRightElement}}
            >
            <PageWrapper className={'PageWrapper'}>
                <FilterListWrapper>
                    <ListWrapper className={'ListWrapper'}>
                        <ShadowBox className={'ShadowBox'}>
                            <LoaderWrapper isLoaded={isTableLoaded}>
                                <InnerListWrapper>

                                    <SortableTableList
                                        headCells={headCells}
                                        bodyRows={bodyRows}
                                        onSelect={handleTableSelection}
                                    />
                                    <AddButtonFloating
                                        onClick={handleAddContactDialog}
                                    />

                                </InnerListWrapper>
                            </LoaderWrapper>
                        </ShadowBox>
                    </ListWrapper>
                </FilterListWrapper>
                <TodoItemWrapper>
                    <ShadowBox className={'ShadowBox'} >
                        <ContactFeature
                            contact={selectedContact}
                        />
                        {selectedContact &&
                        <EditButtonFloating
                            onClick={handleEditContactDialog}
                        />
                        }


                    </ShadowBox>
                </TodoItemWrapper>
            </PageWrapper>
                <div>
                    <EditContactForm
                        isAddNewContactIn={addNewContact}
                        contactFormObjectIn={selectedContact}
                        showDialogIn={showEditDialog}
                        createContactOut={handleCreateNewContact}
                        updateContactOut={handleUpdateNewContact}
                        deleteContactOut={handleDeleteNewContact}
                        closeDialogOut={handleCloseEditDialog}
                    />
                    <PdfGeneratorList
                        showDialog={showExportDialog}
                        setShowDialog={setShowExportDialog}
                        listArrayIn={bodyRows}
                        fieldFiltersIn={filterFieldList}
                        DisplayListElementIn={PdfListDiv}
                        PdfFrameIn={PdfFrame}
                        PdfListElementIn={PdfListElement}
                        fetchContactsOut={handleFetchContacts}
                        sendEmailOut={handleSendEmail}
                    />
                </div>

            </ContentBanners>
        </DocumentWrapper>

    )
}

export default withRouter(TodoList)