import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const CenteredDialogButtonWrapper = styled.div`
  width: 100%;
  padding: 10px;
display: flex;
  flex-direction: row;
  justify-content: center;
  .MuiDialogActions-root {
    width: 100%;
    justify-content: center;
  }

`


const DialogContentWrapperColumn = styled.div`
  width: 500px;
  height: 100%;
  padding: 10px 30px;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .MuiFormControl-root {
    width: 100%;
   
  }
  
  p {
    margin: 20px 0 0 0;
    font-weight: bold;
  }


`


const StyledTextArea = styled.textarea`
  height: 100px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  margin-top: 7px;
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const contactFormObjectInit = {
    name: '',
    surname: '',
    employer: '',
    position: '',
    phone: '',
    email: '',
    address: '',
    postcode: '',
    suburb: '',
    note: '',
}


const EditContactForm = (props) => {

    const {
        classesIn = {base: {}},
        isAddNewContactIn = false,
        contactFormObjectIn = contactFormObjectInit,
        showDialogIn,
        createContactOut = (contactUUID) => {},
        updateContactOut = (uuid, contactObj) => {},
        deleteContactOut = () => {},
        closeDialogOut = () => {}

    } = props

    const [contactFormObject, setContactFormObject] = useState(contactFormObjectInit)
    const [openConfirmDeleteContact, setOpenConfirmDeleteContact] = useState(false)


    useEffect(() => {
        if (contactFormObjectIn && !isAddNewContactIn) {
            setContactFormObject(contactFormObjectIn)
        } else {
            setContactFormObject(contactFormObjectInit)
        }
    }, [contactFormObjectIn, isAddNewContactIn])

    const handleCreateDialogEditContact = () => {
        createContactOut(contactFormObject)
    }

    const handleUpdateDialogEditContact = () => {
        updateContactOut(contactFormObject.id, contactFormObject)
    }

    const handleDeleteDialogEditContact = () => {
        setOpenConfirmDeleteContact(true)
    }

    const handleCloseDialogEditContact = (event) => {
        closeDialogOut()
    }

    const handleChangeCreateContactForm = (event, key) => {
        setContactFormObject(prev => {
            return {...prev, [key]: event.target.value}
        })
    }

    const handleCancelDeleteAlert = () => {
        setOpenConfirmDeleteContact(false)
    }

    const handleConfirmDeleteAlert = () => {
        deleteContactOut(contactFormObject.id)
        setOpenConfirmDeleteContact(false)
    }

    return (
        <>
            <Dialog open={showDialogIn} onClose={() => {}} aria-labelledby="form-dialog-title">
                <CenteredDialogTitleWrapper id="form-dialog-title" >{isAddNewContactIn ? 'CREATE CONTACT' : 'EDIT CONTACT'}</CenteredDialogTitleWrapper>
                <DialogContent>
                    <DialogContentWrapperColumn>
                        <p>Name</p>
                        <TextField
                            className={classesIn.root}
                            inputProps={{ 'aria-label': 'naked' }}
                            placeholder={'Required'}
                            value={contactFormObject.name}
                            onChange={(event) => handleChangeCreateContactForm(event, 'name')}
                            required={true}
                        />
                        <p>Surname</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.surname}
                            onChange={(event) => handleChangeCreateContactForm(event, 'surname')}
                        />
                        <p>Employer</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.employer}
                            onChange={(event) => handleChangeCreateContactForm(event, 'employer')}
                        />
                        <p>Position</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.position}
                            onChange={(event) => handleChangeCreateContactForm(event, 'position')}
                        />
                        <p>Phone</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.phone}
                            onChange={(event) => handleChangeCreateContactForm(event, 'phone')}
                        />
                        <p>Email</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.email}
                            onChange={(event) => handleChangeCreateContactForm(event, 'email')}
                        />
                        <p>Address</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.address}
                            onChange={(event) => handleChangeCreateContactForm(event, 'address')}
                        />
                        <p>Postcode</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.postcode}
                            onChange={(event) => handleChangeCreateContactForm(event, 'postcode')}
                        />
                        <p>Suburb</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={contactFormObject.suburb}
                            onChange={(event) => handleChangeCreateContactForm(event, 'suburb')}
                        />
                        <p>Note</p>
                        <StyledTextArea
                            value={contactFormObject.note}
                            onChange={(event) => handleChangeCreateContactForm(event, 'note')}
                        />

                    </DialogContentWrapperColumn>
                </DialogContent>
                <CenteredDialogButtonWrapper>
                    <Button onClick={handleCloseDialogEditContact} color="primary">
                        CLOSE
                    </Button>
                    {isAddNewContactIn ? (
                        <Button onClick={handleCreateDialogEditContact} color="primary">
                            CREATE
                        </Button>
                    ) : (
                        <>
                            <Button onClick={handleDeleteDialogEditContact} color="primary">
                                DELETE
                            </Button>
                            <Button onClick={handleUpdateDialogEditContact} color="primary">
                                UPDATE
                            </Button>
                        </>
                    )}

                </CenteredDialogButtonWrapper>
            </Dialog>
            <Dialog
                open={openConfirmDeleteContact}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Contact?"}</DialogTitle>
                <AlertButtonsWrapper>
                    <Button onClick={handleCancelDeleteAlert} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteAlert} color="primary" autoFocus>
                        Confirm
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </>
    )

};

export default EditContactForm;