import React, {useEffect, useState, useRef} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import useRESTCRUD from "../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import BackButton from "../../../templates/buttons/BackButton";
import TextButton from "../../../templates/buttons/TextButton";
import ContentBanners from "../../../frames/content/ContentBanners";
import ShadowBox from "../../../templates/boxes/ShadowBox";
import PdfViewerServices from "../../../templates/pdf/PdfViewerServices/PdfViewerServices";
import FlexRowWrapper from "../../../templates/wrappers/FlexRowWrapper";
import "../../../templates/wrappers/FlexColumnWrapper";
import FlexColumnWrapper from "../../../templates/wrappers/FlexColumnWrapper";
import TabBox from "../../../templates/boxes/TabBox/TabBox";
import LoaderWrapper from "../../../templates/wrappers/LoaderWrapper";
import {makeStyles} from "@material-ui/core";
import ContactTable from "../../../templates/tables/ContactTable/ContactTable";
import useFetch from "../../../../appModules/StateBot/library/helpers/hooks/useFetch";
import EditDocumentForm from "./EditDocumentForm";
import {
    mapContactObjectToContactServer,
    mapContactServerToContactObject,
    mapProcessDocumentServerToProcessDocumentObject,
    mapTodoItemToTodoServer,
    mapTodoServerToTodoItem
} from "../../../../library/helpers/functions/dataMaps";
import TagBox from "../../../templates/tags/TagBox/TagBox";
import SimpleCheckboxList from "../../../templates/checkboxes/SimpleCheckboxList/SimpleCheckboxList";
import ConfirmDeleteDialog from "../../../templates/dialogs/ConfirmDelete";
import ConfirmRemoveDialog from "../../../templates/dialogs/ConfirmRemove";


const PdfSmallDisplayToggle = styled.div`
  display: ${props => props.display === 0 ? 'flex' : 'none'};
  height: 100%;
  width: 80%;
  justify-content: center;
  align-items: center;
  background-color: #666666;
  
`



const PdfMedDisplayToggle = styled.div`

  display: ${props => props.display === 1 ? 'flex' : 'none'};
  height: 100%;
  width: 80%;
  justify-content: center;
  align-items: center;
  background-color: #666666;
  
`

const DataWrapper = styled.div`
  height: 100%;
  width: 120%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #EFEFEF;
  
`

const DataBodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 8px;
  //background-color: #EFEFEF;
`

const DataBlockUpperWrapper = styled.div`
  max-height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  //justify-content: space-around;
  //align-items: ;
  padding: 8px 8px;
  //background-color: #c65151;
`

const DataBlockUpperInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  //align-items: ;
  padding: 16px 30px 5px 30px;
  //background-color: #c65151;
`

const StyledH5 = styled.h5`
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
`

const StyledH3 = styled.h3`
  font-size: 28px;
  text-align: center;
`

const StyledP = styled.p`
  font-size: 20px;
  padding: 3px;
`

const DataBlockUpperInnerUpperWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  

`

const DataBlockUpperInnerLowerWrapper = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`



const TextPairWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;

  h5 {
    //width: 10%;
    width: 180px;
    text-align: right;
    padding-right: 10px;
  }
  
  p {
    width: 85%;
  }
  //background-color: #c65151;
`

const UpperDataBlockLineSpacer = styled.div`
  height: 1px;
  background-color: #a9a9a9;
  width: 75%;
`

const TagWrapper = styled.div`
  width: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`



const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 10px 10px;

  //background-color: #c65151;
`
const DescriptionDiv = styled.div`
  //min-height: 50px;
  max-height: 80px;
  overflow-y: auto;
`

const DataBlockLowerWrapper = styled.div`
  height: 130%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px;
  //background-color: #c65151;
`

const DataBlockLowerInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  position: relative;

  //background-color: #c65151;
`


const InnerWrapper = styled.div`
  position: absolute;
  //display: flex;
  //flex-direction: column;
  //justify-content: start;
  //align-items: start;
  height: 100%;
  width: 100%;
    //overflow-y: scroll;
  z-index: 100;
  

  //background-color: #c65151;
`

const StyledTextArea = styled.textarea`
  height: 100%;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  padding: 10px;
`


const headCells = [
    { id: 'fullName', numeric: false, disablePadding: false, label: 'Name' },
    { id: 'position', numeric: false, disablePadding: false, label: 'Position' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },

];


const Document = (props) => {


    // const [tasksGroupRefDocumentServer, hydrateTasksGroupRefDocumentServer, setTasksGroupRefDocumentServer, isTasksGroupRefDocumentFetching] = useRESTCRUD({
    //     endpoint: 'tasksGroupRefDocument',
    //     instance: props.procDoc.id,
    // })
    //
    //
    // const classes = useStyles();

    const [processDocumentServer, hydrateProcessDocumentServer, setProcessDocumentServer, isProcessDocumentServerFetching] = useRESTCRUD({
        endpoint: 'processDocument',
        instance: props.procDoc.id,
    })

    const documentRequest = {
        endpoint: 'document',
        instance: props.procDoc.docId,
    }

    const [documentServer, hydrateDocumentServer, setDocumentServer, isDocumentFetching] = useRESTCRUD(documentRequest)

    const proxyFileRequest = {
        endpoint: 'proxyFile',
        instance: props.procDoc.proxyFile,
    }

    const [proxyFileServer, hydrateProxyFileServer, setProxyFileServer, isProxyFileFetching] = useRESTCRUD(proxyFileRequest)


    const contactsDocRequest = {
        endpoint: 'contactDocument',
        filterObject: {
            document: props.procDoc.docId,
        }
    }

    const [contactsDocServer, hydrateContactsDocServer, setContactsDocServer, isContactsDocLoading] = useRESTCRUD(contactsDocRequest)

    // const contactsRequest = {
    //     endpoint: 'contact',
    //     options: {
    //         initFetch: false
    //     }
    // }
    //
    // const [contactsServer, hydrateContactsServer, setContactsServer, isContactsLoading] = useRESTCRUD(contactsRequest)

    const tasksRequest = {
        endpoint: 'taskItem',
        filterObject: {
            document_process: props.procDoc.id,
        },
        options: {
            writeOnly: true
        }
    }

    const [tasksServer, hydrateTodosServer, setTodosServer, isTodosLoading] = useRESTCRUD(tasksRequest)

    const taskItemProcessDocument = {
        endpoint: 'tasksItemRefDocument',

    }

    const [taskItemProcessDocumentServer, hydrateTaskItemProcessDocumentServer, setTaskItemProcessDocumentServer, isTaskItemProcessDocumentServerLoading] = useRESTCRUD(taskItemProcessDocument)






    const tagRequest = {
        endpoint: 'tag',
        options: {
            initFetch: false
        }
    }

    const [tagServer, hydrateTagServer, setTagServer, isTagLoading] = useRESTCRUD(tagRequest)

    const tagProcessDocumentRequest = {
        endpoint: 'tagProcessDocument',
        // filterObject: {
        //     document_process: props.procDoc.id,
        // },
        options: {
            initFetch: false
        }
    }

    const [tagProcessDocumentServer, hydrateTagProcessDocumentServer, setTagProcessDocumentServer, isTagProcessDocumentLoading] = useRESTCRUD(tagProcessDocumentRequest)

    const contactAddUpdateDeleteRequest = {
        endpoint: 'updateContact',
    }

    const [addUpdateDeleteContact] = useFetch(contactAddUpdateDeleteRequest)

    const contactsRequest = {
        endpoint: 'contact',
    }

    const [contactsServer, hydrateContactsServer, setContactsServer, isContactsLoading] = useRESTCRUD(contactsRequest)


    // const createTaskItemRequest = {
    //     endpoint: 'taskItemCreate',
    // }

    // const [createTaskItem] = useFetch(createTaskItemRequest)
    //
    //
    //
    // const createTaskItemRefProcDocRequest = {
    //     endpoint: 'taskItemRefProcDocCreate',
    // }

    // const [createTaskItemRefProcDoc] = useFetch(createTaskItemRefProcDocRequest)
    //
    // const setContactInstanceServer = useRESTSet(contactsRequest)
    // const setTodoInstanceServer = useRESTSet(tasksRequest)

    const [smallUrl, setSmallUrl] = useState('')
    const [medUrl, setMedUrl] = useState('')
    const [largeUrl, setLargeUrl] = useState('')
    const [loadSeq, setLoadSeq] = useState(0)
    const [noteValue, setNoteValue] = useState('')
    const [documentState, setDocumentState] = useState(props.procDoc)
    const [tasksState, setTaskState] = useState([])
    const [contactsDocState, setContactsDocState] = useState([])
    const [isTodosLoaded, setIsTodosLoaded] = useState(false)
    const [isContactsLoaded, setIsContactsLoaded] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const noteFetchCache = useRef('')
    const [documentLoaded, setDocumentLoaded] = useState(false)
    const [tagList, setTagList] = useState(false)
    const [removeItemUuid, setRemoveItemUuid] = useState("")
    const [showRemoveTodoItemDialog, setShowRemoveTodoItemDialog] = useState(false)
    const [showDeleteTodoItemDialog, setShowDeleteTodoItemDialog] = useState(false)


    useEffect(() => {
        if (!isContactsDocLoading || isContactsLoaded) {
            setIsContactsLoaded(true)
        }
    }, [isContactsDocLoading])

    useEffect(() => {
        if (proxyFileServer) {
            setSmallUrl(`${process.env.REACT_APP_FILE_SERVER}/proxy-files/${proxyFileServer.proxy_instance.pdf_small}.pdf`)
            setLargeUrl(`${process.env.REACT_APP_FILE_SERVER}/proxy-files/${proxyFileServer.proxy_instance.pdf_large}.pdf`)
        }
    }, [proxyFileServer])

    useEffect(() => {
        setInterval(async () => {
            if (noteFetchCache.current) {
                const cachedNote = noteFetchCache.current
                noteFetchCache.current = ''
                await setDocumentServer({note: cachedNote})
            }
        }, 1000)
    }, [])

    useEffect(() => {
        if (processDocumentServer) {
            setDocumentState(mapProcessDocumentServerToProcessDocumentObject(processDocumentServer))
            setTaskState(prev => processDocumentServer.taskitemrefdocumentprocess_set.map((taskRef, i) => {
                return mapTodoServerToTodoItem(taskRef.task_item)
            }))
            setIsTodosLoaded(true)
            setTagList(prev => processDocumentServer.tagprocessdocument_set.map((taskRef, i) => {
                return taskRef.tag
            }))
            if (!processDocumentServer._cache && !documentLoaded) {
                setNoteValue(processDocumentServer.document.note)
                setDocumentLoaded(true)
            }
        }
    }, [processDocumentServer, documentServer, documentLoaded])


    useEffect(() => {
        if (contactsDocServer) {
            const mappedContacts = contactsDocServer.map((contact, i) => {
                return mapContactServerToContactObject(contact)
            })
            setContactsDocState(mappedContacts)
        }
    }, [contactsDocServer])

    const returnToList = () => {
        props.history.push('/document/list/')
    };

    const handleSmallLoaded = () => {
        setMedUrl(`${process.env.REACT_APP_FILE_SERVER}/proxy-files/${proxyFileServer.proxy_instance.pdf_med}.pdf`)
    }

    const handleMedLoaded = () => {

        setLoadSeq(prev => prev + 1)
    }

    const handleFetchContacts = async () => {
        const updatedContacts = await hydrateContactsServer()
        if (contactsServer) {
            return await updatedContacts.map((contact, i) => {
                // debugger
                if (contact.details.given_names) {
                    const givenNames = contact.details.given_names.join(' ')
                    return {
                        label: `${givenNames} ${contact.details.surname}`,
                        value: i,
                        data: contact
                    }
                } else {
                    return {
                        label: undefined,
                        value: undefined,
                    }
                }
            })
        }
        return []
    }


    const handleCreateContact = async (contactObj) => {
        await setContactsDocServer(mapContactObjectToContactServer(contactObj))
        await hydrateContactsDocServer()
    }

    const handleUpdateContact = async (uuid, contactObj) => {
        const requestObj = {
            method: 'PATCH',
            body: JSON.stringify(mapContactObjectToContactServer(contactObj))
        }
        const paramObj = {
            instance: uuid
        }
        await addUpdateDeleteContact(requestObj, paramObj)
        await hydrateContactsDocServer()
    }

    const handleDeleteContact = async (uuid) => {
        const requestObj = {
            method: 'DELETE',
            body: JSON.stringify({document: props.procDoc.docId, contact: uuid})
        }
        await addUpdateDeleteContact(requestObj)
        await hydrateContactsDocServer()
    }

    const handleAddContact = async (contact) => {
        await addUpdateDeleteContact({
            body: JSON.stringify({
                document: props.procDoc.docId,
                contact: contact.id
            })
        })
        await hydrateContactsDocServer()
    }

    const handleNewAddTodoItem = async (newGroupObject) => {
        await setTodosServer(mapTodoItemToTodoServer(newGroupObject))
        await hydrateProcessDocumentServer()
    }

    const handleAssignTodoItem = async (uuid, todoObj) => {

        await setTaskItemProcessDocumentServer({
            document_process: props.procDoc.id,
            task_item: uuid
        })
        await hydrateProcessDocumentServer()
    }

    const handleUpdateTodoItem = async (groupObject, instanceIndex) => {
        const taskGroupServerObj = mapTodoItemToTodoServer(groupObject)
        await setTodosServer(taskGroupServerObj, tasksState[instanceIndex].id)
        await hydrateProcessDocumentServer()
    }

    const handleRemoveTodoItem = async (uuid) => {
        await setRemoveItemUuid(uuid)
        await setShowRemoveTodoItemDialog(true)
    }
    const handleConfirmRemoveTodoItem = async () => {
        const removeItem = processDocumentServer.taskitemrefdocumentprocess_set.filter(item => {
            return item.task_item.id === removeItemUuid
        })[0]
        await setTaskItemProcessDocumentServer(null, removeItem.id)
        await hydrateProcessDocumentServer()
        await setShowRemoveTodoItemDialog(false)
    }

    const handleCancelRemoveTodoItem = () => {
        setShowRemoveTodoItemDialog(false)
    }

    const handleDeleteTodoItem = async () => {
        await setShowDeleteTodoItemDialog(true)

    }

    const handleConfirmDeleteTodoItem = async () => {
        await setShowDeleteTodoItemDialog(false)
        await setShowRemoveTodoItemDialog(false)
        const removeItem = processDocumentServer.taskitemrefdocumentprocess_set.filter(item => {
            return item.task_item.id === removeItemUuid
        })[0]
        await setTaskItemProcessDocumentServer(null, removeItem.id)
        await setTodosServer(null, removeItemUuid)
        await hydrateProcessDocumentServer()
    }

    const handleCancelDeleteTodoItem = async () => {
        await setShowDeleteTodoItemDialog(false)
        await setShowRemoveTodoItemDialog(false)
    }

    const handleChangeNote = (event) => {
        if (event.target.value === '') {
            setDocumentServer({note: event.target.value})
        }
        setNoteValue(event.target.value)
        noteFetchCache.current = event.target.value
    }

    const handleOpenDocForm = () => {
        setShowEditForm(true)
    }

    const handleUpdateDocForm = async (uuid, docObj) => {
        setShowEditForm(false)
        const processDocObj = {
            document: docObj.docId,
            process: docObj.processProjectId
        }
        const documentObj = {
            name: docObj.name,
            description: docObj.description
        }
        await setDocumentServer(documentObj)
        await setProcessDocumentServer(processDocObj)

    }

    const handleDeleteDocForm = async () => {
        setShowEditForm(false)
        await setProcessDocumentServer(null, props.procDoc.id)
        props.history.push('/document/list/')
    }

    const handleCloseDocForm = () => {
        setShowEditForm(false)
    }

    const handleFetchTagsOut = async () => {
        const updatedTags = await hydrateTagServer()
        if (updatedTags) {
            return updatedTags.map((tag, i) => {
                return {label: tag.tag, value: i, data: tag}
            })
        }
        return []
    }

    const handleAddTag = async (uuid) => {
        await setTagProcessDocumentServer({tag: uuid, document_process: props.procDoc.id})
        await hydrateProcessDocumentServer()
    }

    const handleDeleteTag = async (uuid) => {
        const tagDocInstance = processDocumentServer.tagprocessdocument_set.filter((tagProcDoc) => {
            return uuid === tagProcDoc.tag.id
        })[0]
        await setTagProcessDocumentServer(null, tagDocInstance.id)
        await hydrateProcessDocumentServer()
    }

    const ContactTab = (
        <DataBlockLowerInnerWrapper>
            <LoaderWrapper
                isLoaded={isContactsLoaded}
            >
                <ContactTable
                    headCells={headCells}
                    bodyRows={contactsDocState}
                    fetchContactsOut={handleFetchContacts}
                    createContactOut={handleCreateContact}
                    addContactOut={handleAddContact}
                    updateContactOut={handleUpdateContact}
                    deleteContactOut={handleDeleteContact}
                    // onSelect={handleTableSelection}
                />
            </LoaderWrapper>
        </DataBlockLowerInnerWrapper>
    )

    const TaskTab = (
        <DataBlockLowerInnerWrapper>
            <LoaderWrapper
                isLoaded={isTodosLoaded}
            >
                <InnerWrapper>
                    <SimpleCheckboxList
                        checkboxItemArray={tasksState}
                        onAddItemOut={handleNewAddTodoItem}
                        onAssignTodo={handleAssignTodoItem}
                        onSetItemOut={handleUpdateTodoItem}
                        onDeleteItemOut={handleRemoveTodoItem}
                    />
                </InnerWrapper>
            </LoaderWrapper>
        </DataBlockLowerInnerWrapper>
    )

    const NoteTab = (
        <DataBlockLowerInnerWrapper>
            <StyledTextArea value={noteValue} onChange={handleChangeNote}/>
        </DataBlockLowerInnerWrapper>
    )

    const tabArray = [
        {title: 'Contacts', component: ContactTab},
        {title: 'Tasks', component: TaskTab},
        {title: 'Note', component: NoteTab}

    ]

    return (
        <>
            <ContentBanners
                headerTitle={'Document Viewer'}
                TopLeftElement={{component: BackButton, props: {
                        label: 'Document List',
                        output: returnToList
                    }}}
                TopRightElement={{component: TextButton, props: {
                        label: 'Edit',
                        output: handleOpenDocForm
                    }}}
                BottomLeftElement={{component: TextButton, props: {
                        label: 'Delete'
                    }}}
                BottomCenterElement={{component: TextButton, props: {
                        label: 'Share'
                    }}}
                BottomRightElement={{component: TextButton, props: {
                        label: 'Print'
                    }}}
                topLeftOut
                topRightOut
                bottomLeftOut
                bottomCenterOut
                bottomRightOut
            >
                <FlexRowWrapper divHeight={'100%'} divWidth={'100%'} flexJustifyContent={'space-between'}>
                    <PdfSmallDisplayToggle display={loadSeq}>
                        <PdfViewerServices
                            displayUrl={smallUrl}
                            exportUrl={largeUrl}
                            setLoaded={handleSmallLoaded}
                            fetchContactsOut={handleFetchContacts}
                            pages={props.procDoc.pages}
                        />
                    </PdfSmallDisplayToggle>
                    <PdfMedDisplayToggle display={loadSeq}>
                        <PdfViewerServices
                            displayUrl={medUrl}
                            exportUrl={largeUrl}
                            setLoaded={handleMedLoaded}
                            fetchContactsOut={handleFetchContacts}
                            pages={props.procDoc.pages}
                        />
                    </PdfMedDisplayToggle>
                    <DataWrapper>
                        <DataBodyWrapper>
                            <DataBlockUpperWrapper>
                                <ShadowBox>
                                    <DataBlockUpperInnerWrapper>
                                        <DataBlockUpperInnerUpperWrapper>
                                            <TextPairWrapper>
                                                <StyledH3>{documentState.name}</StyledH3>
                                            </TextPairWrapper>
                                        </DataBlockUpperInnerUpperWrapper>
                                        <UpperDataBlockLineSpacer/>
                                        <DataBlockUpperInnerLowerWrapper>
                                            <TextPairWrapper>
                                                <StyledH5>Project: </StyledH5>
                                                <StyledP>{documentState.project}</StyledP>
                                            </TextPairWrapper>
                                            <TextPairWrapper>
                                                <StyledH5>Process: </StyledH5>
                                                <StyledP>{documentState.process}</StyledP>
                                            </TextPairWrapper>
                                            <TextPairWrapper>
                                                <StyledH5>Process Type: </StyledH5>
                                                <StyledP>{documentState.processType}</StyledP>
                                            </TextPairWrapper>
                                        </DataBlockUpperInnerLowerWrapper>
                                    {documentState.description &&
                                        <>
                                            <UpperDataBlockLineSpacer/>
                                            <DescriptionWrapper>
                                                <DescriptionDiv>
                                                    <StyledP>{documentState.description}</StyledP>
                                                </DescriptionDiv>
                                            </DescriptionWrapper>
                                        </>
                                    }
                                        {tagList &&
                                            <>
                                                <UpperDataBlockLineSpacer/>
                                                <TagWrapper>
                                                    <TagBox
                                                        tagArrayInput={tagList}
                                                        fetchTagsOut={handleFetchTagsOut}
                                                        deleteTagOut={handleDeleteTag}
                                                        addTagOut={handleAddTag}
                                                    />
                                                </TagWrapper>
                                            </>

                                        }
                                    </DataBlockUpperInnerWrapper>
                                </ShadowBox>
                            </DataBlockUpperWrapper>
                            <DataBlockLowerWrapper>
                             <ShadowBox>
                                 <FlexColumnWrapper divHeight={'100%'}>
                                     <TabBox tabArray={tabArray}/>
                                 </FlexColumnWrapper>
                             </ShadowBox>
                            </DataBlockLowerWrapper>
                        </DataBodyWrapper>
                    </DataWrapper>
                </FlexRowWrapper>
            </ContentBanners>
            <EditDocumentForm
                showDialogIn={showEditForm}
                documentFormObjectIn={documentState}
                updateDocumentOut={handleUpdateDocForm}
                deleteDocumentOut={handleDeleteDocForm}
                closeDialogOut={handleCloseDocForm}
            />
            <ConfirmRemoveDialog
                dialogText={"Remove Item?"}
                onCancel={handleCancelRemoveTodoItem}
                onRemove={handleConfirmRemoveTodoItem}
                onDelete={handleDeleteTodoItem}
                showDialog={showRemoveTodoItemDialog}
            />
            <ConfirmDeleteDialog
                dialogText={"Delete Item?"}
                onCancel={handleCancelDeleteTodoItem}
                onDelete={handleConfirmDeleteTodoItem}
                showDialog={showDeleteTodoItemDialog}
            />
        </>

    )
};

export default withRouter(Document)