export default {
    sideBar: [
        'Sidebar',
        {
            text: [
                'Text',
                {
                    headerText: ['20px'],
                    linkText: ['18px'],
                }
            ],
            buttons: [
                'Buttons',
                {

                }
            ],
        },
    ],
    mainPane: [
        'Main Pane',
        {
            text: [
                'Text',
                {
                    contentListTextLarge: ['18px'],
                    contentListTextSmall: ['16px'],
                    bannerTitle: ['20px'],
                    bannerButton: ['16px'],
                    shadowBoxTitle: ['18px'],
                    contentTextSmall: ['14px'],
                    contentTextMedium: ['16px'],
                    contentTextLarge: ['18px'],
                    contentTextHeader: ['20px'],
                }
            ],
            buttons: [
                'Buttons',
                {

                }
            ],
            scroll: [
                'Scroll',
                {
                    bigScrollWidth: ['10px'],
                    bigScrollBorderRadius: ['5px'],
                }
            ],
        },
    ],
    frames: [
        'Frames',
        {
        topNavHeight: ['0px'],
        }
    ],
}