import React from "react";
import styled from "styled-components";

export default styled.div`
${props => props.divHeight && `height: ${props.divHeight};`}
${props => props.divWidth && `width: ${props.divWidth};`}
display: flex;
flex-direction: column;
justify-content: ${props => props.flexJustifyContent ? props.flexJustifyContent : 'center'};
align-items: ${props => props.flexAlignItems ? props.flexAlignItems : 'center'};
flex: ${props => props.flexGrow ? props.flexGrow : 'auto'}
`;

// export default (props) => {
//     return (
//         <FlexColumnWrapper className={'FlexColumnWrapper'} props={props}/>
//     )
// }