import React, {useEffect, useState} from 'react'

const dummyList = [
    {id: 'abc123xxx', name: 'Some Name', checked: true},
    {id: 'abc234xxx', name: 'Some Name', checked: true},
    {id: 'abc345xxx', name: 'Some Name', checked: true},
]

const filterFieldList = [
    {field: 'name', label: 'Display Name', filter: '', type: 'string'},
    {field: 'name', label: 'Display Name'} // filter and type optional - preset filter
]


const useFilterListSet = (
        listArrayIn = dummyList,
        filterFieldListIn = filterFieldList,
    ) => {

    const [listArray, setListArray] = useState([])
    const [fieldFilterList, setFieldFilterList] = useState([])

    useEffect(() => {
        setFieldFilterList(prev => {
            return filterFieldListIn.map((filterFieldSet) => {
                return {
                    field: filterFieldSet.field,
                    label: filterFieldSet.label,
                    filter: filterFieldSet.filter ? filterFieldSet.filter : '',
                    type: filterFieldSet.type ? filterFieldSet.type : 'string'
                }
            })
        })
    }, [filterFieldListIn])

    useEffect(() => {
        setListArray(prev => {
            return listArrayIn.filter((listElement) => {
                let filterMatch = 0
                fieldFilterList.forEach((filterFieldSet, i) => {
                    if (filterFieldSet.filter) {
                        if (listElement[filterFieldSet.field] === filterFieldSet.filter) {
                            filterMatch ++
                        }
                    } else {
                        filterMatch ++
                    }
                })
                return filterMatch === fieldFilterList.length
            })
        })
    }, [fieldFilterList, listArrayIn])

    const setFilterStrings = (string, index) => {
        setFieldFilterList(prev => {
            prev[index].filter = string
            return [...prev]
        })
    }

    return [listArray, setFilterStrings]

}

export default useFilterListSet