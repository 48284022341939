const generateFilterString = (filterObject, instance = false) => {
    if (instance) {
        return instance
    } else {
        let requestEndpointString = ''
        if (filterObject) {

            requestEndpointString = '?'
            const filterKeys = Object.keys(filterObject)
            filterKeys.forEach((key, i) => {
                requestEndpointString = `${requestEndpointString}${key}=${filterObject[key]}&`
            })
            requestEndpointString = requestEndpointString.slice(0, -1)
            }
        return requestEndpointString
    }

}

export default generateFilterString