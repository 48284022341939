import React from 'react';
import styled from "styled-components";
import TextButton from "./TextButton";
import ButtonWrapper from "./ButtonWrapper";
import ArrowLeft from "../svg/ArrowLeft";

const WrapperDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: default;
  //background-color: #d0d0d0;
  padding: 0 40px 0 20px;


  .ArrowLeft {
    padding-right: 14px;
    position: relative;
    //top: 0.2px;

    svg {
      height: 22px;
      width: 22px;
      fill: #454545;
    }

  }

  .NavHeaderTitleH2 {
    color: #646464;
    font-size: 18px;
  }


  :hover {
    background-color: #e3e3e3;

    svg {
      height: 22px;
      width: 22px;
      fill: #3e3e3e;
    }

    .NavHeaderTitleH2 {
      color: #6b6b6b;

    }
  }



`;



const BackButton = ({className = '', elementProps = {}}) => {

    const clickHandler = () => {
        elementProps.output && elementProps.output()
    };

    return (

            <WrapperDiv className={`BannerNavBackButton ${className}`} onClick={clickHandler}>
                <ArrowLeft />
                <div>
                    <h2 className={'NavHeaderTitleH2'} style={{whiteSpace: 'noWrap'}}>{elementProps.label}</h2>
                </div>
            </WrapperDiv>
    )

}

export default BackButton;