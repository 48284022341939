import React from "react";
import styled from "styled-components";

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ArrowLeft = () => {

    return (
        <WrapperDiv className={'ArrowLeft'}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path d="M40 22H15.66l11.17-11.17L24 8 8 24l16 16 2.83-2.83L15.66 26H40v-4z"/>
            </svg>
        </WrapperDiv>
    )
};

export default ArrowLeft