import React from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogTitle} from "@material-ui/core";

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`


const ConfirmRemoveDialog = (props) => {

    const {
        dialogText = "Remove Item?",
        showDialog = false,
        onCancel = () => {},
        onDelete = () => {},
        onRemove = () => {},
    } = props

    return (
        <Dialog
            open={showDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogText}</DialogTitle>
            <AlertButtonsWrapper>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onDelete} color="primary" autoFocus>
                    Delete
                </Button>
                <Button onClick={onRemove} color="primary" autoFocus>
                    UnAssign
                </Button>

            </AlertButtonsWrapper>
        </Dialog>
    )

};

export default ConfirmRemoveDialog;