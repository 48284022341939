import React from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogTitle} from "@material-ui/core";

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`


const ConfirmDeleteDialog = (props) => {

    const {
        dialogText = "Delete Item?",
        onCancel = () => {},
        onDelete = () => {},
        showDialog = false,
    } = props

    return (
        <Dialog
            open={showDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogText}</DialogTitle>
            <AlertButtonsWrapper>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onDelete} color="primary" autoFocus>
                    Confirm
                </Button>
            </AlertButtonsWrapper>
        </Dialog>
    )

};

export default ConfirmDeleteDialog;