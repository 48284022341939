import React, {useState, useEffect} from 'react'
import {Droppable} from "react-beautiful-dnd";
import styled from "styled-components"
import ListItem from "./ListItem";

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ListColumn = ({
        orderedListArray = [],
        ListFrameComponent,
        ListItemComponent
    }) => {
    return (
        <ListFrameComponent>
            <Droppable
                droppableId={'MAIN'}
            >
                {(provided) => {
                    return (
                        <ListWrapper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                        >
                            {
                                orderedListArray.map((listItem, i) => {
                                    return (
                                        <ListItem
                                            key={listItem.baseKey}
                                            itemIndex={i}
                                            baseKey={listItem.baseKey}
                                            listItem={listItem}
                                            ListItemComponent={ListItemComponent}
                                        />
                                    )
                                })
                            }
                            {provided.placeholder}
                        </ListWrapper>
                    )
                }}
            </Droppable>
        </ListFrameComponent>
    )

}

export default ListColumn