import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import GroupCheckbox from "../GroupCheckbox/GroupCheckbox";
import AddButtonFloating from "../../buttons/AddButtonFloating";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import SimpleCheckbox from "../SimpleCheckbox/SimpleCheckbox";
import SimpleDropdown from "../../dropdowns/SimpleDropdown/SimpleDropdown";
import useRESTCRUD from "../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";

const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow-y: scroll;
  padding: ${props => props.padding};
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 120px;
  }
`

const defaultCheckboxGroup = [
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
]

// task: add ordering of groups and items (maybe allow items to be moved between groups)
// ultimately groups will be virtualized...
// task: add splash if no items to show
const GroupCheckboxList = (props) => {

    const {
        padding = 0,
        loadOnce = false,
        checkboxItemArray = defaultCheckboxGroup,
        onAssignTodo = (uuid, todoObj) => {},
        onAddItemOut = (newGroupObject) => {},
        onSetItemOut = (groupObject, instanceIndex) => {},
        onDeleteItemOut = (instanceIndex) => {},
        onRemoveItemOut = (index) => {}
    } = props

    const [checkboxGroupItems, setCheckboxGroupItems] = useState(checkboxItemArray)
    const [checkboxServerItems, setCheckboxServerItems] = useState([])
    const [openAssignTaskDialog, setOpenAssignTaskDialog] = useState(false)
    const [openNewTaskDialog, setOpenNewTaskDialog] = useState(false)
    const [assignTodoObj, setAssignTodoObj] = useState([])
    const [newTodoName, setNewTodoName] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)


    const [todoDropDownList, setTodoDropdownList] = useState([])


    const [taskItemServer, hydrateTaskItemServer, setTaskItemServer, isFetchingItemServer] = useRESTCRUD({
        endpoint: 'taskItem',
    })

    useEffect(() => {
    }, [openAssignTaskDialog, openNewTaskDialog])

    useEffect(() => {

        if (checkboxServerItems && checkboxGroupItems) {
            const newDropdownList = []
            checkboxServerItems.forEach(item => {
                let hasItem = false
                checkboxGroupItems.forEach(existingItem => {
                    if (item.id === existingItem.id) {
                        hasItem = true
                    }
                })
                if (!hasItem) {
                    newDropdownList.push({
                        label: item.task,
                        value: item.id,

                    })
                }

            })
            setTodoDropdownList(newDropdownList)
        }


    }, [checkboxServerItems, checkboxGroupItems])

    useEffect(() => {
        const asyncFunc = async () => {
            const newServerItems = await hydrateTaskItemServer()
            setCheckboxServerItems(newServerItems)
            if (checkboxItemArray) {
                if (loadOnce) {
                    if (!isLoaded) {
                        setCheckboxGroupItems(checkboxItemArray)
                    }

                } else {
                    setCheckboxGroupItems(checkboxItemArray)
                }
                setIsLoaded(true)
            }

        }
        asyncFunc().then()
    }, [checkboxItemArray, loadOnce, isLoaded])

    const handleDeleteItem = (uuid) => {
        onDeleteItemOut(uuid)
        // setCheckboxItems(prev => {
        //     const index = prev.map(e => e.id).indexOf(uuid)
        //     prev.splice(index, 1)
        //     return [...prev]
        // })
    }

    const handleClickAddButton = () => {
        setOpenAssignTaskDialog(true)
    }

    const handleAddDialog = (event) => {
        setOpenAssignTaskDialog(false)
        onAssignTodo(assignTodoObj[0].id, assignTodoObj[0])




        // if (newTodoName.trim()) {
        //     const newItem = {
        //         taskString: newTodoName.trim(),
        //         isCheckedBool: false,
        //     }
        //     setCheckboxItems(prev => {
        //         return [...prev, newItem]
        //     })
        //     newItem.priority = checkboxItems.length
        //     onAddItemOut(newItem)
        // }
        // setNewTodoName('')
    }

    const handleToggleAddNewTask = (event) => {
        setOpenNewTaskDialog(true)
    }

    const handleCancelAddNewTask = () => {
        setOpenNewTaskDialog(false)
        setNewTodoName('')
    }

    const handleAddNewTaskSave = async () => {
        await setOpenNewTaskDialog(false)
        await setOpenAssignTaskDialog(false)
        if (newTodoName.trim()) {
            const newItem = {
                taskString: newTodoName.trim(),
                isCheckedBool: false,
            }
            setCheckboxGroupItems(prev => {
                return [...prev, newItem]
            })
            newItem.priority = checkboxGroupItems.length
            onAddItemOut(newItem)
        }
        setNewTodoName('')



    }

    const handleEnterDialog = async (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            await handleAddNewTaskSave()
        }
    }

    const handleCancelDialog = () => {
        setOpenAssignTaskDialog(false)
    }
    const handleSelectItem = (uuid) => {
        const selectTodoItem = taskItemServer.filter(item => item.id === uuid)
        setAssignTodoObj(selectTodoItem)
    }

    const handleChangeNewItem = (event) => {

        setNewTodoName(event.target.value)
    }


    // `
    //             onCheckGroupOut={() => {}}
    //             onSetHeaderOut={() => {}}
    //             onToggleExpandedOut={() => {}}
    //             onSetGroupOut={onSetGroupOut}
    //             onDeleteGroupOut={handleDeleteCheckboxGroup}
    //
    // `

    const checkboxItemsArray = checkboxGroupItems.map((checkboxItem, i) => {
        return (
            <SimpleCheckbox
                key={`${checkboxItem.task}_${i}`}
                instanceIndex={i}
                inputItemObject={checkboxItem}
                expandedBool={false}
                onSetItemOut={onSetItemOut}
                onDeleteItemOut={handleDeleteItem}

            />
        )
    })

    return (
        <BodyWrapper
            className={'SimpleCheckBoxList'}
            padding={padding}
        >
                {checkboxItemsArray}
                <div>
                    <AddButtonFloating
                        onClick={handleClickAddButton}
                    />
                </div>
            <Dialog open={openAssignTaskDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{'Assign Task To Document'}</DialogTitle>
                <DialogContent>

                    <SimpleDropdown
                        title={'Todo'}
                        inputArray={todoDropDownList}
                        onSelect={handleSelectItem}
                    >

                    </SimpleDropdown>
                </DialogContent>

                <AlertButtonsWrapper>

                    <Button onClick={handleCancelDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleToggleAddNewTask} color="primary" >
                        New Task
                    </Button>
                    <Button onClick={handleAddDialog} color="primary">
                        Add
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
            <Dialog open={openNewTaskDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{'Add new task'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Todo Label"
                        fullWidth
                        value={newTodoName}
                        onChange={handleChangeNewItem}
                        onKeyDown={handleEnterDialog}
                    />

                </DialogContent>
                <AlertButtonsWrapper>

                    <Button onClick={handleCancelAddNewTask} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddNewTaskSave} color="primary">
                        Add
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </BodyWrapper>
    )

};

export default GroupCheckboxList;