
export const mapContactServerToContactObject = (contactObject) => {


    let employmentId = ''
    let employer = ''
    let position = ''

    if (contactObject.employmentpoint_set.length > 0) {
        employmentId = contactObject.employmentpoint_set[0].id
        employer = contactObject.employmentpoint_set[0].organisation
        position = contactObject.employmentpoint_set[0].role
    }

    let phoneId = ''
    let phoneNumber = ''
    let emailId = ''
    let email = ''

    if (contactObject.contactpoint_set) {
        let phoneNumberObject = contactObject.contactpoint_set.filter(contactPoint => contactPoint.type === 'PHONE')
        phoneNumberObject[0] ? phoneNumber = phoneNumberObject[0].contact_point : phoneNumber = ''
        phoneNumberObject[0] ? phoneId = phoneNumberObject[0].id : phoneId = ''
        let emailAddressObject = contactObject.contactpoint_set.filter(contactPoint => contactPoint.type === 'EMAIL')
        emailAddressObject[0] ? email = emailAddressObject[0].contact_point : email = ''
        emailAddressObject[0] ? emailId = emailAddressObject[0].id : emailId = ''

    }

    let addressId = ''
    let address = ''
    let postcode = ''
    let suburb = ''

    if (contactObject.address_set?.length > 0) {
        addressId = contactObject?.address_set[0].id
        postcode = contactObject?.address_set[0].postcode
        suburb = contactObject?.address_set[0]?.suburb
        if (contactObject.address_set[0]?.street_array) {
            address = contactObject.address_set[0]?.street_array[0]
        } else {
            address = ''
        }
    } else {
        address = ''
        postcode = ''
        suburb = ''
    }

    return {
        id: contactObject.id,
        name: contactObject.details.given_names.join(' '),
        surname: contactObject.details.surname,
        fullName: `${contactObject.details.given_names[0]} ${contactObject.details.surname}`,
        employmentId: employmentId,
        employer: employer,
        position: position,
        phoneId: phoneId,
        phone: phoneNumber,
        emailId: emailId,
        email: email,
        addressId: addressId,
        address: address,
        postcode: postcode,
        suburb: suburb,
        note: contactObject.note,
    }
}

export const mapContactObjectToContactServer = (contactObject) => {

    const details = {
        given_names: contactObject.name ? contactObject.name.split(' ') : [],
        surname: contactObject.surname ? contactObject.surname : '',

    }
    const address_set = []
    const contactpoint_set = []
    const employmentpoint_set = []

    let addressSetPushObj = {
        street_array: [contactObject.address],
        suburb: contactObject.suburb,
        postcode: contactObject.postcode,
    }
    if (contactObject.addressId) {
        addressSetPushObj.id = contactObject.addressId
    }
    address_set.push(addressSetPushObj)

    let phonePushObj = {
        type: 'PHONE',
        contact_point: contactObject.phone,

    }
    if (contactObject.phoneId) {
        phonePushObj.id = contactObject.phoneId
    }
    contactpoint_set.push(phonePushObj)

    let emailPushObj = {
        type: 'EMAIL',
        contact_point: contactObject.email
    }
    if (contactObject.emailId) {
        emailPushObj.id = contactObject.emailId
    }
    contactpoint_set.push(emailPushObj)

    let employmentPushObj = {
        organisation: contactObject.employer,
        role: contactObject.position,

    }
    if (contactObject.employmentId) {
        employmentPushObj.id = contactObject.employmentId
    }
    employmentpoint_set.push(employmentPushObj)

    return {
        type: 'person',
        note: contactObject.note ? contactObject.note : '',
        details,
        address_set,
        contactpoint_set,
        employmentpoint_set
    }
}

export const mapTodoServerToTodoGroup = (taskObj) => {
    return {
        id: taskObj.id,
        headerString: taskObj.name,
        isCheckedBool: taskObj.completed,
        checkboxArray: taskObj.taskitem_set.map((taskItem, i) => {
            return {
                id: taskItem.id,
                taskString: taskItem.task,
                isCheckedBool: taskItem.completed
            }
        })
    }

}

export const mapTodoGroupToTodoServer = (taskObj) => {
    let idObj = {}
    if (taskObj.id) {
        idObj = {
            id: taskObj.id
        }
    }
    return {
        ...idObj,
        name: taskObj.headerString,
        completed: taskObj.isCheckedBool,
        taskitem_set: taskObj.checkboxArray.map((taskItem, i) => {

            let itemIdObj = {}
            if (taskItem.id) {
                itemIdObj = {
                    id: taskItem.id
                }
            }
            return {
                ...itemIdObj,
                task: taskItem.taskString,
                completed: taskItem.isCheckedBool
            }
        })
    }
}

export const mapTodoServerToTodoItem = (taskObj) => {
    return {
        id: taskObj.id,
        taskString: taskObj.task,
        isCheckedBool: taskObj.completed,
    }
}

export const mapTodoItemToTodoServer = (taskObj) => {
    let idObj = {}
    if (taskObj.id) {
        idObj = {
            id: taskObj.id
        }
    }
    return {
        ...idObj,
        task: taskObj.taskString,
        completed: taskObj.isCheckedBool,
    }
}

export const mapProcessDocumentServerToProcessDocumentObject = (processDocObj) => {

    return {
        id: processDocObj['id'],
        docId: processDocObj.document['id'],
        name: processDocObj.document['name'],
        processId: processDocObj.process['id'],
        process: processDocObj.process['name'],
        processRef: processDocObj.process['process_ref'],
        processDisplay: `${processDocObj.process['process_ref']}: ${processDocObj.process['name']}`,
        projectId: processDocObj.process.project['id'],
        project: processDocObj.process.project['name'],
        processTypeId: processDocObj.process.process_type['id'],
        processType: processDocObj.process.process_type['name'],
        parentDoc: processDocObj['parent_doc'] ? document['parent_doc'] : '',
        proxyFile: processDocObj.document['proxy_file'],
        note: processDocObj.document['note'],
        description: processDocObj.document['description'],
        pages: processDocObj.document['pages'],
        tags: processDocObj.tagprocessdocument_set.map((tagProcDoc) => {
            return tagProcDoc.tag
        }),
        tagsDisplay: processDocObj.tagprocessdocument_set.map((tagProcDoc) => {
            return tagProcDoc.tag.tag
        }).join(', ')
    }
}

export const mapProcessDocumentObjectToProcessDocumentServer = (processDocObj) => {

}

export const mapTaskGroupProcessServerToTaskGroupProcessObject = (taskGroupProcessObj) => {
    let managerId = ''
    let manager = ''
    let date = ''
    if (taskGroupProcessObj.task_group?.manager?.id) {
        managerId = taskGroupProcessObj.task_group.manager.id
        manager = `${taskGroupProcessObj.task_group.manager.details.given_names.join(' ')} ${taskGroupProcessObj.task_group.manager.details.surname}`
    }
    if (taskGroupProcessObj.task_group?.date) {
        date = taskGroupProcessObj.task_group.date
    }
    return {
        id: taskGroupProcessObj.id,
        taskGroupId: taskGroupProcessObj.task_group.id,
        headerString: taskGroupProcessObj.task_group.name,
        isCheckedBool: taskGroupProcessObj.task_group.completed,
        note: taskGroupProcessObj.task_group.note,
        projectId: taskGroupProcessObj.process.project.id,
        project: taskGroupProcessObj.process.project.name,
        processId: taskGroupProcessObj.process.id,
        process: taskGroupProcessObj.process.name,
        processRef: taskGroupProcessObj.process.process_ref,
        processTypeId: taskGroupProcessObj.process.process_type.id,
        processType: taskGroupProcessObj.process.process_type.name,
        manager: manager,
        managerId: managerId,
        date: date,
        checkboxArray: taskGroupProcessObj.task_group.taskitem_set.map((taskItem, i) => {
            return {
                id: taskItem.id,
                taskString: taskItem.task,
                isCheckedBool: taskItem.completed,
                priority: taskItem.priority
            }
        })
    }
}

export const mapTaskGroupProcessObjectToTaskGroupProcessServer = (taskGroupProcessObj) => {
    return {
        id: taskGroupProcessObj.id,
        task_group: taskGroupProcessObj.taskGroupId,
        process: taskGroupProcessObj.processId
    }
}

export const mapTaskGroupObjectToTaskGroupServer = (taskGroupObj) => {
    return {
        id: taskGroupObj.taskGroupId,
        name: '',
    }
}

export const mapTaskItemObjectToTaskItemServer = (taskItemObj) => {
    return {
        task: taskItemObj.taskString,
        completed: taskItemObj.isCheckedBool,
        priority: taskItemObj.priority
    }
}

export const mapProcessServerToProcessObject = (processObj) => {
    return {
        id: processObj.id,
        name: processObj.name,
        project: processObj.project.name,
        projectId: processObj.project.id,
        processType: processObj.process_type.name,
        processTypeId: processObj.process_type.id,
    }
}