import React, {useEffect, useState} from 'react'
import styled from "styled-components"
import SimpleDropdown from "../SimpleDropdown/SimpleDropdown"

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 30px;
  .MuiFormControl-root {
    width: 100%;
  }
`

const ListFilterDropdown = ({
        labelStringIn = '',
        listArrayIn = [],
        onSelectOut = () => {}
    }) => {

    return (
        <BodyWrapper>
            <SimpleDropdown
                title={labelStringIn}
                inputArray={listArrayIn}
                onSelect={onSelectOut}
                selectNone={true}
            />
        </BodyWrapper>
    )
}

export default ListFilterDropdown
