import React from "react";

import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {getComparator, stableSort} from "../library/helpers/sort";
import styled from "styled-components";

const CellWrapper = styled(TableCell)`
  text-align: left;
`

const StyledP = styled.p`
  text-align: left;

`

export default function EnhancedTableBody(props) {

    return (

        <TableBody>
            {stableSort(props.bodyRows, getComparator(props.order, props.orderBy))
                .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <TableRow
                            hover
                            onClick={(event) => props.onSelect(row)}
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                        >
                            {
                                props.headCells.map((headCell, i) => {
                                    return (

                                        <CellWrapper
                                            className={props.classes.tableCell}
                                            id={labelId}
                                            scope="row"
                                            style={{width: `${headCell.width}`}}
                                            align={headCell.numeric ? "right" : "left"}
                                            key={`${headCell.id}_${i}`}
                                            // padding="checkbox"
                                        >
                                            <StyledP>{row[headCell.id]}</StyledP>
                                        </CellWrapper>
                                            )
                                })
                            }
                        </TableRow>
                    );
                })}
        </TableBody>

    );
}
