import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import useRESTCRUD from "../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../../templates/wrappers/LoaderWrapper";
import SimpleDropdown from "../../../templates/dropdowns/SimpleDropdown/SimpleDropdown";

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`


const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const CenteredDialogButtonWrapper = styled.div`
  width: 100%;
  padding: 10px;
display: flex;
  flex-direction: row;
  justify-content: center;
  .MuiDialogActions-root {
    width: 100%;
    justify-content: center;
  }

`


const DialogContentWrapperColumn = styled.div`
  width: 500px;
  height: 100%;
  padding: 10px 30px;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .MuiFormControl-root {
    width: 100%;
   
  }



`

const StyledP = styled.p`
  margin: 20px 0 0 0;
  font-weight: bold;
`

const StyledPGrey = styled.p`
  margin: 20px 0 0 0;
  font-weight: bold;
  color: #888888;
`


const StyledTextArea = styled.textarea`
  height: 100px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  margin-top: 7px;
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const documentFormObjectInit = {
    name: '',
    project: '',
    process: '',
    processType: '',
    description: '',
}


const EditDocumentForm = (props) => {

    const {
        classesIn = {base: {}},
        documentFormObjectIn = documentFormObjectInit,
        showDialogIn,
        updateDocumentOut = (uuid, contactObj) => {},
        deleteDocumentOut = () => {},
        closeDialogOut = () => {}

    } = props

    const [processProjectServer, hydrateProcessProjectServer, setProcessProjectServer, isProcessProjectServerFetching] = useRESTCRUD({
        endpoint: 'processProject',
    })

    const [processProjectsLoaded, setProcessProjectsLoaded] = useState(false)
    const [documentFormObject, setDocumentFormObject] = useState(documentFormObjectIn)
    const [processProjectArray, setProcessProjectArray] = useState([])
    const [openConfirmDeleteContact, setOpenConfirmDeleteContact] = useState(false)
    const [selectedProcessProjectIndex, setSelectedProcessProjectIndex] = useState(null)

    useEffect(() => {
        if (processProjectServer) {
            setProcessProjectArray(prev => {
                return processProjectServer.map((processProject, i) => {
                    if (processProject.id === documentFormObject.processId) {
                        setSelectedProcessProjectIndex(i)
                    }
                    return {
                        label: processProject.name,
                        value: i,
                        data: processProject
                    }
                })
            })
            setProcessProjectsLoaded(true)
        }
    }, [processProjectServer, documentFormObject])


    useEffect(() => {
        if (documentFormObjectIn) {
            setDocumentFormObject(documentFormObjectIn)
        }
    }, [documentFormObjectIn])

    const handleUpdateDialogEditDocument = () => {
        updateDocumentOut(documentFormObject.id, documentFormObject)
    }

    const handleDeleteDialogEditDocument = () => {
        setOpenConfirmDeleteContact(true)
    }

    const handleCloseDialogEditDocument = (event) => {
        closeDialogOut()
    }

    const handleChangeEditDocumentForm = (event, key) => {
        setDocumentFormObject(prev => {
            return {...prev, [key]: event.target.value}
        })
    }

    const handleCancelDeleteAlert = () => {
        setOpenConfirmDeleteContact(false)
    }

    const handleConfirmDeleteAlert = () => {
        deleteDocumentOut(documentFormObject.id)
        setOpenConfirmDeleteContact(false)
    }

    const handleSelectProcess = (index) => {
        const selectedObject = processProjectArray[index].data
        setDocumentFormObject(prev => {
            return {
                ...prev,
                processId: selectedObject.id,
                process: selectedObject.name,
                processType: selectedObject.processType,
                project: selectedObject.project.name,
            }
        })
    }

    return (
        <>
            <Dialog open={showDialogIn} onClose={() => {}} aria-labelledby="form-dialog-title">
                <CenteredDialogTitleWrapper id="form-dialog-title" >EDIT DOCUMENT</CenteredDialogTitleWrapper>
                <LoaderWrapper
                    isLoaded={processProjectsLoaded}
                >
                    <DialogContent>
                        <DialogContentWrapperColumn>
                            <StyledP>Name</StyledP>
                            <TextField
                                className={classesIn.root}
                                inputProps={{ 'aria-label': 'naked' }}
                                placeholder={'Required'}
                                value={documentFormObject.name}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'name')}
                            />
                            <StyledPGrey>Project</StyledPGrey>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={documentFormObject.project}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'project')}
                                disabled
                            />
                            <StyledP>Process</StyledP>
                            <SimpleDropdown
                                inputArray={processProjectArray}
                                initSelection={selectedProcessProjectIndex}
                                onSelect={handleSelectProcess}
                                isLoaded={true}
                            />
                            <StyledPGrey>Process Type</StyledPGrey>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={documentFormObject.processType}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'processType')}
                                disabled
                            />
                            <StyledP>Description</StyledP>
                            <StyledTextArea
                                value={documentFormObject.description}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'description')}
                            />

                        </DialogContentWrapperColumn>
                    </DialogContent>
                    <CenteredDialogButtonWrapper>
                        <Button onClick={handleCloseDialogEditDocument} color="primary">
                            CLOSE
                        </Button>

                        <>
                            <Button onClick={handleDeleteDialogEditDocument} color="primary">
                                DELETE
                            </Button>
                            <Button onClick={handleUpdateDialogEditDocument} color="primary">
                                UPDATE
                            </Button>
                        </>


                    </CenteredDialogButtonWrapper>
                </LoaderWrapper>
            </Dialog>
            <Dialog
                open={openConfirmDeleteContact}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Document?"}</DialogTitle>
                <AlertButtonsWrapper>
                    <Button onClick={handleCancelDeleteAlert} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteAlert} color="primary" autoFocus>
                        Confirm
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </>
    )

};

export default EditDocumentForm;