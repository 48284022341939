import React from 'react';
import styled from "styled-components";
import ButtonWrapper from "./ButtonWrapper";

const WrapperDiv = styled.div`
  //height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: default;
  //background-color: #eaeaea;
  border: 1px #bebebe solid;
  color: #5A5A5A;
  padding: 5px 10px;

  :hover {
    background-color: #e5e5e5;
    border: 1px #cfcfcf solid;

  }
  .MuiSvgIcon-root {
      width: 26px;
    height: 26px;
  }
  :hover {
    background-color: #e5e5e5;
    border: 1px #cfcfcf solid;
    color: #5a5a5a;
  }

`

const SVGButton = ({
        className = '',
        SVGIconIn,
        handleClickOut = () => {}
    }) => {

    const clickHandler = () => {
        handleClickOut()
    };


    return (
        <ButtonWrapper
            elementName={'SVGButton'}
            clickHandler={clickHandler}
        >
            <WrapperDiv className={`BannerSVGButton ${className}`}>
                <SVGIconIn/>
            </WrapperDiv>
        </ButtonWrapper>
    )

}

export default SVGButton;