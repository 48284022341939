import React from "react";
import styled from "styled-components";

import colors from "../../../library/objects/theme/definitions/colors";
import SideNavLinks from "./SideNavLinks";

const BodyDiv = styled.div`
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 0 0;
  background-color: ${colors.sideBar[1].frames[1].sideBarBackground[0]};
`;

const SideBar = (props) => {

    return (
        <BodyDiv>
            <SideNavLinks/>
        </BodyDiv>
)
}


export default SideBar