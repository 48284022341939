export default {

    sideBar: [
        'Sidebar',
        {
            buttons: [
                'Buttons',
                {
                    navButtonOver: ['#BCBCBC'],
                    navButtonDown: ['#AAAAAA'],
                    navButtonActive: ['#D8D8D8'],
                }
            ],
            text: [
                'Text',
                {
                    navHeaderUp: ['#E8E8E8'],
                    navHeaderOver: ['#565656'],
                    navHeaderDown: ['#666666'],
                    navHeaderActive: ['#565656'],
                    navLinksUp: ['#D6D6D6'],
                    navLinksOver: ['#565656'],
                    navLinksDown: ['#666666'],
                    navLinksActive: ['#565656'],
                }
            ],
            frames: [
                'Frames',
                {
                    sideBarBackground: ['#3D3D3D'],
                    divider: ['#565656']
                }
            ]

        }
    ],
    mainPane: [
        'Main Pane',
        {
            buttons: [
                'Buttons',
                {
                    bannerButtonUp: '',
                    bannerButtonOver: '',
                    bannerButtonDown: '',
                    bannerButtonActive: '',
                    widgetButtonUp: ['#7A7A7A'],
                    widgetButtonOver: ['#575757'],
                    widgetButtonDown: [''],
                    widgetButtonActive: [''],
                    contentListItemUp: [''],
                    contentListItemOver: ['#a1a1a1'],
                    contentListItemDown: [''],
                    contentListItemSelected: ['#575757'],
                    contentListItemHighlighted: ['#ececec'],
                    contentListItemDisabled: ['#f0f0f0'],
                }
            ],
            text: [
                'Text',
                {
                    bannerHeader: '',
                    bannerButtonUp: '',
                    bannerButtonOver: '',
                    bannerButtonDown: '',
                    bannerButtonActive: '',
                    shadowBoxHeader: [''],
                    contentListItemUp: ['#606060'],
                    contentListItemOver: ['#FFFFFF'],
                    contentListItemDown: [''],
                    contentListItemSelected: ['#FFFFFF'],
                    contentPlaceholderText: ['#606060'],
                    contentKeyText: ['#606060'],
                    contentText: ['#606060'],
                }
            ],
            frames: [
                'Frames',
                {
                    topBannerBackground: '',
                    bottomBannerBackground: '',
                    contentBackground: ['#EFEFEF'],
                    filterBarBackground: '',
                    shadowBoxBackground: '',
                    scrollThumb: ['#7A7A7A'],
                    scrollTrack: ['#f0f0f0']
                }
            ],
        }
    ]


};