import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import useRESTCRUD from "../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../../templates/wrappers/LoaderWrapper";
import SimpleDropdown from "../../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import {
    mapProcessServerToProcessObject,
    mapTaskGroupProcessServerToTaskGroupProcessObject
} from "../../../../library/helpers/functions/dataMaps";

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const CenteredDialogButtonWrapper = styled.div`
  width: 100%;
  padding: 10px;
display: flex;
  flex-direction: row;
  justify-content: center;
  .MuiDialogActions-root {
    width: 100%;
    justify-content: center;
  }

`


const DialogContentWrapperColumn = styled.div`
  width: 500px;
  height: 100%;
  padding: 10px 30px;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .MuiFormControl-root {
    width: 100%;
   
  }



`

const StyledP = styled.p`
  margin: 20px 0 0 0;
  font-weight: bold;
`


const StyledPGrey = styled.p`
  margin: 20px 0 0 0;
  font-weight: bold;
  color: #888888;
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const taskFormObjectInit = {
    project: '',
    headerString: '',
    processType: '',
    manager: '',
    managerId: '',
    date: '',
    processId: ''

}


const EditTaskForm = (props) => {

    const {
        classesIn = {base: {}},
        taskFormObjectIn = taskFormObjectInit,
        showDialogIn,
        addTaskOut = (taskObj) => {},
        updateTaskOut = (uuid, taskObj) => {},
        deleteTaskOut = () => {},
        closeDialogOut = () => {},
        addMode = false
    } = props

    const [processServer, setState, isFetching] = useRESTCRUD({
        endpoint: 'processProject',
    })

    const contactsRequest = {
        endpoint: 'contact',
    }

    const [contactsServer, hydrateContactsServer, setContactsServer, isContactsLoading] = useRESTCRUD(contactsRequest)

    const [processProjectsLoaded, setProcessProjectsLoaded] = useState(false)
    const [taskFormObject, setTaskFormObject] = useState(taskFormObjectIn)
    const [processProjectArray, setProcessProjectArray] = useState([])
    const [contactArray, setContactArray] = useState([])
    const [openConfirmDeleteContact, setOpenConfirmDeleteContact] = useState(false)
    const [selectedProcessProjectIndex, setSelectedProcessProjectIndex] = useState('')
    const [selectedContactIndex, setSelectedContactIndex] = useState('')
    const [hasSetProcess, setHasSetProcess] = useState(false)
    const [hasSetName, setHasSetName] = useState(false)
    const [confirmDisabled, setConfirmDisabled] = useState(true)

    useEffect(() => {
        if (hasSetName && hasSetProcess) {
            setConfirmDisabled(false)
        } else {
            setConfirmDisabled(true)
        }
    }, [hasSetName, hasSetProcess])

    useEffect(() => {
        if (processServer) {
            setProcessProjectArray(prev => {
                return processServer.map((processProject, i) => {
                    if (processProject.id === taskFormObject.processId) {
                        setSelectedProcessProjectIndex(i)
                    }
                    return {
                        label: processProject.name,
                        data: processProject,
                        value: i
                    }
                })
            })
            setProcessProjectsLoaded(true)
        }
    }, [processServer, taskFormObject])


    useEffect(() => {
        if (taskFormObjectIn) {
            setTaskFormObject(taskFormObjectIn)
        }
    }, [taskFormObjectIn])

    useEffect(() => {
        if (contactsServer) {
            const mappedContacts = contactsServer.map((contact, i) => {
                if (contact.details.given_names) {
                    if (contact.id === taskFormObject.managerId) {
                        setSelectedContactIndex(i)
                    }
                    const givenNames = contact.details.given_names.join(' ')
                    return {
                        label: `${givenNames} ${contact.details.surname}`,
                        data: contact,
                        value: i,
                    }
                } else {
                    return {
                        label: undefined,
                        value: undefined,
                    }
                }
            })
            setContactArray(mappedContacts)
        }

    }, [contactsServer])

    const cleanup = () => {
        if (addMode) {
            setTaskFormObject(taskFormObjectIn)
            setSelectedProcessProjectIndex('')
            setSelectedContactIndex('')
        }
    }

    const handleAddDialogDocument = () => {
        addTaskOut(taskFormObject)
        closeDialogOut()
        cleanup()
    }

    const handleUpdateDialogEditDocument = () => {
        updateTaskOut(taskFormObject.id, taskFormObject)
    }

    const handleDeleteDialogEditDocument = () => {
        setOpenConfirmDeleteContact(true)
        cleanup()
    }

    const handleCloseDialogEditDocument = (event) => {
        closeDialogOut()
        cleanup()
    }

    const handleChangeEditDocumentForm = (event, key) => {

        setTaskFormObject(prev => {
            return {...prev, [key]: event.target.value}
        })
        if (event.target.value === "") {
            setHasSetName(false)
        } else {
            setHasSetName(true)
        }
    }

    const handleCancelDeleteAlert = () => {
        setOpenConfirmDeleteContact(false)
    }

    const handleConfirmDeleteAlert = () => {
        deleteTaskOut(taskFormObject.id)
        setOpenConfirmDeleteContact(false)
    }

    const handleSelectProcess = (index) => {
        const selectedObject = processProjectArray[index].data
        setTaskFormObject(prev => {
            return {
                ...prev,
                processId: selectedObject.id,
                process: selectedObject.name,
                processType: selectedObject.process_type.name,
                project: selectedObject.project.name,
            }
        })
        setHasSetProcess(true)
    }

    const handleSelectContact = (index) => {
        setSelectedContactIndex(index)
        setTaskFormObject(prev => {
            return {
                ...prev,
                manager: `${contactArray[index].data.details.given_names.join(' ')} ${contactArray[index].data.details.surname}`,
                managerId: contactArray[index].data.id
            }
        })
    }

    const handleDateChange = (event) => {
        setTaskFormObject(prev => {
            return {...prev, date: event.target.value}
        })
    }

    return (
        <>
            <Dialog open={showDialogIn} onClose={() => {}} aria-labelledby="form-dialog-title">
                <CenteredDialogTitleWrapper id="form-dialog-title" >{addMode ? 'ADD NEW': 'EDIT'} TASK</CenteredDialogTitleWrapper>
                <LoaderWrapper
                    isLoaded={processProjectsLoaded}
                >
                    <DialogContent>
                        <DialogContentWrapperColumn>
                            {addMode &&
                                <>
                                    <StyledP>Task</StyledP>
                                    <TextField
                                    inputProps={{ 'aria-label': 'naked' }}
                                    value={taskFormObject.headerString}
                                    onChange={(event) => handleChangeEditDocumentForm(event, 'headerString')}

                                    />
                                </>
                            }
                            <StyledPGrey>Project</StyledPGrey>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={taskFormObject.project}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'project')}
                                disabled
                            />
                            <StyledP>Process</StyledP>
                            <SimpleDropdown
                                inputArray={processProjectArray}
                                initSelection={selectedProcessProjectIndex}
                                onSelect={handleSelectProcess}
                                isLoaded={true}
                                showAll={true}
                            />
                            <StyledPGrey>Process Type</StyledPGrey>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={taskFormObject.processType}
                                onChange={(event) => handleChangeEditDocumentForm(event, 'processType')}
                                disabled
                            />
                            {/*<StyledP>Task Manager</StyledP>*/}
                            {/*<SimpleDropdown*/}
                            {/*    inputArray={contactArray}*/}
                            {/*    initSelection={selectedContactIndex}*/}
                            {/*    onSelect={handleSelectContact}*/}
                            {/*    showAll={true}*/}
                            {/*    isLoaded={true}*/}
                            {/*/>*/}
                            <StyledP>Date Due</StyledP>
                            <input
                                type={'date'}
                                value={taskFormObject.date}
                                onChange={handleDateChange}
                            />
                        </DialogContentWrapperColumn>
                    </DialogContent>
                    <CenteredDialogButtonWrapper>
                        <Button onClick={handleCloseDialogEditDocument} color="primary">
                            CLOSE
                        </Button>
                        {addMode ?
                            <>
                                <Button onClick={handleAddDialogDocument} color="primary" disabled={confirmDisabled}>
                                    ADD
                                </Button>
                            </>
                            :
                            <>
                                <Button onClick={handleDeleteDialogEditDocument} color="primary">
                                    DELETE
                                </Button>
                                <Button onClick={handleUpdateDialogEditDocument} color="primary">
                                    UPDATE
                                </Button>
                            </>
                        }



                    </CenteredDialogButtonWrapper>
                </LoaderWrapper>
            </Dialog>
            <Dialog
                open={openConfirmDeleteContact}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Document?"}</DialogTitle>
                <AlertButtonsWrapper>
                    <Button onClick={handleCancelDeleteAlert} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteAlert} color="primary" autoFocus>
                        Confirm
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </>
    )

};

export default EditTaskForm;