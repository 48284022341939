import React from "react";
import styled from "styled-components";

const LoaderWrapperDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 25px;
`

const LoaderWrapper = (props) => {

    if (props.isLoaded) {
        return props.children
    } else {
        return (
            <LoaderWrapperDiv>
                <p>loading...</p>
            </LoaderWrapperDiv>
        )
    }

}

export default LoaderWrapper