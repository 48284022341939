import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Draggable} from "react-beautiful-dnd";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import OpenWithIcon from '@material-ui/icons/OpenWith';

const BodyWrapper = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgrey;
  margin-bottom: 8px;
  background-color: ${props => props.isDragging ? 'lightblue' : 'white'};
  padding: 0 22px;
`

const TaskItemHeadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const StyledP = styled.p`
  font-size: 22px;
`
const StyledH5 = styled.h5`
  font-size: 24px;
`


const LabelWrapper = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 22px;
  //padding: 5px;

`

const GrabbyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 65px;
position: relative;
`

const GrabbyDiv = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #808080;
  padding: 0;
  //transform: none !important;
  .MuiSvgIcon-root{
    height: 30px;
    width: 30px;
  }
  border-radius: 5px;
  position: absolute;
  opacity: ${props => props.activeTask ? .8 : 0};
`

const CheckBoxWrapper = styled.div`
  position: relative;
  top:2px;
  .MuiSvgIcon-root{
    height: 30px;
    width: 30px;
  }
`

const FakeGrabbyDiv = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #808080;
  padding: 0;
  .MuiSvgIcon-root{
    height: 30px;
    width: 30px;
  }
  border-radius: 5px;
  position: absolute;
  
`



const MoveIcon = styled(OpenWithIcon)`
  color: white;


`

const TaskItem = (props) => {

    const [activeTask, setActiveTask] = useState(false)

    useEffect(() => {
        if (props.isDragging === props.task.id) {
            setActiveTask(true)
        } else {
            setActiveTask(false)
        }
    }, [props.isDragging, props.task.id])

    return (
        <BodyWrapper>
            <TaskItemHeadWrapper>
                <CheckBoxWrapper>
                    {
                        props.task.isCheckedBool ?
                            <CheckCircleOutlineIcon/>
                        :
                            <RadioButtonUncheckedIcon/>
                    }
                </CheckBoxWrapper>
                <LabelWrapper>
                    <StyledH5>
                        {props.task.taskString}
                    </StyledH5>
                    <StyledP>
                        {props.task.taskGroup ? props.task.taskGroup : '<Unassigned>'}
                    </StyledP>
                </LabelWrapper>

            </TaskItemHeadWrapper>
            <GrabbyWrapper>
                <FakeGrabbyDiv>
                    <MoveIcon/>
                </FakeGrabbyDiv>
                <Draggable
                    draggableId={props.task.id}
                    index={props.index}
                >
                    {(provided, snapshot) => {
                        return (

                                <GrabbyDiv
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    isDragging={snapshot.isDragging}
                                    activeTask={activeTask}
                                >
                                    <MoveIcon/>
                                </GrabbyDiv>
                        )
                    }}
                </Draggable>
            </GrabbyWrapper>
        </BodyWrapper>
    )

};

export default TaskItem;