import React, {useEffect, useState, useMemo, useCallback} from 'react';
import styled from "styled-components";
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import {Button, Checkbox, Dialog, DialogContent, DialogTitle} from "@material-ui/core";
// import PdfViewer from "../PdfViewer/PdfViewer";
import {usePDF, PDFViewer, StyleSheet, Document, Page, View, Text} from '@react-pdf/renderer';

import colors from "../../../../library/objects/theme/definitions/colors";
import ListFilterDropdownSet from "../../dropdowns/ListFilterDropdownSet/ListFilterDropdownSet";
// import PdfCompiler from "../PdfCompiler/PdfCompiler";
import PdfViewerSimple from "../PdfViewerSimple/PdfViewerSimple";
import printJS from "print-js";
import EmailFileBlob from "../../email/EmailFileBlob/EmailFileBlob";
import DraggableList from "../../lists/DraggableList/DraggableList";


const BodyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`


const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
  //height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;

`
const DialogInnerWrapper = styled.div`
  width: 1600px;
  height: 800px;
  //padding: 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`

const PdfViewerWrapper = styled.div`
  width: 50%;
  height: 100%;
  background-color: ${colors.mainPane[1].frames[1].contentBackground[0]};
`

const ContentFormOuterWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const FilterListOuterWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;  


`

const FilterListInnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  border-bottom: solid 1px #B7B7B7;

`

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 10px;
  border-bottom: solid 1px #b7b7b7;

`

const ListWrapper = styled.div`
  height: 100%;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
`

const ListItemWrapper = styled.div`
  //height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const CenteredDialogButtonWrapper = styled.div`
  width: 100%;
  //height: 60px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-grow: 0;
`

const filterFieldList = [
    {field: 'name', label: 'Display Name #1', filter: '', type: 'string'},
    {field: 'job', label: 'Display Name #2'} //optional filterString
]

const dummyList = [
    {id: 'abc123xxx', name: 'Some Name 1', checked: true, job: 'Job 1'},
    {id: 'abc234xxx', name: 'Some Name 2', checked: false, job: 'Job 2'},
    {id: 'abc345xxx', name: 'Some Name 3', checked: true, job: 'Job 3'},
]

const DefaultListDiv = (displayObject) => {
    return (
        <>
            <p>{displayObject.id}</p>
        </>
    )
}

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
})

const DefaultPdfListElement = ({pdfListObjectIn, listIndexIn = null}) => {
    return (
        <View>
            <Text>default item</Text>
        </View>
    )
}

const DefaultPdfFrame = ({children}) => {
    return (
        <Document>
            <Page>
                {children}
            </Page>
        </Document>
    )
}


const PdfGeneratorList = ({
                              showDialog = false,
                              setShowDialog = () => {},
                              listArrayIn = dummyList,
                              DisplayListElementIn= DefaultListDiv,
                              PdfFrameIn = DefaultPdfFrame,
                              PdfListElementIn = DefaultPdfListElement,
                              fieldFiltersIn = filterFieldList,
                              fetchContactsOut = () => {},
                              sendEmailOut = () => {}
                          }) => {

    const [showEmailDialog, setShowEmailDialog] = useState(false)
    const [baseListMap, setBaseListMap] = useState({})
    const [displayListArray, setDisplayListArray] = useState([])
    const [pdfList, setPdfList] = useState([])

    const MyDoc = useMemo(() => (
        <PdfFrameIn>
            {displayListArray.map((pdfItem, i) => {
                if (pdfItem.checked) {
                    return <PdfListElementIn key={`pdfItem_${i}`} pdfListObjectIn={pdfItem.data}/>
                } else {
                    return <></>
                }

            })}
        </PdfFrameIn>
    ), [displayListArray])

    const [instance, updateInstance] = usePDF({document: MyDoc})

    useEffect(() => {
        updateInstance()
    }, [updateInstance, displayListArray])

    useEffect(() => {
        setBaseListMap(prev => {
            const returnMap = {}
            listArrayIn.forEach((listObject, i) => {
                returnMap[listObject.id] = {
                    id: listObject.id,
                    index: i,
                    checked: true,
                    display: true,
                    data: listObject,
                }
            })
            return returnMap
        })
    }, [listArrayIn])

    useEffect(() => {
        const returnDisplayListArray = []
        Object.keys(baseListMap).forEach((baseObjectKey) => {
            if (baseListMap[baseObjectKey].display) {
                returnDisplayListArray[baseListMap[baseObjectKey].index] = baseListMap[baseObjectKey]
            }
        })
        setDisplayListArray(returnDisplayListArray)
    }, [baseListMap])

    const handleClickCloseDialog = () => {
        setShowDialog(false)
    }

    const handleClickSaveDialog = () => {
        const link = document.createElement('a')
        link.target = '_blank'
        link.href = instance.url
        link.click()
    }

    const handleClickPrintDialog = () => {
        printJS(instance.url)
    }

    const handleClickSendDialog = () => {
        setShowEmailDialog(true)
    }

    const handleFilterSelection = useCallback((filteredListReturn) => {
            setBaseListMap(prev => {
                Object.keys(prev).forEach((baseItemKey) => {
                    let hasMatchedItems = false
                    filteredListReturn.forEach((filteredItem) => {
                        if (baseItemKey === filteredItem.id) {
                            hasMatchedItems = true
                        }
                    })
                    if (hasMatchedItems) {
                        prev[baseItemKey] = {...prev[baseItemKey], display: true}
                    } else {
                        prev[baseItemKey] = {...prev[baseItemKey], display: false}
                    }
                })
                return {...prev}
            })
        },
        [],
    );

    const handleListItemCheck = (event, instanceUUID) => {
        setBaseListMap(prev => {
            return {
                ...prev,
                [instanceUUID]: {
                    ...prev[instanceUUID],
                    checked: !prev[instanceUUID].checked,
                }
            }
        })
    }

    const handleDragOut = (newMap) => {
        setBaseListMap(prev => {
            Object.keys(newMap).forEach((newMapKey) => {
                prev[newMapKey] = {
                    ...prev[newMapKey],
                    index: newMap[newMapKey].orderedIndex
                }
            })
            return {...prev}
        })
    }

    const ListItemComponent = ({listItem}) => {
        return (
            <ListItemWrapper
                key={listItem.data.id}
            >
                <Checkbox
                    className={'Checkbox'}
                    checked={listItem.checked}
                    onChange={(event) => handleListItemCheck(event, listItem.data.id)}
                    inputProps={{'aria-label': 'primary checkbox'}}
                    color={'primary'}
                />
                {DisplayListElementIn(listItem.data)}
            </ListItemWrapper>
        )
    }

    return (
        <BodyWrapper>
            <Dialog open={showDialog} aria-labelledby="form-dialog-title" maxWidth="xl">
                <CenteredDialogTitleWrapper id="form-dialog-title" >EXPORT PAGE</CenteredDialogTitleWrapper>
                <DialogInnerWrapper>
                    <PdfViewerWrapper>

                        {
                            instance.loading ? <div>Loading ...</div> : (
                                <PdfViewerSimple
                                    displayUrl={instance.blob}
                                />
                            )
                        }


                    </PdfViewerWrapper>
                    <ContentFormOuterWrapper>
                        <FilterListOuterWrapper>
                            <FilterListInnerWrapper>
                                <FilterWrapper>
                                    <ListFilterDropdownSet
                                        listArrayIn={listArrayIn}
                                        fieldFiltersArrayIn={fieldFiltersIn}
                                        handleChangeOut={handleFilterSelection}
                                    />
                                </FilterWrapper>
                                <ListWrapper>
                                    <DraggableList
                                        listArrayIn={displayListArray}
                                        ListItemComponent={ListItemComponent}
                                        // handleDragOutList={handleDragOut}
                                        handleDragOutMap={handleDragOut}
                                    />
                                    {/*{ListItems}*/}
                                </ListWrapper>
                            </FilterListInnerWrapper>
                        </FilterListOuterWrapper>
                        <CenteredDialogButtonWrapper>
                            <Button onClick={handleClickSaveDialog} color="primary">
                                SAVE
                            </Button>
                            <Button onClick={handleClickSendDialog} color="primary">
                                SEND
                            </Button>
                            <Button onClick={handleClickPrintDialog} color="primary">
                                PRINT
                            </Button>
                            <Button onClick={handleClickCloseDialog} color="primary">
                                CLOSE
                            </Button>
                        </CenteredDialogButtonWrapper>
                    </ContentFormOuterWrapper>
                </DialogInnerWrapper>
            </Dialog>
            <EmailFileBlob
                blobFileIn={instance.blob}
                showDialogIn={showEmailDialog}
                setShowDialogOut={setShowEmailDialog}
                fetchContactsOut={fetchContactsOut}
                sendEmailOut={sendEmailOut}
            />
        </BodyWrapper>
    )

};

export default React.memo(PdfGeneratorList);