import React, {useEffect, useState, useRef} from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    Button,
    Dialog,
    DialogTitle,
    makeStyles,
    Checkbox,
} from "@material-ui/core";
import styled from "styled-components";
import RevealColumnWrapper from "../../wrappers/RevealColumnWrapper";
import SimpleCheckbox from "../SimpleCheckbox/SimpleCheckbox";

const useStyles = makeStyles({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
    checkbox: {
        color: '#13c552',
        backgroundColor: '#e3a913',
        fill: '#131cc5',
        colorPrimary: '#131cc5',
        "&$checked": {
            color: "#9613c5",
            fill: '#131cc5',
            backgroundColor: '#07d9a8',
        }
    },
    checked: {
        color: "#9613c5",
        fill: '#131cc5',
        backgroundColor: '#07d9a8',
    }
});

const StyledCheckbox = styled(Checkbox)`
  color: #c54513;
  background-color: #9013c5;
  fill: #133fc5;

  &:checked {
    color: #13c552;
    background-color: #d6a833;
    fill: #c51351;
  }

  svg {
    //color: #13c552;
    //background-color: #13c552;
    //fill: #13c552;
  }

  .MuiTouchRipple-root {
    color: #131cc5;
    fill: #131cc5;

    &hover {
      background-color: #131cc5;
      //opacity: 0.3;
    }

    //background-color: #ffffff;
  }
`

const BodyWrapper = styled.div`
  width: 100%;
  //height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`

const HeaderWrapper = styled.div`
  width: 100%;
    display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  
`

const HeaderGroupWrapper = styled.div`
  width: 100%;
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
`

const HeaderCheckInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HeaderTitleInput = styled.input`
  width: 100%;
    background: none;
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 2px;
`

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 0 18px 0 50px;
`

const TodoItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const DeleteIcon = styled(DeleteForeverIcon)`
  position: relative;
  top: 3px;
  opacity: 0.5;
  :hover {
    opacity: 0.8;
  }
`

const AddNewTaskWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  
  ${props => props.displayButton && 'display: none;'}
  opacity: 0.7;

  :hover {
    opacity: 1;
  }

  p {
    width: 100%;
    text-align: center;
    cursor: default;
    font-weight: bold;
    color: #414141
  }

`

const NewTaskInputWrapper = styled.div`
  padding: 10px 0 10px 42px;
  input {
    ${props => props.displayInput && 'display: none;'}
    background: none;
    border: none;
    outline: none;
  }
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

// const StyledCheckbox = styled(Checkbox)({
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     color: 'white',
// });


const defaultCheckboxArray = {
    headerString: 'Header',
    checkboxArray: [
        {taskString: 'this is a task', isCheckedBool: false},
        {taskString: 'this is a task', isCheckedBool: false},
        {taskString: 'this is a task', isCheckedBool: false},
    ]
}

const GroupCheckbox = (props) => {

    const classes = useStyles();

    const {
        instanceIndex = 0,
        groupObject = defaultCheckboxArray,
        expandedBool = false,
        onToggleExpandedOut = (isExpanded, instanceIndex) => {},
        onSetGroupOut = (value, instanceIndex) => {},
        onDeleteGroupOut = (instanceIndex) => {},
    } = props


    const [checkboxes, setCheckboxes] = useState(groupObject.checkboxArray)
    const [headerChecked, setHeaderChecked] = useState(false)
    const [headerIndeterminate, setHeaderIndeterminate] = useState(false)
    const [expanded, setExpanded] = useState(expandedBool);
    const [headerValue, setHeaderValue] = useState(groupObject.headerString)
    const [hideNewTaskButton, setHideNewTaskButton] = useState(false)
    const [hideNewTaskInput, setHideNewTaskInput] = useState(true)
    const [newInputValue, setNewInputValue] = useState('')
    const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
    const newItemRef = useRef(null)
    const isUpdated = useRef(true)

    useEffect(() => {
        let checkedBoxes = 0
        checkboxes.forEach((checkbox, i) => {
            if (checkbox.isCheckedBool) {
                checkedBoxes++
            }
        })
        let headCheck = false
        if (checkedBoxes === 0) {
            headCheck = false
            setHeaderIndeterminate(false)

        } else if (checkedBoxes < checkboxes.length) {
            headCheck = false
            setHeaderIndeterminate(true)
        } else if (checkedBoxes === checkboxes.length) {
            headCheck = true
            setHeaderIndeterminate(false)
        }
        if (headCheck !== headerChecked) {
            setHeaderChecked(headCheck)
            onSetGroupOut({isCheckedBool: headCheck, headerString: headerValue, checkboxArray: checkboxes}, instanceIndex)

        }



    }, [checkboxes])

    useEffect(() => {
        if (!hideNewTaskInput) {
            newItemRef.current.focus()
        }
    }, [hideNewTaskInput])

    useEffect(() => {
        setCheckboxes(groupObject.checkboxArray)
        setHeaderValue(groupObject.headerString)
        setHeaderChecked(groupObject.isCheckedBool)
    }, [groupObject])

    useEffect(() => {
        setExpanded(expandedBool)
    }, [expandedBool])

    const handleCheckGroup = async (event, index) => {
        if (checkboxes.length === 0) {
            await setHeaderChecked(prev => {
                return !prev
            })
            onSetGroupOut({isCheckedBool: !headerChecked, headerString: headerValue, checkboxArray: checkboxes}, instanceIndex)

        } else {
            setExpanded(true)
        }
    };

    const handleToggleExpanded = () => {
        setExpanded(prev => {
            onToggleExpandedOut(!prev)
            return !prev
        })
    }

    const handleClickHeaderGroup = () => {
        // if (checkboxes.length === 0) {
        //     setHeaderChecked(prev => !prev)
        // } else {
        //     setExpanded(false)
        // }
    }

    const handleChangeHeader = (event) => {
        isUpdated.current = false
        setHeaderValue(event.target.value)
    }

    const handleUpdateHeaderBlur = (event) => {
        if (!isUpdated.current) {
            onSetGroupOut({isCheckedBool: headerChecked, headerString: headerValue, checkboxArray: checkboxes}, instanceIndex)
            isUpdated.current = true
        }
    }

    const handleUpdateHeaderEnter = (event) => {
        if (!isUpdated.current) {
            if (event.keyCode === 13) {
                event.preventDefault()
                onSetGroupOut({isCheckedBool: headerChecked, headerString: headerValue, checkboxArray: checkboxes}, instanceIndex)
                isUpdated.current = true
            }
        }
    }

    const handleDeleteGroup = () => {
        setDeleteAlertOpen(true)
    }

    const handleCloseDeleteAlert = () => {
        setDeleteAlertOpen(false)
    }

    const handleCancelDeleteAlert = () => {
        setDeleteAlertOpen(false)
    }

    const handleConfirmDeleteAlert = () => {
        setDeleteAlertOpen(false)
        onDeleteGroupOut(instanceIndex)
    }

    const handleSetItem = async (checkboxObject, index) => {
        const updatedItem = checkboxes
        updatedItem[index] = checkboxObject
        onSetGroupOut({isCheckedBool: headerChecked, headerString: headerValue, checkboxArray: updatedItem}, instanceIndex)
        await setCheckboxes(prev => {
            prev[index] = checkboxObject
            return [...prev]
        })
    }

    const handleClickAddItem = () => {
        if (!hideNewTaskButton) {
            setHideNewTaskButton(true)
            setHideNewTaskInput(false)
        }
    }

    const handleChangeNewItem = (event) => {
        setNewInputValue(event.target.value)
    }

    const addItem = (newTask) => {
        setHideNewTaskButton(false)
        setHideNewTaskInput(true)
        if (newTask.trim()) {
            const updatedItems = [...checkboxes, {taskString: newTask.trim(), isCheckedBool: false}]
            onSetGroupOut({headerString: headerValue, checkboxArray: updatedItems, isCheckedBool: headerChecked}, instanceIndex)
            setCheckboxes(prev => {
                return [...prev, {taskString: newTask.trim(), isCheckedBool: false}]
            })
        }
        setNewInputValue('')
    }

    const handleAddItemBlur = (event) => {
        addItem(event.target.value)
    }

    const handleAddItemEnter = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            addItem(event.target.value)
        }

    }

    const handleDeleteItem = (uuid) => {
        const updatedCheckBoxes = [...checkboxes]
        const index = updatedCheckBoxes.map(e => e.id).indexOf(uuid)
        updatedCheckBoxes.splice(index, 1)
        setCheckboxes(updatedCheckBoxes)
        onSetGroupOut({headerString: headerValue, checkboxArray: updatedCheckBoxes, isCheckedBool: headerChecked}, instanceIndex)
    }

    const CheckboxSubItems = checkboxes.map((checkboxItem, i) => {
        return (
            <TodoItemWrapper
                key={`${checkboxItem.taskString}_${i}`}
                className={'TodoItemWrapper'}
            >
                <SimpleCheckbox
                    inputItemObject={checkboxItem}
                    onSetItemOut={handleSetItem}
                    onDeleteItemOut={handleDeleteItem}
                    instanceIndex={i}
                />
            </TodoItemWrapper>
        )
    })

    return (
        <BodyWrapper>
            <HeaderWrapper
                className={'HeaderWrapper'}
            >
                {expanded ? <ExpandMoreIcon onClick={handleToggleExpanded}/> : <ChevronRightIcon onClick={handleToggleExpanded}/>}
                <HeaderGroupWrapper
                    className={'HeaderGroupWrapper'}
                    onClick={handleClickHeaderGroup}
                >
                    <HeaderCheckInputWrapper
                        className={'HeaderCheckInputWrapper'}
                    >
                        <Checkbox
                            // className={{...classes.checkbox, checked: classes.checked}}
                            checked={headerChecked}
                            indeterminate={headerIndeterminate}
                            onChange={handleCheckGroup}
                            color={'primary'}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <HeaderTitleInput
                            value={headerValue}
                            onChange={handleChangeHeader}
                            onBlur={handleUpdateHeaderBlur}
                            onKeyDown={handleUpdateHeaderEnter}
                        />
                    </HeaderCheckInputWrapper>
                    <DeleteIcon
                        className={'DeleteIcon'}
                        onClick={handleDeleteGroup}
                    />
                </HeaderGroupWrapper>

            </HeaderWrapper>
            <ListWrapper>
                <RevealColumnWrapper
                    expanded={expanded}
                >
                    {CheckboxSubItems}
                    <AddNewTaskWrapper
                        className={'AddNewTaskWrapper'}
                        onClick={handleClickAddItem}
                        displayButton={hideNewTaskButton}
                    >
                        <p>+ add new</p>
                    </AddNewTaskWrapper>
                    <NewTaskInputWrapper
                        displayInput={hideNewTaskInput}
                    >
                        <input
                            ref={newItemRef}
                            onBlur={handleAddItemBlur}
                            onKeyDown={handleAddItemEnter}
                            value={newInputValue}
                            onChange={handleChangeNewItem}
                        />
                    </NewTaskInputWrapper>
                </RevealColumnWrapper>
            </ListWrapper>
            <Dialog
                open={deleteAlertOpen}
                onClose={handleCloseDeleteAlert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Todo Group?"}</DialogTitle>
                <AlertButtonsWrapper>
                    <Button onClick={handleCancelDeleteAlert} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDeleteAlert} color="primary" autoFocus>
                        Confirm
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </BodyWrapper>
    )

}

export default GroupCheckbox