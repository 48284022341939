import React from "react";
import styled from "styled-components";
const WrapperDiv = styled.div`
  ${props => props.padding && `padding: ${props.padding}` };
  height: 100%;
  width: 100%;
`


const BodyDiv = styled.div`
  
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  background-color: ${props => props.backgroundColor};
    display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.03), 0 6px 20px 0 rgba(0, 0, 0, 0.03);
  
`;

const ShadowBox = (props) => {

    return (
        <WrapperDiv>
            <BodyDiv className={'ShadowBox'} backgroundColor={props.backgroundColor ? props.backgroundColor : '#FFFFFF'}>
                {props.children}
            </BodyDiv>
        </WrapperDiv>

    )

}


export default ShadowBox