


const fetchRawAsync = async ({apiBaseUrl, requestUrl, method, headers, body, mode = {}}, loadFunc = null) => {
    try {
        const response = await fetch(`${apiBaseUrl}${requestUrl}`, {method, headers, body});
        return response
    }
    catch (error){
        return error
    }

};

export default fetchRawAsync

