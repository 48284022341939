import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Button, Dialog, DialogContent, DialogTitle, makeStyles, TextField} from "@material-ui/core";
import AddButtonFloating from "../../buttons/AddButtonFloating";
import SortableTableList from "../SortableTableList/SortableTableList";
import SimpleDropdown from "../../dropdowns/SimpleDropdown/SimpleDropdown";
import EditContactForm from "./components/EditContactForm";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    focused: {},
}));

const BodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`

const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  //overflow-y: scroll;
`

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`


const CenteredDialogButtonWrapperChooseDialog = styled.div`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 50px;
  .MuiDialogActions-root {
    //width: 100%;
    justify-content: center;
  }

`


const DialogContentWrapperColumnChooseDialog = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 30px 20px 30px;
display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  .MuiFormControl-root {
    width: 500px;
   
  }
  
`


const DialogContentWrapperRow = styled.div`
  //width: 300px;
  height: 100%;
  //padding: 10px 30px;
display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  //.MuiFormControl-root {
  //  width: 100%;
  //  margin-bottom: 20px;
  //
  //}

`


const contactFormObjectInit = {
    name: '',
    surname: '',
    employer: '',
    position: '',
    phone: '',
    email: '',
    address: '',
    postcode: '',
    suburb: '',
    note: '',
}

const ContactTable = (props) => {

    const {
        headCells = [],
        bodyRows = [],
        fetchContactsOut = () => {},
        createContactOut = (contactObj) => {},
        addContactOut = (contactUUID) => {},
        updateContactOut = (uuid, contactObj) => {},
        deleteContactOut = (contactUUID) => {},

    } = props

    const [isLoadedContacts, setIsLoadedContacts] = useState(false)
    const [contacts, setContacts] = useState([])
    const [showChooseDialog, setShowChooseDialog] = useState(false)
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [contactFormObject, setContactFormObject] = useState(contactFormObjectInit)
    const [selectedAddContact, setSelectedAddContact] = useState(contactFormObjectInit)
    const [isCreateContact, setIsCreateContact] = useState(false)

    const handleClickAddContact = async (event) => {
        const updatedContacts = await fetchContactsOut()
        await setContacts(updatedContacts)
        setIsLoadedContacts(false)
        setShowChooseDialog(true)
        await setIsLoadedContacts(true)
    }

    const handleClickCreateChooseContact = (event) => {
        setContactFormObject(contactFormObjectInit)
        setIsCreateContact(true)
        setShowChooseDialog(false)
        setShowEditDialog(true)
    }

    const handleSelectDialogChooseContact = (index) => {
        setSelectedAddContact(contacts[index].data)

    }

    const handleAddDialogChooseContact = () => {
        addContactOut(selectedAddContact)
        setShowChooseDialog(false)

    }

    const handleCloseDialogChooseContact = (event) => {
        setShowChooseDialog(false)
    }



    const handleTableSelection = (contactObject) => {
        setContactFormObject(contactObject)
        setShowEditDialog(true)
    }


    const handleCreateContact = async (contactFormObject) => {
        createContactOut(contactFormObject)
        await setContactFormObject({...contactFormObjectInit})
        setShowEditDialog(false)
        setIsCreateContact(false)
    }

    const handleUpdateContact = async (uuid, contactObject) => {
        updateContactOut(uuid, contactObject)
        await setContactFormObject({...contactFormObjectInit})
        setShowEditDialog(false)
    }

    const handleDeleteContact = async (uuid) => {
        deleteContactOut(uuid)
        await setContactFormObject({...contactFormObjectInit})
        setShowEditDialog(false)
    }

    // ******************

    const handleCloseDialogEditContact = async (event) => {
        setShowEditDialog(false)
        await setContactFormObject({...contactFormObjectInit})
        setIsCreateContact(false)
    }


    const classes = useStyles();

    return (
        <BodyWrapper>
            <InnerListWrapper>
                <AddButtonFloating
                    onClick={handleClickAddContact}
                />
                <SortableTableList
                    headCells={headCells}
                    bodyRows={bodyRows}
                    onSelect={handleTableSelection}
                />

            </InnerListWrapper>
            <EditContactForm
                classesIn={classes}
                showDialogIn={showEditDialog}
                isAddNewContactIn={isCreateContact}
                contactFormObjectIn={contactFormObject}
                createContactOut={handleCreateContact}
                updateContactOut={handleUpdateContact}
                deleteContactOut={handleDeleteContact}
                closeDialogOut={handleCloseDialogEditContact}
            />
            <Dialog open={showChooseDialog} aria-labelledby="form-dialog-title">
                <CenteredDialogTitleWrapper id="form-dialog-title" >ADD CONTACT</CenteredDialogTitleWrapper>
                <DialogContent>
                    <DialogContentWrapperColumnChooseDialog>
                        <SimpleDropdown
                            classes={classes}
                            title={'Contact'}
                            inputArray={contacts}
                            onSelect={handleSelectDialogChooseContact}
                            isLoaded={isLoadedContacts}
                        />
                    </DialogContentWrapperColumnChooseDialog>
                </DialogContent>
                {isLoadedContacts && (
                <CenteredDialogButtonWrapperChooseDialog>
                    <DialogContentWrapperRow>
                        <Button onClick={handleClickCreateChooseContact} color="primary">
                            CREATE NEW CONTACT
                        </Button>
                    </DialogContentWrapperRow>
                    <DialogContentWrapperRow>
                        <Button onClick={handleCloseDialogChooseContact} color="primary">
                            CLOSE
                        </Button>
                        <Button onClick={handleAddDialogChooseContact} color="primary">
                            ADD
                        </Button>
                    </DialogContentWrapperRow>
                </CenteredDialogButtonWrapperChooseDialog>
                )}
            </Dialog>
        </BodyWrapper>
    )

};

export default ContactTable;