import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import { usePDF, pdf, Text, View, StyleSheet, BlobProvider } from '@react-pdf/renderer';
import printJS from "print-js";


import { PDFDownloadLink, Document, Page, Image } from '@react-pdf/renderer';



const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});


const MyDoc = () => (
    <Document>
        <Page>

        </Page>
    </Document>
);

// const blob = pdf(MyDoc).toBlob()

const DashboardWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonDiv = styled.div`
  height: 50px;
  width: 50px;

`


const StyledP = styled.p`
  font-size: 50px;
`

// const pdfBlob = pdf(MyDoc).toBlob()

const Dashboard = (props) => {

    // const [instance, updateInstance] = usePDF({ document: MyDoc });
    const [state, setState] =useState(true)


    // const handlePrintPage = (pdfUrl) => {
    //         printJS(blob)
    //
    //
    // }

    useEffect(() => {
        // const pdfBlob = pdf(MyDoc).toBlob()
    }, [])

    return (
        <>
            <DashboardWrapper>
                {/*<button type="button" onClick={handlePrintPage}>*/}
                {/*    Print PDF*/}
                {/*</button>*/}
                {/*{instance.loading ?*/}
                {/*    <p>loaded</p>*/}
                {/*    :*/}
                {/*    <p>is loading...</p>*/}
                {/*}*/}

                {/*instance.error*/}

            <div>
                {/*<PDFDownloadLink document={<MyDoc />} fileName="somename.pdf">*/}
                {/*    {({ blob, url, loading, error }) => {*/}
                {/*        if (loading) {*/}
                {/*        return <div/>*/}

                {/*    } else {*/}
                {/*        return(*/}
                {/*        <button type="button" onClick={() => {handlePrintPage(url)}}>*/}
                {/*        Print PDF*/}
                {/*        </button>*/}
                {/*        )*/}
                {/*    }*/}
                {/*    }}*/}
                {/*</PDFDownloadLink>*/}
                {/*<BlobProvider document={MyDoc}>*/}
                {/*    {({ blob, url, loading, error }) => {*/}
                {/*        if (loading) {*/}
                {/*            return <p>loading...</p>*/}

                {/*        } else {*/}
                {/*            return(*/}
                {/*                <button type="button" onClick={() => handlePrintPage(url)}>*/}
                {/*                    Print PDF*/}
                {/*                </button>*/}
                {/*            )*/}
                {/*        }*/}
                {/*    }}*/}
                {/*</BlobProvider>*/}
            </div>
                <StyledP>LemmmanSoft v0.0.2b</StyledP>
            </DashboardWrapper>
        </>
    )
}

export default withRouter(Dashboard)