import React from 'react';
import styled from "styled-components";
import ButtonWrapper from "./ButtonWrapper";

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px 22px 4px 22px;
  cursor: default;
  //background-color: #eaeaea;
  border: 1px #bebebe solid;

  .HeaderTitleH2 {
    color: #484848;
    font-size: 18px;
  }

  :hover {
    background-color: #e5e5e5;
    border: 1px #cfcfcf solid;

    .HeaderTitleH2 {
      color: #5a5a5a;

    }
  }

`;

const StyledH2 = styled.h1`
  color: #808080;
  

`

const TextButton = ({className = '', elementProps = {}}) => {

    const clickHandler = () => {
        elementProps.output && elementProps.output()
    };


    return (
        <ButtonWrapper
            elementName={'TextButton'}
            clickHandler={clickHandler}
        >
            <WrapperDiv className={`BannerTextButton ${className}`}>
                <h2 className={'HeaderTitleH2'} style={{whiteSpace: 'noWrap'}}>{elementProps.label}</h2>
            </WrapperDiv>
        </ButtonWrapper>
    )

}

export default TextButton;