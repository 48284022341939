import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core";
import SimpleDropdown from "../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import ShadowBox from "../../templates/boxes/ShadowBox";
import "../../templates/wrappers/FlexRowWrapper";
import FlexRowWrapper from "../../templates/wrappers/FlexRowWrapper";
import SortableTableList from "../../templates/tables/SortableTableList/SortableTableList";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../templates/wrappers/LoaderWrapper";
import {mapTaskGroupProcessServerToTaskGroupProcessObject} from "../../../library/helpers/functions/dataMaps";
import ContentBanners from "../../frames/content/ContentBanners";
import TextButton from "../../templates/buttons/TextButton";
import ReOrderTasks from "./components/ReOrderTasks/ReOrderTasks";
import filterFilterLists from "../Document/library/helpers/filterFilterLists";
import AddButtonFloating from "../../templates/buttons/AddButtonFloating";
import EditTaskForm from "./components/EditTaskForm";
import useFetch from "../../../appModules/StateBot/library/helpers/hooks/useFetch";
import PdfGeneratorList from "../../templates/pdf/PdfGeneratorList/PdfGeneratorList";
import {Document, Page, Text, View, StyleSheet} from "@react-pdf/renderer";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import SVGButton from "../../templates/buttons/SVGButton";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '31%',

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

const TaskWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PageWrapper = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 20px;

`

const FilterWrapper = styled.div`
  width: 100%;
  height: 70px;
  margin: 20px 0;

`

const FilterInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`

const ListWrapper = styled.div`
  height: calc(100% - 130px);
  width: 100%;
  //margin: 20rem 0;
`

const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TopRightElementWrapper = styled.div`
  height: 100%;
  display: flex;
  
`

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const OpenDialogButtonWrapper = styled.div`
  height: 37px;
  width: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 4px 2px;
  cursor: default;
  //background-color: #eaeaea;
  border: 1px #bebebe solid;
  color: #5A5A5A;
  .MuiSvgIcon-root {
    //height: 20px;
  }
  :hover {
    background-color: #e5e5e5;
    border: 1px #cfcfcf solid;
    color: #5a5a5a;
  }
`

const headCells = [
    { id: 'headerString', numeric: false, disablePadding: false, label: 'Action', width: '33%' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project', width: '12%' },
    { id: 'processRef', numeric: false, disablePadding: false, label: 'Process Ref', width: '15%' },
    { id: 'process', numeric: false, disablePadding: false, label: 'Process', width: '20%' },
    { id: 'processType', numeric: false, disablePadding: false, label: 'Process Type', width: '20%' },
]

const pdfStyles = StyleSheet.create({
    page: {
        padding: 75,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    header: {
        padding: '10px 0',

    },
    headerText: {
        fontWeight: 'bold'
    },
    section: {
        // margin: 10,
        // padding: 10,
        // flexGrow: 1
    }
})

const filterFieldList = [
    {field: 'project', label: 'Project', filter: '', type: 'string'},
    {field: 'process', label: 'Process'} //optional filterString
]

const PdfListElement = ({pdfListObjectIn, listIndexIn = null}) => {
    return (
        <View style={pdfStyles.section}>
            <Text style={pdfStyles.headerText}>{pdfListObjectIn.headerString}</Text>
        </View>
    )
}

const PdfFrame = ({children}) => {
    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={pdfStyles.header}>
                    <Text>TASK GROUP LIST</Text>
                </View>
                {children}
            </Page>
        </Document>
    )
}

const TaskList = (props) => {

    const [taskServer, hydrateTaskServer, setState, isFetching] = useRESTCRUD({
        endpoint: 'taskProcess',
    })

    const [addNewTaskProcess, isFetchingAddNewProcessTask] = useFetch({
        endpoint: 'taskProcessNew',
    })

    const contactsRequest = {
        endpoint: 'contact',
    }

    const [contactsServer, hydrateContactsServer, setContactsServer, isContactsLoading] = useRESTCRUD(contactsRequest)

    const sendEmailRequest = {
        endpoint: 'emailBlobFile',
    }

    const [sendEmail, isFetchingSendEmail] = useFetch(sendEmailRequest)

    const [bodyRows, setBodyRows] = useState([])
    const [showExportDialog, setShowExportDialog] = useState(false)
    const [displayRows, setDisplayRows] = useState([])
    const [isTableLoaded, setIsTableLoaded] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [processList, setProcessList] = useState([])
    const [processTypeList, setProcessTypeList] = useState([])
    const [projectFilter, setProjectFilter] = useState('')
    const [processFilter, setProcessFilter] = useState('')
    const [processTypeFilter, setProcessTypeFilter] = useState('')
    const [projectFilterInitSelection, setProjectFilterInitSelection] = useState(-1)
    const [processFilterInitSelection, setProcessFilterInitSelection] = useState(-1)
    const [processTypeFilterInitSelection, setProcessTypeFilterInitSelection] = useState(-1)
    const [showReorderTasks, setShowReorderTasks] = useState(false)
    const [showAddTasks, setShowAddTasks] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        if (bodyRows) {
            const {
                mappedProjectList,
                mappedProcessList,
                mappedProcessTypeList,
            } = filterFilterLists(bodyRows)
            setProjectList(mappedProjectList)
            setProcessList(mappedProcessList)
            setProcessTypeList(mappedProcessTypeList)
        }
    }, [bodyRows])

    useEffect(() => {
        if (taskServer) {
            const newState = []

            taskServer.forEach((task, i) => {
                if (task.process) {
                    return newState.push(mapTaskGroupProcessServerToTaskGroupProcessObject(task))

                }

            })
            setBodyRows(newState)
            setDisplayRows(newState)
            setIsTableLoaded(true)
        }
    }, [taskServer])

    useEffect(() => {
        const filteredRows = bodyRows
            .filter((option) => {
                if (projectFilter) {
                    return option.projectId === projectFilter
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (processFilter) {
                    return option.processId === processFilter
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (processTypeFilter) {
                    return option.processTypeId === processTypeFilter
                } else {
                    return true
                }
            })
        setDisplayRows(filteredRows)

        //    task: also filter lists (resetting lists if filtered items don't have selected value)

    }, [projectFilter, processFilter, processTypeFilter, bodyRows])

    const handleFilterProjectSelection = (index) => {
        if (index === -1) {
            setProjectFilter('')
        } else {
            setProjectFilter(projectList[index].data.projectId)
        }
    }

    const handleFilterProcessSelection = (index) => {
        if (index === -1) {
            setProcessFilter('')
        } else {
            setProcessFilter(processList[index].data.processId)
        }
    }

    const handleFilterProcessTypeSelection = (index) => {
        if (index === -1) {
            setProcessTypeFilter('')
        } else {
            setProcessTypeFilter(processTypeList[index].data.processTypeId)
        }
    }

    const handleTableSelection = (taskObject) => {
        props.history.push(`/task/view/${taskObject.id}`, taskObject)
    }

    const handleOpenEditTasks = () => {
        setShowReorderTasks(true)
    }

    const handleCloseEditTasks = () => {
       setShowReorderTasks(false)
    }

    const handleClickAddContact = () => {
        setShowAddTasks(true)
    }

    const handleCreateNewTask = async (newTask) => {
        if (newTask.processId) {
            const requestObj = {
                name: newTask.headerString ? newTask.headerString : '',
                date: newTask.date ? newTask.date : '',
                manager: newTask.managerId ? newTask.managerId : '',
                process: newTask.processId ? newTask.processId : '',
            }
            await addNewTaskProcess({body: JSON.stringify(requestObj)})
            await hydrateTaskServer()
        }

    }

    const handleCloseAddTaskDialog = () => {
        setShowAddTasks(false)
    }

    const handleClickOpenDialog = () => {
        setShowExportDialog(true)
    }

    const DefaultListDiv = (displayObject, instanceIndex) => {
        return (
            <ListItemWrapper
                className={'ListItemWrapper'}
            >
                <p>{displayObject.headerString}</p>
            </ListItemWrapper>
        )
    }

    const handleFetchContacts = async () => {
        const updatedContacts = await hydrateContactsServer()
        if (contactsServer) {
            return await updatedContacts.map((contact, i) => {
                // debugger
                if (contact.details.given_names) {
                    const givenNames = contact.details.given_names.join(' ')
                    return {
                        label: `${givenNames} ${contact.details.surname}`,
                        value: i,
                        data: contact
                    }
                } else {
                    return {
                        label: undefined,
                        value: undefined,
                    }
                }
            })
        }
        return []
    }

    const handleSendEmail = async (formData) => {
        await sendEmail({body: formData})
    }

    const TopRightElement = (props) => {
        return (
            <TopRightElementWrapper>
                <TextButton
                    elementProps={props.elementProps}
                />
                <SVGButton
                    handleClickOut={handleClickOpenDialog}
                    SVGIconIn={PresentToAllIcon}
                />
            </TopRightElementWrapper>
        )
    }


    return (
        <TaskWrapper className={'DocumentWrapper'}>
            <ContentBanners
                headerTitle={'Action Browser'}
                TopRightElement={{component: TopRightElement, props: {
                        label: 'Organise',
                        output: handleOpenEditTasks,
                    }}}
                topLeftOut
                topRightOut
                bottomLeftOut
                bottomCenterOut
                bottomRightOut
            >
                <PageWrapper className={'PageWrapper'}>
                    <FilterWrapper className={'FilterWrapper'}>
                        <ShadowBox className={'ShadowBox'} >
                            <FlexRowWrapper divHeight={'100%'} divWidth={'100%'}>
                                <FilterInnerWrapper>
                                    <SimpleDropdown
                                        classes={classes}
                                        title={'Project'}
                                        inputArray={projectList}
                                        onSelect={handleFilterProjectSelection}
                                        selectNone={true}
                                        initSelection={projectFilterInitSelection}
                                    />
                                    <SimpleDropdown
                                        classes={classes}
                                        title={'Process'}
                                        inputArray={processList}
                                        onSelect={handleFilterProcessSelection}
                                        selectNone={true}
                                        initSelection={processFilterInitSelection}
                                    />
                                    <SimpleDropdown
                                        classes={classes}
                                        title={'Process Type'}
                                        inputArray={processTypeList}
                                        onSelect={handleFilterProcessTypeSelection}
                                        selectNone={true}
                                        initSelection={processTypeFilterInitSelection}
                                    />
                                </FilterInnerWrapper>
                            </FlexRowWrapper>
                        </ShadowBox>
                    </FilterWrapper>
                    <ListWrapper className={'ListWrapper'}>
                        <ShadowBox className={'ShadowBox'}>
                            <LoaderWrapper isLoaded={isTableLoaded}>
                                <InnerListWrapper>
                                    <AddButtonFloating
                                        onClick={handleClickAddContact}
                                    />
                                    <SortableTableList
                                        headCells={headCells}
                                        bodyRows={displayRows}
                                        onSelect={handleTableSelection}
                                    />
                                </InnerListWrapper>
                            </LoaderWrapper>
                        </ShadowBox>
                    </ListWrapper>
                </PageWrapper>
            </ContentBanners>
            <div>
                <ReOrderTasks
                    todoObjectIn={bodyRows}
                    showDialogIn={showReorderTasks}
                    closeDialogOut={handleCloseEditTasks}
                />
                <EditTaskForm
                    addMode={true}
                    showDialogIn={showAddTasks}
                    addTaskOut={handleCreateNewTask}
                    closeDialogOut={handleCloseAddTaskDialog}
                />
                <PdfGeneratorList
                    showDialog={showExportDialog}
                    setShowDialog={setShowExportDialog}
                    listArrayIn={displayRows}
                    fieldFiltersIn={filterFieldList}
                    DisplayListElementIn={DefaultListDiv}
                    PdfFrameIn={PdfFrame}
                    PdfListElementIn={PdfListElement}
                    fetchContactsOut={handleFetchContacts}
                    sendEmailOut={handleSendEmail}
                />
            </div>
        </TaskWrapper>
    )
}

export default withRouter(TaskList)