import React from 'react';
import styled from "styled-components";

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  //padding: 8px;
  //margin-bottom: 8px;
  background-color: ${props => props.isDraggingOver ? 'bold' : 'normal'};
`


const StyledP = styled.p`
  font-size: 22px;
  color: ${props => props.isDraggingOver ? 'white' : 'black'};
`

const StyledH5 = styled.h5`
  font-size: 24px;
  color: ${props => props.isDraggingOver ? 'white' : 'black'};
`


const LabelWrapper = styled.p`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 16px;
  //padding: 5px;

`


const TaskGroup = (props) => {
    return (

        <BodyWrapper>
            <LabelWrapper>
            <StyledH5
                isDraggingOver={props.isDraggingOver}
            >
                {props.task.headerString}
            </StyledH5>
                <StyledP
                    isDraggingOver={props.isDraggingOver}
                >
                    {props.task.checkboxArray.length} Items
                </StyledP>
            </LabelWrapper>
        </BodyWrapper>

    )

};

export default TaskGroup;