const {
    REACT_APP_DATA_SERVER_PROTOCOL,
    REACT_APP_DATA_SERVER_ADDRESS,
    REACT_APP_DATA_SERVER_PORT,
} = process.env

const restConfig = {
    primary: {
        server: {
            protocol: REACT_APP_DATA_SERVER_PROTOCOL,
            address: REACT_APP_DATA_SERVER_ADDRESS,
            port: REACT_APP_DATA_SERVER_PORT,
        },
        auth: {
            type: 'restJWT',
            endpoints: {
                token: '/api/auth/token/',
                tokenRefresh: '/api/auth/token/refresh/',
                tokenVerify: '/api/auth/token/verify/',
                passwordReset: '/api/auth/password-reset/',
                passwordValidation: '/api/auth/password-reset/validation/',
                registration: '/api/auth/registration/',
            }
        },
        endpoints: {
            crud: {
                user: {
                    endpoint: '/api/core/user/',
                },
                document: {
                    endpoint: '/api/model/document/',
                },
                processDocument: {
                    endpoint: '/api/model/document/process/',
                },
                processProject: {
                    endpoint: '/api/model/process/project/',
                },
                contact: {
                    endpoint: '/api/model/contact/',
                },
                contactDocument: {
                    endpoint: '/api/model/contact/document/',
                },
                dataFile: {
                    endpoint: '/api/model/file/data/',
                },
                proxyFile: {
                    endpoint: '/api/model/file/proxy/',
                },
                proxyPdf: {
                    endpoint: '/api/model/file/proxy/proxy-pdf/',
                },
                tasksItemRefDocument: {
                    endpoint: '/api/meta/ref/task/item/process/document/',
                },
                tasksGroupRefDocument: {
                    endpoint: '/api/meta/ref/task/group/process/document/',
                },
                taskItem: {
                    endpoint: '/api/model/task/item/',
                },
                taskGroup: {
                    endpoint: '/api/model/task/group/',
                },
                taskProcess: {
                    endpoint: '/api/model/task/process/',
                },
                tag: {
                    endpoint: '/api/meta/tag/',
                },
                tagProcessDocument: {
                    endpoint: '/api/meta/tag/process/document/',
                },
            },
            fetch: {
                csvUpload: {
                    endpoint: '/api/util/io/import/csv',
                    method: 'GET',
                    header: {
                        'Content-Type': 'application/json',
                    },
                },
                updateContact: {
                    endpoint: '/api/model/contact/document/',
                    method: 'PATCH',
                    header: {
                        'Content-Type': 'application/json',
                    },
                },
                getPDF: {
                    endpoint: '/proxy/proxy_files/bd424570-3799-44e6-94b2-27ece763bfd5/',
                    method: 'GET',
                    header: {
                        'Content-Type': 'application/pdf',
                    },
                },
                emailProxyFile: {
                    endpoint: '/api/util/mail/send/proxy-file/',
                    method: 'POST',
                    header: {
                        'Content-Type': 'application/json',
                    },
                },
                emailBlobFile: {
                    endpoint: '/api/util/mail/send/blob-file/',
                    method: 'POST',
                },
                taskProcessNew: {
                    endpoint: '/api/model/task/process/group/',
                    method: 'POST',
                    header: {
                        'Content-Type': 'application/json',
                    },
                },
                taskItemRefProcDocCreate: {
                    endpoint: '/api/meta/ref/task/item/process/document/',
                    method: 'POST',
                    header: {
                        'Content-Type': 'application/json',
                    },
                }   ,
                taskItemCreate: {
                    endpoint: '/api/model/task/item/',
                    method: 'POST',
                    header: {
                        'Content-Type': 'application/json',
                    },
                }
            }
        }
    },
}

const file_server = {
    primary: {

    }
}

export default restConfig