import React from "react";
import StateBot from "./appModules/StateBot/StateBot";
import restConfig from "./library/configs/fetchConfig";
import Lemmman from "./elements/app/Lemmman";
import {MemoryRouter, Switch} from "react-router-dom";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#646464'
        },
    },
    typography: {
        fontFamily: [
            'Heebo'
        ],
    },
});

function App() {
    return (
        <div className="App">
            <React.StrictMode>
                <StateBot authObject={restConfig.primary} restObject={restConfig}>
                    <MuiThemeProvider theme={theme}>
                        <MemoryRouter>
                            <Switch>
                                <Lemmman/>
                            </Switch>
                        </MemoryRouter>
                    </MuiThemeProvider>
                </StateBot>
            </React.StrictMode>
        </div>
    );
}

export default App;
