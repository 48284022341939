import React, {useEffect} from "react";
import styled from "styled-components";
import SideBar from "./sidebar/SideBar";
import ContentRoutes from "./content/ContentRoutes";

const BodyDiv = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  //background-color: aqua;
`;

const AppFrame = (props) => {


    return (
        <BodyDiv>
            <SideBar/>
            <ContentRoutes/>
        </BodyDiv>
    )
};


export default AppFrame