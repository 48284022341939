import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import GroupCheckbox from "../GroupCheckbox/GroupCheckbox";
import AddButtonFloating from "../../buttons/AddButtonFloating";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import SimpleCheckbox from "../SimpleCheckbox/SimpleCheckbox";
import DraggableList from "../../lists/DraggableList/DraggableList";

const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  overflow-y: scroll;
  padding: ${props => props.padding};
`

const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const CheckboxFrameWrapper = styled.div`
  width: 100%;
  padding-right: 10px;
`


const defaultCheckboxGroup = [
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
    {taskString: 'this is a task1', isCheckedBool: false},
    {taskString: 'this is a task2', isCheckedBool: false},
    {taskString: 'this is a task3', isCheckedBool: false},
    {taskString: 'this is a task4', isCheckedBool: false},
]

// task: add ordering of groups and items (maybe allow items to be moved between groups)
// ultimately groups will be virtualized...
// task: add splash if no items to show
const DraggableCheckboxList = (props) => {

    const {
        padding = 0,
        loadOnce = false,
        checkboxItemArray = defaultCheckboxGroup,
        onAddItemOut = (newGroupObject) => {},
        onSetItemOut = (groupObject, instanceIndex) => {},
        onDeleteItemOut = (instanceIndex) => {},
    } = props

    const [checkboxItems, setCheckboxItems] = useState(checkboxItemArray)
    const [openDialog, setOpenDialog] = useState(false)
    const [newTodoName, setNewTodoName] = useState('')
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (checkboxItemArray) {
            if (loadOnce) {
                if (!isLoaded) {
                    const checkboxList = []
                    checkboxItemArray.forEach((checkboxItem) => {
                        checkboxList[checkboxItem.priority] = checkboxItem
                    })
                    setCheckboxItems(checkboxList)
                }

            } else {
                const checkboxList = []
                checkboxItemArray.forEach((checkboxItem) => {
                    checkboxList[checkboxItem.priority] = checkboxItem
                })
                setCheckboxItems(checkboxList)
            }
            setIsLoaded(true)
        }
    }, [checkboxItemArray, loadOnce, isLoaded])

    const handleDeleteItem = (uuid) => {
        onDeleteItemOut(uuid)

        // setCheckboxItems(prev => {
        //     const index = prev.map(e => e.id).indexOf(uuid)
        //     prev.splice(index, 1)
        //     return [...prev]
        // })
    }

    const handleClickAddButton = () => {
        setOpenDialog(true)
    }

    const handleAddDialog = (event) => {
        setOpenDialog(false)
        if (newTodoName.trim()) {
            const newItem = {
                taskString: newTodoName.trim(),
                isCheckedBool: false,
            }
            newItem.priority = checkboxItems.length
            setCheckboxItems(prev => {
                return [...prev, newItem]
            })
            onAddItemOut(newItem)
        }
        setNewTodoName('')
    }

    const handleEnterDialog = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            handleAddDialog(event)
        }
    }

    const handleCancelDialog = () => {
        setOpenDialog(false)
    }

    const handleChangeNewItem = (event) => {
        setNewTodoName(event.target.value)
    }

    const handleDragOut = (source, destination) => {

    }


    // `
    //             onCheckGroupOut={() => {}}
    //             onSetHeaderOut={() => {}}
    //             onToggleExpandedOut={() => {}}
    //             onSetGroupOut={onSetGroupOut}
    //             onDeleteGroupOut={handleDeleteCheckboxGroup}
    //
    // `

    const CheckboxItems = (props) => {
        return (
            <SimpleCheckbox
                key={`${props.listItem.id}`}
                instanceIndex={props.itemIndex}
                inputItemObject={props.listItem}
                expandedBool={false}
                onSetItemOut={onSetItemOut}
                onDeleteItemOut={handleDeleteItem}
            />
        )
    }

    const CheckboxFrame = (props) => {
        return (
            <CheckboxFrameWrapper>
                {props.children}
            </CheckboxFrameWrapper>
        )
    }

    return (
        <BodyWrapper
            className={'SimpleCheckBoxList'}
            padding={padding}
        >
                <DraggableList
                    listArrayIn={checkboxItems}
                    ListItemComponent={CheckboxItems}
                    ListFrameComponent={CheckboxFrame}
                    handleDragOutList={() => {}}
                    handleDragOutMap={() => {}}
                    handleDragOutIndex={handleDragOut}
                />
                <div>
                    <AddButtonFloating
                        onClick={handleClickAddButton}
                    />
                </div>
            <Dialog open={openDialog} onClose={handleCancelDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{'Add new task'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Todo Label"
                        fullWidth
                        value={newTodoName}
                        onChange={handleChangeNewItem}
                        onKeyDown={handleEnterDialog}
                    />
                </DialogContent>
                <AlertButtonsWrapper>
                    <Button onClick={handleCancelDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddDialog} color="primary">
                        Add
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </BodyWrapper>
    )

};

export default DraggableCheckboxList;