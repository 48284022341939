import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import BackButton from "../../templates/buttons/BackButton";
import TextButton from "../../templates/buttons/TextButton";
import ContentBanners from "../../frames/content/ContentBanners";

import FlexRowWrapper from "../../templates/wrappers/FlexRowWrapper";

const Contact = (props) => {

    const returnToList = () => {
        props.history.push('/document/list/')
    };

    return (
        <>
            <ContentBanners
                headerTitle={'Document'}
                TopLeftElement={{component: BackButton, props: {
                        label: 'Document List',
                        output: () => returnToList()
                    }}}
                TopRightElement={{component: TextButton, props: {
                        label: 'Edit'
                    }}}
                BottomLeftElement={{component: TextButton, props: {
                        label: 'Delete'
                    }}}
                BottomCenterElement={{component: TextButton, props: {
                        label: 'Share'
                    }}}
                BottomRightElement={{component: TextButton, props: {
                        label: 'Print'
                    }}}
                topLeftOut
                topRightOut
                bottomLeftOut
                bottomCenterOut
                bottomRightOut
            >
                <FlexRowWrapper divHeight={'100%'} divWidth={'100%'} flexJustifyContent={'space-between'}>

                </FlexRowWrapper>
            </ContentBanners>

        </>

    )
}

export default withRouter(Contact)