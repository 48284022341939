import React from 'react';
import styled from "styled-components";
import BannerTitle from "../../templates/text/BannerTitle";

const WrapperDiv = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid #D6D6D6 1px;
  padding: 0 15px 0 0;
`;

const ButtonLeftWrapper = styled.div`
  height: 100%;
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
`;

const ButtonRightWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-end;
`;


const ContentHeaderNav = ({
    title = '',
    className = '',
    TopLeftElement = null,
    TopRightElement = null,
    elementProps = {},
                          }) => {
    return (
        <WrapperDiv className={`ContentFormBodyHeader ${className}`}>
            <ButtonLeftWrapper className={`ButtonLeftWrapper`}>
                {TopLeftElement && <TopLeftElement.component
                    elementProps={TopLeftElement.props}
                />}
            </ButtonLeftWrapper>
            <BannerTitle text={title}/>
            <ButtonRightWrapper className={`ButtonRightWrapper`}>
                {TopRightElement && <TopRightElement.component elementProps={TopRightElement.props}/>}
            </ButtonRightWrapper>
        </WrapperDiv>
    )
};

export default ContentHeaderNav;