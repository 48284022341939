import {useState, useEffect, useContext} from "react";
import {StateBotContext} from "../../../StateBot";
import fetchAsync from "../functions/fetchAsync";
import generateFilterString from "../functions/generateFilterString";

const defaultOptions = {
    returnPromise: false,
    authRequired: true,
    refreshInterval: null,
    writeOnly: false,
    initFetch: true,
}

const useRESTCRUD = (request) => {

    const {
        server = 'primary',
        endpoint,
        instance = '',
        filterObject = null,      // filters is really on the list
        options = defaultOptions
    } = request

    const {requestObject, sessionObject, sessionState, setSessionState} = useContext(StateBotContext)

    const [state, setState] = useState(null)
    const [isFetching, setIsFetching] = useState(true)

    useEffect(() => {
        if (`${server}_${endpoint}_${instance}` in sessionState.data) {
            setState(sessionState.data[`${server}_${endpoint}_${instance}`])
        }
    }, [sessionState, server, endpoint, instance])


    const compiledOptions = {...defaultOptions, ...options}

    let headers = {
        'Content-Type': 'application/json',
    }

    if (compiledOptions.authRequired) {
        headers['Authorization'] = `Bearer ${sessionObject.session.token.access}`
    }

    const restObject = requestObject.restObject

    const fetchObject = {
        apiBaseUrl: `${restObject[server].server.protocol}://${restObject[server].server.address}:${restObject[server].server.port}`,
        requestUrl: `${restObject[server].endpoints.crud[endpoint].endpoint}`,
        method: 'GET',
        headers,
    }

    const handleSetSessionState = async (data) => {
        if (data) {
            setSessionState(prev => {
                return {...prev, data: {...prev.data, [`${server}_${endpoint}_${instance}`]: data, _cache: true}}
            })
        }
    }

    const fetchState = async () => {
        if (!options.writeOnly) {
            if (`${server}_${endpoint}_${instance}` in sessionState.data) {
                setState(sessionState.data[`${server}_${endpoint}_${instance}`])
            }
            let requestEndpointString = generateFilterString(filterObject, instance)
            const getFetchObject = {...fetchObject, requestUrl: `${fetchObject.requestUrl}${requestEndpointString}`}
            await setIsFetching(true)
            const serverResponse = await fetchAsync(getFetchObject)
            await setState(serverResponse[1])
            await handleSetSessionState(setState(serverResponse[1]))
            await setIsFetching(false)
            return serverResponse[1]
        }
    }

    useEffect(() => {
        if (options.initFetch) {
            fetchState().then(data => {
                const innerFunc = async () => {
                    await handleSetSessionState(data)
                }
                innerFunc()
            })
        }
        // logs etc etc tec
        // here resolve requestObject into request data stored on StateBot input object
        // here a switch statement which builds the request object out of defaults // or spread oeprator
        // here the request made
        // if no error, the request is cached... can cache many requests
        // here if promise resoled return promise
        // else .then(data => return data)
    }, [server, endpoint, instance])

    const setStateFunc = async (newState, requestInstance = instance) => {

        let setRequestObject

        if (requestInstance) {
            if (newState === null) {
                const convergedFetchObject = {
                    ...fetchObject,
                    requestUrl: `${fetchObject.requestUrl}${requestInstance}`,
                }
                setRequestObject = {...convergedFetchObject, method: 'DELETE'}
                // this will delete an item
            } else {
                const convergedFetchObject = {
                    ...fetchObject,
                    requestUrl: `${fetchObject.requestUrl}${requestInstance}`,
                }
                setRequestObject = {...convergedFetchObject, method: 'PATCH', body: JSON.stringify(newState)}
                // this will update an item
            }
        } else {
            if (filterObject) {
                let requestEndpointString = generateFilterString(filterObject, requestInstance)
                setRequestObject = {
                    ...fetchObject,
                    requestUrl: `${fetchObject.requestUrl}${requestEndpointString}`,
                    method: 'POST',
                    body: JSON.stringify(newState)
                }
            } else {
                setRequestObject = {...fetchObject, method: 'POST', body: JSON.stringify(newState)}
            }
            // this will create an item
        }
        return await fetchAsync(setRequestObject)
    }
    return [state, fetchState, setStateFunc, isFetching]
//      task: have a single fetch function (currently there are two: getState and fetchState)
}

export default useRESTCRUD

//      maybe these return items are methods off a class/object