const filterFilterLists = (processProjectList) => {
    const dupProjectList = []
    const dupProcessList = []
    const dupProcessTypeList = []

    const mappedProjectList = []
    const mappedProcessList = []
    const mappedProcessTypeList = []

    processProjectList.forEach((processProject, i) => {
        if (!dupProjectList.includes(processProject.projectId)) {
            dupProjectList.push(processProject.projectId)
            mappedProjectList.push({
                label: processProject.project,
                data: processProject,
            })
        }
        if (!dupProcessList.includes(processProject.processId)) {
            dupProcessList.push(processProject.processId)
            mappedProcessList.push({
                label: `${processProject.processRef}: ${processProject.process}`,
                data: processProject,
            })
        }
        if (!dupProcessTypeList.includes(processProject.processTypeId)) {
            dupProcessTypeList.push(processProject.processTypeId)
            mappedProcessTypeList.push({
                label: processProject.processType,
                data: processProject,
            })
        }
    })

    let projectIndex = 0
    let processIndex = 0
    let processTypeIndex = 0

    mappedProjectList.sort((a, b) => {
        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
        return 0
    }).forEach((project) => {
        project.value = projectIndex
        projectIndex++
    })
    mappedProcessList.sort((a, b) => {
        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
        return 0
    }).forEach((process) => {
        process.value = processIndex
        processIndex++
    })
    mappedProcessTypeList.sort((a, b) => {
        if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
        if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
        return 0
    }).forEach((processType) => {
        processType.value = processTypeIndex
        processTypeIndex++
    })

    return {
        mappedProjectList,
        mappedProcessList,
        mappedProcessTypeList,
    }
}

export default filterFilterLists