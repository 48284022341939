import React from 'react';
import styled from "styled-components";

const BodyWrapper = styled.div`

  flex-direction: row;
  justify-content: center;
  align-items: center;

`


const PercentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: visible;
  transform: rotate(90deg);
`

const StyledSVG = styled.svg`
  height: 100%;
  width: 100%;
  overflow: visible;
`

const StyledSVGCircleFront = styled.circle`
  height: 100%;
  width: 100%;
  fill: ${props => props.percent === 100 ? '#929292' : 'none'};
  stroke-width: 25%;
  stroke: ${props => props.percent === 100 ? '#929292' : '#929292'};
  transform: translate(2.5%, 2.5%);
  stroke-dasharray: 314%;
  //stroke-dashoffset: 314%;
  stroke-dashoffset: calc(314% - (314% * ${props => props.percent}) / 100);
  stroke-linecap: round;

`

const StyledSVGCircleBack = styled.circle`
  height: 100%;
  width: 100%;
  fill: none;
  stroke-width: 25%;
  stroke: #d0d0d0;
  transform: translate(2.5%, 2.5%);
`

const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
`

const NumberText = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
`

const CircleProgress = (props) => {

    return (
        <BodyWrapper>
            <PercentWrapper>
                <StyledSVG viewBox={'0 0 1 1'}>
                    <StyledSVGCircleBack cx={'50%'} cy={'50%'} r={'50%'}/>
                    <StyledSVGCircleFront cx={'50%'} cy={'50%'} r={'50%'} percent={props.percent}/>
                </StyledSVG>
                {/*<NumberWrapper>*/}
                {/*    <NumberText>{props.percent}%</NumberText>*/}
                {/*</NumberWrapper>*/}
            </PercentWrapper>
        </BodyWrapper>
    )

};

export default CircleProgress;