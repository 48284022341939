import React from "react";
import styled from "styled-components";
import sizes from "../../../library/objects/theme/definitions/sizes";
import ContentHeader from "../../frames/content/ContentHeader";

export default styled(ContentHeader)`

  .BannerTitle {
    h1 {
      // cursor: default;
      // font-size: ${sizes.mainPane[1].text[1].bannerTitle[0]};
      // color: #f1f1f1;

    }
  }

`