import React from 'react';
import styled from "styled-components";
import BannerTitle from "../../templates/text/BannerTitle";

const WrapperDiv = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: solid #e5e5e5 1px;
  padding: 0 30px;
`;


const ContentHeader = (props) => {

    return (
        <WrapperDiv className={`ContentHeader ${props.className}`}>
            <BannerTitle text={props.title}/>
        </WrapperDiv>
    )
};

export default ContentHeader;