import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Button, Checkbox, Dialog, DialogTitle} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CheckInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
width: 100%;
`

const TodoItemInput = styled.input`
  width: 100%;
    background: none;
  border: none;
  outline: none;
  position: relative;
  top: 1px;
`

const DeleteIcon = styled(DeleteForeverIcon)`
  position: relative;
  top: 3px;
  opacity: 0.5;
  :hover {
    opacity: 0.8;
  }
`


const checkboxObject = {taskString: 'this is a task4', isCheckedBool: false}

const SimpleCheckbox = (props) => {

    const {
        inputItemObject = checkboxObject,
        onSetItemOut = (value, index) => {},
        onDeleteItemOut = (uuid) => {},
        instanceIndex = 0
    } = props

    const [isChecked, setIsChecked] = useState(inputItemObject.isCheckedBool)
    const [inputValue, setInputValue] = useState(inputItemObject.taskString)
    const isUpdated = useRef(true)

    useEffect(() => {
        setInputValue(inputItemObject.taskString)
        setIsChecked(inputItemObject.isCheckedBool)
    }, [inputItemObject])


    const handleTodoItemCheck = (event, index) => {
        setIsChecked(prev => {
            return !prev
        })
        onSetItemOut({id: inputItemObject.id, taskString: inputValue, isCheckedBool: !isChecked}, instanceIndex)
    };

    const handleInputChange = (event, index) => {
        isUpdated.current = false
        setInputValue(event.target.value)

    }

    const handleUpdateTaskBlur = (event, index) => {
        if (!isUpdated.current) {
            onSetItemOut({id: inputItemObject.id, taskString: event.target.value, isCheckedBool: isChecked}, instanceIndex)
            isUpdated.current = true

        }

    }

    const handleUpdateTaskEnter = (event, index) => {
        if (!isUpdated.current) {
            if (event.keyCode === 13) {
                event.preventDefault()
                onSetItemOut({id: inputItemObject.id, taskString: event.target.value, isCheckedBool: isChecked}, instanceIndex)
                isUpdated.current = true
            }
        }
    }

    const handleRemoveTodoItem = (uuid) => {
        onDeleteItemOut(uuid)

    }


    return (
        <BodyWrapper>
            <CheckInputWrapper>
                <Checkbox
                    className={'Checkbox'}
                    checked={isChecked}
                    onChange={(event) => handleTodoItemCheck(event, instanceIndex)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    color={'primary'}
                />
                <TodoItemInput
                    className={'TodoItemInput'}
                    value={inputValue}
                    onChange={(event) => handleInputChange(event, instanceIndex)}
                    onBlur={handleUpdateTaskBlur}
                    onKeyDown={handleUpdateTaskEnter}
                />
            </CheckInputWrapper>
            <DeleteIcon
                className={'DeleteIcon'}
                onClick={(event) => handleRemoveTodoItem(inputItemObject.id)}
            />
        </BodyWrapper>
    )

};

export default SimpleCheckbox;