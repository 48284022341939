import React from 'react';
import styled from "styled-components";

const WrapperDiv = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: solid #D6D6D6 1px;
  padding: 0 40px;
  padding-bottom: 5px;
`;

const ButtonLeftWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
`

const ButtonCenterWrapper = styled.div`

`;

const ButtonRightWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: row;
justify-content: flex-end;
`;

const ContentFooterNav = ({
    className = '',
    BottomLeftElement = null,
    BottomCenterElement = null,
    BottomRightElement = null,
                          }) => {

    return (
        <WrapperDiv className={`ContentFormBodyFooter ${className}`}>
            <ButtonLeftWrapper>
                {BottomLeftElement && <BottomLeftElement.component elementProps={BottomLeftElement.props}/>}
            </ButtonLeftWrapper>
            <ButtonCenterWrapper>
                {BottomCenterElement && <BottomCenterElement.component elementProps={BottomCenterElement.props}/>}
            </ButtonCenterWrapper>
            <ButtonRightWrapper>
                {BottomRightElement && <BottomRightElement.component elementProps={BottomRightElement.props}/>}
            </ButtonRightWrapper>
        </WrapperDiv>
    )
};

export default ContentFooterNav;