import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import {makeStyles} from "@material-ui/core";
import SimpleDropdown from "../../templates/dropdowns/SimpleDropdown/SimpleDropdown";
import ShadowBox from "../../templates/boxes/ShadowBox";
import "../../templates/wrappers/FlexRowWrapper";
import FlexRowWrapper from "../../templates/wrappers/FlexRowWrapper";
import SortableTableList from "../../templates/tables/SortableTableList/SortableTableList";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import LoaderWrapper from "../../templates/wrappers/LoaderWrapper";
import {mapProcessDocumentServerToProcessDocumentObject} from "../../../library/helpers/functions/dataMaps";
import filterFilterLists from "./library/helpers/filterFilterLists";
import {Document, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import SVGButton from "../../templates/buttons/SVGButton";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import ContentBanners from "../../frames/content/ContentBanners";
import PdfGeneratorList from "../../templates/pdf/PdfGeneratorList/PdfGeneratorList";
import useFetch from "../../../appModules/StateBot/library/helpers/hooks/useFetch";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '23%',

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const DocumentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PageWrapper = styled.div`
  height: calc(100% - 50px);
  width: 100%;
  padding: 0 20px;

`

const FilterWrapper = styled.div`
  width: 100%;
  height: 70px;
  margin: 20px 0;

`;

const FilterInnerWrapper = styled.div`
width: 100%;
  height: 100%;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

`;

const ListWrapper = styled.div`
  height: calc(100% - 130px);
  width: 100%;
  //margin: 20rem 0;
`;

const InnerListWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const TopRightElementWrapper = styled.div`
  height: 100%;
  display: flex;
  
`

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Document', width: '30%' },
    { id: 'pages', numeric: false, disablePadding: false, label: 'Pages', width: '3%' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project', width: '12%' },
    { id: 'processRef', numeric: false, disablePadding: false, label: 'Process Ref #', width: '10%' },
    { id: 'process', numeric: false, disablePadding: false, label: 'Process', width: '15%' },
    { id: 'processType', numeric: false, disablePadding: false, label: 'Process Type', width: '15%' },
    { id: 'tagsDisplay', numeric: false, disablePadding: false, label: 'Tags', width: '15%' },
]

const pdfStyles = StyleSheet.create({
    page: {
        padding: 75,
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    header: {
        padding: '10px 0',

    },
    headerText: {
        fontWeight: 'bold'
    },
    section: {
        // margin: 10,
        // padding: 10,
        // flexGrow: 1
    }
})

const filterFieldList = [
    {field: 'project', label: 'Project', filter: '', type: 'string'},
    {field: 'process', label: 'Process', filter: '', type: 'string'},
    {field: 'processType', label: 'Process Type', filter: '', type: 'string'},
]

const PdfListElement = ({pdfListObjectIn, listIndexIn = null}) => {
    return (
        <View style={pdfStyles.section}>
            <Text style={pdfStyles.headerText}>{`${pdfListObjectIn.name}`}</Text>
        </View>
    )
}

const PdfFrame = ({children}) => {
    return (
        <Document>
            <Page size="A4" style={pdfStyles.page}>
                <View style={pdfStyles.header}>
                    <Text>Document LIST</Text>
                </View>
                {children}
            </Page>
        </Document>
    )
}

const PdfListDiv = (displayObject, instanceIndex) => {
    return (
        <ListItemWrapper>
            <p>{displayObject.name}</p>
        </ListItemWrapper>
    )
}



const DocumentList = (props) => {

    const [contactServer, hydrateContactServer, setContactServer, isFetchingContactServer] = useRESTCRUD({
        endpoint: 'contact',
    })

    const sendEmailRequest = {
        endpoint: 'emailBlobFile',
    }

    const [sendEmail, isFetchingSendEmail] = useFetch(sendEmailRequest)

    const [processDocumentServer, setState, isFetching] = useRESTCRUD({
        endpoint: 'processDocument',
    })

    const [bodyRows, setBodyRows] = useState([])
    const [displayRows, setDisplayRows] = useState([])
    const [isTableLoaded, setIsTableLoaded] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [processList, setProcessList] = useState([])
    const [processTypeList, setProcessTypeList] = useState([])
    const [tagList, setTagList] = useState([])
    const [projectFilter, setProjectFilter] = useState('')
    const [processFilter, setProcessFilter] = useState('')
    const [processTypeFilter, setProcessTypeFilter] = useState('')
    const [tagFilter, setTagFilter] = useState('')
    const [projectFilterInitSelection, setProjectFilterInitSelection] = useState(-1)
    const [processFilterInitSelection, setProcessFilterInitSelection] = useState(-1)
    const [processTypeFilterInitSelection, setProcessTypeFilterInitSelection] = useState(-1)
    const [parentDocFilterInitSelection, setParentDocFilterInitSelection] = useState(-1)
    const [showExportDialog, setShowExportDialog] = useState(false)

    useEffect(() => {
        if (bodyRows) {

            const {
                mappedProjectList,
                mappedProcessList,
                mappedProcessTypeList,

            } = filterFilterLists(bodyRows)

            setProjectList(mappedProjectList)
            setProcessList(mappedProcessList)
            setProcessTypeList(mappedProcessTypeList)
        }

    }, [bodyRows])

    useEffect(() => {
        if (processDocumentServer) {
            const newState = processDocumentServer.map((document, i) => {
                return mapProcessDocumentServerToProcessDocumentObject(document)
            })

            setBodyRows(newState)
            setDisplayRows(newState)
            setIsTableLoaded(true)

            const dupTagList = []
            const mappedTagList = []

            newState.forEach((element) => {
                element.tags.forEach((tag, i) => {
                    if (!dupTagList.includes(tag.tag)) {
                        dupTagList.push(tag.tag)
                        mappedTagList.push({label: tag.tag, data: tag})
                    }
                })
            })

            let tagIndex = 0
            mappedTagList.sort((a, b) => {
                if (a.label.toUpperCase() < b.label.toUpperCase()) return -1
                if (a.label.toUpperCase() > b.label.toUpperCase()) return 1
                return 0
            }).forEach((tag) => {
                tag.value = tagIndex
                tagIndex++
            })
            setTagList(mappedTagList)
        }
    }, [processDocumentServer])

    const classes = useStyles()

    useEffect(() => {

        const filteredRows = bodyRows
            .filter((option) => {
                if (projectFilter) {
                    return option.projectId === projectFilter
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (processFilter) {
                    return option.processId === processFilter
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (processTypeFilter) {
                    return option.processTypeId === processTypeFilter
                } else {
                    return true
                }
            })
            .filter((option) => {
                if (tagFilter) {
                    let hasTag = false
                    option.tags.forEach((tag) => {
                        if (tag.id === tagFilter) {
                            hasTag = true
                        }
                    })
                    return hasTag
                } else {
                    return true
                }
            })

        setDisplayRows(filteredRows)

    //    task: also filter lists (resetting lists if filtered items don't have selected value)

    }, [projectFilter, processFilter, processTypeFilter, tagFilter, bodyRows])

    const handleFilterProjectSelection = (index) => {
        if (index === -1) {
            setProjectFilter('')
        } else {
            setProjectFilter(projectList[index].data.projectId)
        }
    }

    const handleFilterProcessSelection = (index) => {
        if (index === -1) {
            setProcessFilter('')
        } else {
            setProcessFilter(processList[index].data.processId)
        }
    }

    const handleFilterProcessTypeSelection = (index) => {
        if (index === -1) {
            setProcessTypeFilter('')
        } else {
            setProcessTypeFilter(processTypeList[index].data.processTypeId)
        }
    }

    const handleFilterTagSelection = (index) => {
        if (index === -1) {
            setTagFilter('')
        } else {
            setTagFilter(tagList[index].data.id)
        }
    }

    const handleTableSelection = (docObject) => {
        props.history.push(`/document/view/${docObject.id}`, docObject)
    }

    const handleClickOpenDialog = () => {
        setShowExportDialog(true)
    }

    const handleFetchContacts = async () => {
        const updatedContacts = await hydrateContactServer()
        if (contactServer) {
            return await updatedContacts.map((contact, i) => {
                // debugger
                if (contact.details.given_names) {
                    const givenNames = contact.details.given_names.join(' ')
                    return {
                        label: `${givenNames} ${contact.details.surname}`,
                        value: i,
                        data: contact
                    }
                } else {
                    return {
                        label: undefined,
                        value: undefined,
                    }
                }
            })
        }
        return []
    }

    const handleSendEmail = async (formData) => {
        await sendEmail({body: formData})
    }

    const TopRightElement = (props) => {
        return (
            <TopRightElementWrapper>
                <SVGButton
                    handleClickOut={handleClickOpenDialog}
                    SVGIconIn={PresentToAllIcon}
                />
            </TopRightElementWrapper>
        )
    }

    return (
        <DocumentWrapper className={'DocumentWrapper'}>
            <ContentBanners
                headerTitle={'Document Browser'}
                TopRightElement={{component: TopRightElement}}
            >
            <PageWrapper className={'PageWrapper'}>
                <FilterWrapper className={'FilterWrapper'}>
                    <ShadowBox className={'ShadowBox'} >
                        <FlexRowWrapper divHeight={'100%'} divWidth={'100%'}>
                            <FilterInnerWrapper>
                                <SimpleDropdown
                                    classes={classes}
                                    title={'Project'}
                                    inputArray={projectList}
                                    onSelect={handleFilterProjectSelection}
                                    selectNone={true}
                                    initSelection={projectFilterInitSelection}
                                />
                                <SimpleDropdown
                                    classes={classes}
                                    title={'Process'}
                                    inputArray={processList}
                                    onSelect={handleFilterProcessSelection}
                                    selectNone={true}
                                    initSelection={processFilterInitSelection}
                                />
                                <SimpleDropdown
                                    classes={classes}
                                    title={'Process Type'}
                                    inputArray={processTypeList}
                                    onSelect={handleFilterProcessTypeSelection}
                                    selectNone={true}
                                    initSelection={processTypeFilterInitSelection}
                                />
                                <SimpleDropdown
                                    classes={classes}
                                    title={'Tag'}
                                    inputArray={tagList}
                                    onSelect={handleFilterTagSelection}
                                    selectNone={true}
                                    initSelection={parentDocFilterInitSelection}
                                />
                            </FilterInnerWrapper>
                        </FlexRowWrapper>
                    </ShadowBox>
                </FilterWrapper>
                <ListWrapper className={'ListWrapper'}>
                    <ShadowBox className={'ShadowBox'}>
                        <LoaderWrapper isLoaded={isTableLoaded}>
                            <InnerListWrapper>

                                    <SortableTableList
                                        headCells={headCells}
                                        bodyRows={displayRows}
                                        onSelect={handleTableSelection}
                                    />

                            </InnerListWrapper>
                        </LoaderWrapper>
                    </ShadowBox>
                </ListWrapper>
            </PageWrapper>
            </ContentBanners>
            <div>
                <PdfGeneratorList
                    showDialog={showExportDialog}
                    setShowDialog={setShowExportDialog}
                    listArrayIn={displayRows}
                    fieldFiltersIn={filterFieldList}
                    DisplayListElementIn={PdfListDiv}
                    PdfFrameIn={PdfFrame}
                    PdfListElementIn={PdfListElement}
                    fetchContactsOut={handleFetchContacts}
                    sendEmailOut={handleSendEmail}
                />
            </div>
        </DocumentWrapper>
    )
}

export default withRouter(DocumentList)