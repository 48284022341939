import React, {useEffect, useState, useContext} from 'react';
import styled from "styled-components";
import useSession from "../../library/helpers/hooks/useSession";

const OuterWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
`

const InputField = styled.input`
  height: 22px;
  width: 200px;
  padding: 1px 5px;
  border-radius: 0;
  outline: None;
  margin: 5px 0;
  font-size: 14px;
`

const SubmitButton = styled.button`
  width: 200px;
  height: 25px;
  border-radius: 0;
  margin-top: 5px;
  
  background-color: ${props => props.abled ? '#4367a0' : 'grey'};
  outline: none;
  border: none;
  // border-radius: 3px;

  color: ${props => props.abled ? 'white' : '#f6f6f6'};
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  // ${props => !props.abled && 'font-style: italic'};
  
`

const PasswordWarningDiv = styled.div`
  width: 200px;
  background-color: #db0d0d;
  padding: 10px;
  opacity: ${props => props.authState === 3 ? 1 : 0};

`

const StyledP = styled.p`
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`

const AuthGate = (props) => {

    const {setAuthToken, authEndpoints} = useSession()
    const [checkToken, setCheckToken] = useState(false)
    const [authState, setAuthState] = useState(0)
        // 0: awaiting login, 1: requesting..., 2: all good, 3: wrong password
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const asyncFunc = async () => {
            const refreshToken = window.localStorage.getItem('LEMMMAN_REFRESH_TOKEN')
            if (refreshToken) {
                const authResponse = await authEndpoints.tokenRefresh({refresh: refreshToken})
                if (authResponse[0].ok) {
                    await setAuthToken(authResponse[1])
                    window.localStorage.setItem('LEMMMAN_AUTH_TOKEN', authResponse[1].access)
                    await setAuthState(2)
                }
            }
            setCheckToken(true)
        }
        asyncFunc().then()
    }, [])

    const inputFocusHandler = (event) => {
        setAuthState(0)
    }

    const usernameChangeHandler = (event) => {
        setUsername(event.target.value)
    }

    const passwordChangeHandler = (event) => {
        setPassword(event.target.value)
    }

    const submitHandler = async (event) => {
        event.preventDefault()
        await setAuthState(1)
        const authResponse = await authEndpoints.token({
            username,
            password
        })
        if (authResponse[0].ok) {
            await setAuthToken(authResponse[1])
            window.localStorage.setItem('LEMMMAN_AUTH_TOKEN', authResponse[1].access)
            window.localStorage.setItem('LEMMMAN_REFRESH_TOKEN', authResponse[1].refresh)
            await setAuthState(2)
        } else {
            await setAuthState(3)
        }

    }

    const OuterWrapperClickHandler = (event) => {
        setAuthState(0)
    }


    if (authState === 2) {
        return (
            <>
                {props.children}
            </>
        )
    } else {
        return (
            <OuterWrapper
                onClick={OuterWrapperClickHandler}
            >
                {checkToken &&
                <CenterWrapper>
                    <PasswordWarningDiv authState={authState}>
                        <StyledP>Incorrect username/password.</StyledP>
                    </PasswordWarningDiv>
                    <form onSubmit={submitHandler}>
                        <div>
                            <InputField
                                value={username}
                                onChange={usernameChangeHandler}
                                placeholder={'Username'}
                                autoComplete={'username'}
                                onFocus={inputFocusHandler}
                            />
                        </div>
                        <div>
                            <InputField
                                value={password}
                                onChange={passwordChangeHandler}
                                type={'password'}
                                placeholder={'Password'}
                                autoComplete={'current-password'}
                                onFocus={inputFocusHandler}
                            />
                        </div>
                        <div>
                            {
                                authState === 1 ?
                                    <SubmitButton disabled>Authenticating...</SubmitButton>
                                    :
                                    <SubmitButton abled={true}>Sign In</SubmitButton>
                            }
                        </div>
                    </form>
                </CenterWrapper>
                }

            </OuterWrapper>
        )
    }
}

export default AuthGate