import React, {useState} from "react";
import styled from "styled-components";

const BodyDiv = styled.div`
  
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  
`;

const DataBlockTabWrapper = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  //padding: 8px 8px;
  
  background-color: #c65151;

`

const StyledP = styled.p`
  width: 100%;
  text-align: center;
`

const TabCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.selected ? '#fafafa' : '#8d8d8d'};
  border-right: 0.1px solid #c3c3c3;
  padding-top: 2px;

  p {
    font-size: 18px;
    font-weight: bold;
    cursor: default;
    color: ${props => props.selected ? '#404040' : '#f5f5f5'}
  }

  :last-child {
    border-right: none;
  }
`

const DataBlockContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //padding: 8px 8px;
`

const TabBox = (props) => {

    const {
        tabSide = 'top',
        tabArray = [], // [['name', Component, (options)], ['name', Component, (options)]]
    } = props

    const [selection, setSelection] = useState(0)

    const tabClickHandler = (index) => {
        setSelection(index)
    }

    const Tabs = tabArray.map((tab, i) => {
        return (
            <TabCell
                onClick={() => tabClickHandler(i)}
                selected={selection === i}
                key={`tab${i}`}
            >
                <StyledP>{tab.title}</StyledP>
            </TabCell>
        )
    })

    return (
        <BodyDiv>
            <DataBlockTabWrapper>
                {Tabs}
            </DataBlockTabWrapper>
            <DataBlockContentWrapper>
                {tabArray[selection].component}
            </DataBlockContentWrapper>
        </BodyDiv>
    )

}

export default TabBox