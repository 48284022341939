import React, {useEffect, useRef, useState} from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import styled from "styled-components";
import Draggable from 'react-draggable'
import LaunchIcon from '@material-ui/icons/Launch';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PrintIcon from '@material-ui/icons/Print';
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import SimpleDropdown from "../../dropdowns/SimpleDropdown/SimpleDropdown";
import useFetch from "../../../../appModules/StateBot/library/helpers/hooks/useFetch";
import printJS from "print-js";

import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFWrapper = styled.div`
  height: 100%;
  width: 100%;
    margin: 0;
    position: relative;
  //overflow: hidden;
  
  
  


`

const DocumentWrapper = styled.div`
    margin: 0;
    height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
    position: absolute;
  .react-pdf__Page__textContent {
    display: none;
  }
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  


`

const PageButtonWrapper = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  z-index: 100;
  bottom: 50px;


  div {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fdfdfd;
    border: solid 1px #b7b7b7;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);


    p {
      font-weight: bold;
    }

  }

  //height: 100%;
  //width: 100%;

`

const ScaleButtonWrapper = styled.div`
  //width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  position: absolute;
  z-index: 100;
  top: 50px;
  left: 50px;
  border-radius: 10px;
  overflow: hidden;
  opacity: ${props => props.mouseOver ? .8 : 0};
  transition: opacity .1s;
  :hover {
    opacity: 1;
  }

  button {
    border-bottom: 1px solid #a9a9a9;
    opacity: .8;
    transition: opacity .1s;
    :hover {
      opacity: 1;
    }

    :last-child {
      border-bottom: none;


    }
  }


  //height: 100%;
  //width: 100%;

`

const UtilityButtonWrapper = styled.div`
  //width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  overflow: hidden;
  opacity: ${props => props.mouseOver ? .8 : 0};
  transition: opacity .1s;
  :hover {
    opacity: 1;
  }
  button {
    border-right: 1px solid #a9a9a9;
    opacity: .8;
    transition: opacity .1s;
    :hover {
      opacity: 1;
    }
    :last-child {
      border-right: none;


    }
  }



  position: absolute;
  z-index: 100;
  top: 50px;
  right: 50px;
    

  //height: 100%;
  //width: 100%;

`

const StyledPageButton = styled.button`
  height: 50px;
  width: 50px;
  background-color: #222222;
  border: none;
  outline: none;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  border-radius: 10px;
  opacity: ${props => props.mouseOver ? .8 : 0};
  transition: opacity .1s;
  :hover {
    opacity: 1;
    transition: opacity .1s;
  }
`

const StyledScaleButton = styled.button`
  height: 50px;
  width: 50px;
  background-color: #222222;
  border-radius: 0;
  border: none;
  outline: none;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
`

const StyledUtilityButton = styled.button`
  height: 50px;
  width: 50px;
  background-color: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  border: none;
  outline: none;
  color: #FFFFFF;
  //font-size: 28px;
  //font-weight: bold;
`

const PageWrapper = styled.div`
  //height: 100%;
  //width: 100%;
  //position: relative;
  //padding-bottom: 46px;
  cursor: grab;
`


const AlertButtonsWrapper = styled.div`
width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
display: flex;
  flex-direction: row;
  justify-content: center;

`

const EmailDialogContentWrapper = styled.div`
  width: 500px;
  height: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .MuiFormControl-root {
    width: 100%;
  }
  p {
    margin: 20px 0 0 0;
    font-weight: bold;
  }
`

const ContactDialogContentWrapper = styled.div`
  width: 350px;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .MuiFormControl-root {
    width: 100%;
  }
  p {
    margin: 20px 0 0 0;
    font-weight: bold;
  }
`

const ContactGroupWrapper = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ContactButtonWrapper = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  overflow: hidden;


`

const StyledTextArea = styled.textarea`
  height: 100px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  margin-top: 7px;
`

const PageRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  border-top: 1px #a9a9a9 solid;
  width: 100%;
  p{
    white-space: nowrap;
    
  }
  .MuiFormControl-root {
    width: 100%;
    padding: 0 20px;

  }
`

const initEmailObject = {
    to: '',
    cc: '',
    subject: '',
    message: '',
    pageRangeText: ''
}



const PdfViewerServices = ({
    displayUrl = '',
    exportUrl = null,
    setLoaded = (bool) => {},
    scale = 0.7,
    fetchContactsOut = () => [],
    pages = null
             }) => {

    // todo: need to add authentication to fetch


    const sendEmailRequest = {
        endpoint: 'emailProxyFile',
    }

    const [sendEmail, isFetchingSendEmail] = useFetch(sendEmailRequest)


    const [numPages, setNumPages] = useState(pages);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfScale, setPdfScale] = useState(scale)
    const [mouseOverWrapper, setMouseOverWrapper] = useState(false)
    const [showUtilityDialog, setShowUtilityDialog] = useState(false)
    const [emailObject, setEmailObject] = useState(initEmailObject)
    const [contactList, setContactList] = useState([])
    const [showChooseContact, setShowChooseContact] = useState(false)
    const [selectContactIndex, setSelectContactIndex] = useState(false)
    const [emailNumPages, setEmailNumPages] = useState('')
    const [emailPageRange, setEmailPageRange] = useState('')
    const contactSelectType = useRef('')

    useEffect(() => {
        if (numPages <= 15) {
            setEmailPageRange(`1 - ${numPages}`)
            setEmailNumPages(`${numPages} pages`)
        } else {
            setEmailPageRange(`1 - 15`)
            setEmailNumPages(`15 pages`)
        }
    }, [numPages])

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setLoaded(true)
    }

    const handleOverWrapper = () => {
        setMouseOverWrapper(true)
    }

    const handleOutWrapper = () => {
        setMouseOverWrapper(false)
    }

    const handlePreviousClick = (event) => {
        event.preventDefault()
        setPageNumber(prev => {
            if (prev > 1) {
                return prev - 1
            } else {
                return prev
            }
        })
    }

    const handleNextClick = (event) => {
        event.preventDefault()
        setPageNumber(prev => {
            if (prev < numPages) {
                return prev + 1
            } else {
                return prev
            }
        })
    }

    const handleZoomOutClick = (event) => {
        event.preventDefault()
        setPdfScale(prev => prev * 0.75)
    }

    const handleZoomInClick = (event) => {
        event.preventDefault()
        setPdfScale(prev => prev * 1.25)
    }

    const handleOpenClick = () => {
        window.open(exportUrl)

    }

    const handleSendClick = () => {
        setShowUtilityDialog(true)

    }

    const handlePrintClick = () => {
        printJS(exportUrl)

    }

    const handleOpenSelectContact = async () => {
        const updatedContacts = await fetchContactsOut()
        const filteredUpdatedContacts = updatedContacts.filter((contact) => {
            const returnSet = contact.data.contactpoint_set.filter((contactPoint) => {
                return contactPoint.type === 'EMAIL'
            })
            return returnSet.length > 0

        }).map((contact, i) => {
            return {
                label: contact.label,
                value: i,
                data: {
                    id: contact.data.id,
                    email: contact.data.contactpoint_set.filter((contactPoint) => {
                        return contactPoint.type === 'EMAIL'
                    })[0].contact_point
                }
            }
        })
        await setContactList(filteredUpdatedContacts)
        await setShowChooseContact(true)
    }

    const handleClickSelectToContact = async (event) => {
        contactSelectType.current = 'to'
        await handleOpenSelectContact()
    }

    const handleClickSelectCcContact = async (event) => {
        contactSelectType.current = 'cc'
        await handleOpenSelectContact()
    }

    const handleCloseContactDialog = (event) => {
        setShowChooseContact(false)
    }

    const handleSelectContactDropdown = (index) => {
        setSelectContactIndex(index)
    }

    const handleClickSelectContactDialog = () => {
        if (selectContactIndex !== false) {
            setEmailObject(prev => {
                return {
                    ...prev,
                    [contactSelectType.current]: contactList[selectContactIndex].data.email
                }
            })
            setShowChooseContact(false)
        }
    }

    const handleUpdateEmailPageNumber = (event) => {
        const pageRangeText = event.target.value
        let pageCount = 0
        let valid = true
        const pageNumberArray = pageRangeText.split(',')
        pageNumberArray.forEach((pageNumberBlock) => {
            const pageNumberBlockArray = pageNumberBlock.split('-')
            if (pageNumberBlockArray.length === 0) {
                valid = false
            } else if (pageNumberBlockArray.length === 1) {
                if (pageNumberBlockArray[0].trim()) {
                    if (parseInt(pageNumberBlock.trim()) <= numPages) {
                        pageCount = pageCount + 1
                    } else {
                        valid = false
                    }
                }
            } else if (pageNumberBlockArray.length === 2) {
                if (parseInt(pageNumberBlockArray[1].trim()) <= numPages && parseInt(pageNumberBlockArray[0].trim()) < parseInt(pageNumberBlockArray[1].trim())) {
                    const pageBlockCount = +pageNumberBlockArray[1].trim() - parseInt(pageNumberBlockArray[0].trim())
                    pageCount = pageCount + pageBlockCount + 1
                } else {
                    valid = false
                }
            } else if (pageNumberBlockArray.length > 2) {
                valid = false
            }
        })
        if (valid && pageCount < 16) {
            setEmailNumPages(`${pageCount} pages`)
        } else {
            setEmailNumPages(`Invalid`)
        }
        setEmailPageRange(event.target.value)
    }

    const handleChangeEmailDialog = (event, key) => {
        setEmailObject(prev => {
            return {
                ...prev,
                [key]: event.target.value
            }
        })
        setShowChooseContact(false)
    }

    const handleSendEmailDialog = async () => {
        await sendEmail({body: JSON.stringify({...emailObject, page_range_text: emailPageRange, proxy_file: exportUrl})})
        setEmailObject(initEmailObject)
        setShowUtilityDialog(false)
    }

    const handleCloseEmailDialog = (event) => {
        setEmailObject(initEmailObject)
        setShowUtilityDialog(false)
    }

    return (
        <PDFWrapper
            className={'PDFWrapper'}
            onMouseOver={handleOverWrapper}
            onMouseOut={handleOutWrapper}
        >

            <DocumentWrapper className={'DocumentWrapper'}>

                <Document
                    className={'Document'}
                    file={displayUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onItemClick={({ pageNumber }) => {}}
                    noData={'Loading PDF...'}
                >
                    <Draggable
                        bounds={{ top: -150*(pdfScale*pdfScale), left: -110*(pdfScale*pdfScale), right: 110*(pdfScale*pdfScale), bottom: 150*(pdfScale*pdfScale) }}

                    >
                        {/*task: fix this bonds system for various resolutions ratios*/}
                    <PageWrapper className={'PageWrapper'}>
                        <Page
                            className={'Page'}
                            pageNumber={pageNumber}
                            scale={pdfScale}
                        />
                    </PageWrapper>
                        {/*</div>*/}
                    </Draggable>
                </Document>
            </DocumentWrapper>
            <ScaleButtonWrapper className={'ScaleButtonWrapper'} mouseOver={mouseOverWrapper}>
                <StyledScaleButton onClick={handleZoomInClick}>{'+'}</StyledScaleButton>
                <StyledScaleButton onClick={handleZoomOutClick}>{'-'}</StyledScaleButton>

            </ScaleButtonWrapper>
            <UtilityButtonWrapper className={UtilityButtonWrapper} mouseOver={mouseOverWrapper}>
                <StyledUtilityButton onClick={handleOpenClick}><LaunchIcon /></StyledUtilityButton>
                <StyledUtilityButton onClick={handleSendClick}><MailOutlineIcon /></StyledUtilityButton>
                <StyledUtilityButton onClick={handlePrintClick}><PrintIcon /></StyledUtilityButton>
            </UtilityButtonWrapper>
            <PageButtonWrapper className={PageButtonWrapper}>
                <StyledPageButton onClick={handlePreviousClick} mouseOver={mouseOverWrapper}>{'<<'}</StyledPageButton>
                <div>
                    <p>{pageNumber} of {numPages}</p>
                </div>
                <StyledPageButton onClick={handleNextClick} mouseOver={mouseOverWrapper}>{'>>'}</StyledPageButton>
            </PageButtonWrapper>
            <Dialog
                open={showUtilityDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CenteredDialogTitleWrapper id="form-dialog-title" >EMAIL DOCUMENT</CenteredDialogTitleWrapper>
                <DialogContent>
                    <EmailDialogContentWrapper>

                        <p>To:</p>
                        <ContactGroupWrapper>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={emailObject.to}
                                onChange={(event) => handleChangeEmailDialog(event, 'to')}
                            />
                            <ContactButtonWrapper
                                onClick={handleClickSelectToContact}
                            >
                                <PersonIcon/>
                            </ContactButtonWrapper>
                        </ContactGroupWrapper>
                        <p>CC:</p>
                        <ContactGroupWrapper>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={emailObject.cc}
                                onChange={(event) => handleChangeEmailDialog(event, 'cc')}
                            />
                            <ContactButtonWrapper
                                onClick={handleClickSelectCcContact}
                            >
                                <PersonIcon/>
                            </ContactButtonWrapper>
                        </ContactGroupWrapper>
                        <p>Subject:</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={emailObject.subject}
                            onChange={(event) => handleChangeEmailDialog(event, 'subject')}
                        />
                        <p>Message:</p>
                        <StyledTextArea
                            value={emailObject.message}
                            onChange={(event) => handleChangeEmailDialog(event, 'message')}

                        />
                        <PageRangeWrapper>
                            <p>Page Range:</p>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={emailPageRange}
                                onChange={handleUpdateEmailPageNumber}
                            />
                            <p>({emailNumPages})</p>
                        </PageRangeWrapper>

                    </EmailDialogContentWrapper>
                </DialogContent>
                <AlertButtonsWrapper>
                    <Button onClick={handleCloseEmailDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSendEmailDialog} color="primary" autoFocus>
                        Send
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
            <Dialog
                open={showChooseContact}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CenteredDialogTitleWrapper id="form-dialog-title" >SELECT CONTACT</CenteredDialogTitleWrapper>
                <DialogContent>
                <ContactDialogContentWrapper>
                    <SimpleDropdown
                        inputArray={contactList}
                        onSelect ={handleSelectContactDropdown}
                    />
                </ContactDialogContentWrapper>
                </DialogContent>
                <AlertButtonsWrapper>
                    <Button onClick={handleCloseContactDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSelectContactDialog} color="primary" autoFocus>
                        Select
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        </PDFWrapper>
    );
}

export default React.memo(PdfViewerServices)