import React from "react";
import styled from "styled-components";


const AddButtonFloating = styled.div`
display: flex;
justify-content: center;
align-items: center;
    height: 40px;
    width: 40px;
    position: absolute;
    background-color: #FFFFFF;
    bottom: 25px;
    right: 30px;
    border-radius: 50% 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    cursor: default;
    
    :hover {
    background-color: #bababa;
    p {
    color: #FFFFFF;
    }
}
  svg {
    height: 35px;
    width: 35px;
  }
`;

export default (props) => {
    return (
        <AddButtonFloating onClick={props.onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#606060" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
        </AddButtonFloating>
    )
}