import React, {useEffect, useRef, useState} from "react";
import {withRouter} from "react-router";
import styled from "styled-components";
import useRESTCRUD from "../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import BackButton from "../../templates/buttons/BackButton";
import TextButton from "../../templates/buttons/TextButton";
import ContentBanners from "../../frames/content/ContentBanners";
import TextArea from 'react-expanding-textarea'

import FlexRowWrapper from "../../templates/wrappers/FlexRowWrapper";
import ShadowBox from "../../templates/boxes/ShadowBox";
import CircleProgress from "../../templates/progress/CircleProgress/CircleProgress";
import {
    mapTaskGroupProcessServerToTaskGroupProcessObject,
    mapTaskItemObjectToTaskItemServer
} from "../../../library/helpers/functions/dataMaps";
import TabBox from "../../templates/boxes/TabBox/TabBox";
import LoaderWrapper from "../../templates/wrappers/LoaderWrapper";
import DocRefItem from "./components/DocRefItem";
import EditTaskForm from "./components/EditTaskForm";
import DraggableCheckboxList from "../../templates/checkboxes/DraggableCheckboxList/DraggableCheckboxList";
import ConfirmDeleteDialog from "../../templates/dialogs/ConfirmDelete";


const TaskListWrapper = styled.div`
  height: 100%;
  width: 60%;
  padding: 20px 20px 20px 20px;
`

const TaskListInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const TaskListHeaderWrapper = styled.div`
  //height: 100px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px #dbdbdb solid;
`


const TodoHeaderCheckbox = styled.div`
  padding: 10px;
  height: 45px;
  width: 45px;
  margin-right: 10px;
`

const TodoHeaderInput = styled(TextArea)`
  width: calc(100% - 80px);
  min-height: 30px;
    background: none;
  border: none;
  outline: none;
  font-size: 20px;
  position: relative;
  top: 2px;
  resize: none;
`

const TaskListItemsOuterWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const TaskListItemsWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`

const TaskListItemsInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const TaskDataWrapper = styled.div`
  height: 100%;
  width: 40%;
  padding: 20px 20px 20px 0;
  display: flex;
  flex-direction: column;

`

const TaskDataUpperWrapper = styled.div`
  width: 100%;
`

const TaskDataLowerWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 20px;
`

const DataBlockUpperInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  //align-items: ;
  padding: 5px 30px 5px 30px;
  //background-color: #c65151;
`

const StyledH5 = styled.h5`
  font-size: 20px;
  font-weight: bold;
  padding: 3px;
`

const StyledH3 = styled.h3`
  font-size: 28px;
  text-align: center;
`

const StyledP = styled.p`
  font-size: 20px;
  padding: 3px;
`

const DataBlockUpperInnerUpperWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const DataBlockUpperInnerLowerWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextPairWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;

  h5 {
    //width: 10%;
    width: 180px;
    text-align: right;
    padding-right: 10px;
  }
  
  p {
    width: 85%;
  }
  //background-color: #c65151;
`


const UpperDataBlockLineSpacer = styled.div`
  height: 1px;
  background-color: #a9a9a9;
  width: 75%;
`
const DataBlockLowerInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  position: relative;

  //background-color: #c65151;
`


const InnerWrapper = styled.div`
  position: absolute;
  padding: 20px;
  //display: flex;
  //flex-direction: column;
  //justify-content: start;
  //align-items: start;
  height: 100%;
  width: 100%;
    //overflow-y: scroll;
  z-index: 100;
  

  //background-color: #c65151;
`

const StyledTextArea = styled.textarea`
  height: 100%;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  padding: 10px;
`

const ListFrameWrapper = styled.div`
  width: 100%;
`


const Task = (props) => {

    const [taskProcessServer, hydrateTaskProcessServer, setTaskProcessServer, isFetchingTaskProcessServer] = useRESTCRUD({
        endpoint: 'taskProcess',
        instance: props.location.state.id
    })

    const [taskGroupServer, hydrateTaskGroupServer, setTaskGroupServer, isFetchingTaskGroupServer] = useRESTCRUD({
        endpoint: 'taskGroup',
        instance: props.location.state.taskGroupId,
        options: {
            writeOnly: true
        }
    })

    const [taskItemServer, hydrateTaskItemServer, setTaskItemServer, isFetchingItemServer] = useRESTCRUD({
        endpoint: 'taskItem',
        options: {
            writeOnly: true
        }
    })

    const [taskGroupRefProcDocServer, hydrateTaskGroupRefProcDocServer, setTaskGroupRefProcDocServer, isFetchingTaskGroupRefProcDocServer] = useRESTCRUD({
        endpoint: 'tasksGroupRefDocument',
        filterObject: {
            task_group: props.location.state.taskGroupId,
        },
    })

    const [processTask, setProcessTask] = useState({
        id: props.location.state.taskGroupId,
        project: props.location.state.project,
        process: props.location.state.process,
        processId: props.location.state.processId,
        processType: props.location.state.processType,
        manager: props.location.state.manager,
        managerId: props.location.state.managerId,
        date: props.location.state.date
    })
    const [taskHeader, setTaskHeader] = useState(props.location.state.headerString)
    const [taskItemList, setTaskItemList] = useState(props.location.state.checkboxArray)
    const [taskNote, setTaskNote] = useState(props.location.state.note)
    const [docReferences, setDocReferences] = useState({})
    const [percentageComplete, setPercentageComplete] = useState(0)
    const [showEditTask, setShowEditTask] = useState(false)
    const [showDeleteTodoItemDialog, setShowDeleteTodoItemDialog] = useState(false)
    const [removeItemUuid, setRemoveItemUuid] = useState("")


    const noteFetchCache = useRef('')

    useEffect(() => {
        if (taskProcessServer) {
            const newTaskGroupState = mapTaskGroupProcessServerToTaskGroupProcessObject(taskProcessServer)
            setTaskHeader(newTaskGroupState.headerString)

            setTaskItemList(newTaskGroupState.checkboxArray.sort((a, b) => {
                if (a.taskString.toUpperCase() < b.taskString.toUpperCase()) return -1
                if (a.taskString.toUpperCase() > b.taskString.toUpperCase()) return 1
                return 0
            }))
            setTaskNote(newTaskGroupState.note)
            setProcessTask(newTaskGroupState)
        }
    }, [taskProcessServer])

    useEffect(() => {

        let completedCount = 0
        taskItemList.forEach((taskItem) => {
            if (taskItem.isCheckedBool) {
                completedCount++
            }
        })
        setPercentageComplete((completedCount / taskItemList.length) * 100)

    }, [taskItemList])

    useEffect(() => {

        if (taskGroupRefProcDocServer) {

            const refList = {} // this is the master object of this block
            // here build a hash table of all the documents
            taskGroupRefProcDocServer.task_group.forEach((taskGroupRef) => {
                refList[taskGroupRef.document_process.id] = {
                    processDoc: taskGroupRef.document_process,
                    document: taskGroupRef.document_process.document,
                    taskItems: []
                }
            })
            //
            taskGroupRefProcDocServer.task_items.forEach((taskItem) => {
                if (taskItem) {
                    taskItem.forEach((taskItemItem) => {
                        if (taskItemItem.document_process.id in refList) {
                            refList[taskItemItem.document_process.id].taskItems.push()
                        } else {
                            refList[taskItemItem.document_process.id] = {
                                processDoc: taskItemItem.document_process,
                                document: taskItemItem.document_process.document,
                                taskItems: []
                            }
                        }
                    })

                }
            })

            // needs to also get the todoItem documents... now just getting group docs

            // three objects:
            // refList => emerging hashTable => already has all the documents
            // taskGroupRefProcDocServer => server taskGroupsRefProcDoc // has empty taskItems if no doc relevence
            // taskItemList => detail of taskItems

            // here fill out the taskItems

            Object.keys(refList).forEach((refListKey) => {
                // refListKey is the hash key of the processDoc
                taskGroupRefProcDocServer.task_items.forEach((taskItemRefProcDocList) => {
                    if (taskItemRefProcDocList) {
                        taskItemRefProcDocList.forEach((taskItemRefProcDoc) => {
                            if (taskItemRefProcDoc.document_process.id === refListKey) {
                                taskItemList.forEach((taskItem) => {
                                    if (taskItem.id === taskItemRefProcDoc.task_item) {
                                        refList[refListKey].taskItems.push(taskItem)
                                    }
                                })
                            }
                        })
                    }
                })
            })
            setDocReferences(refList)
        }
    }, [taskGroupRefProcDocServer, taskItemList])

    useEffect(() => {
        setInterval(async () => {
            if (noteFetchCache.current) {
                const cachedNote = noteFetchCache.current
                noteFetchCache.current = ''
                await setTaskGroupServer({note: cachedNote})
            }
        }, 1000)
    }, [])

    const returnToList = () => {
        props.history.push('/task/list/')
    };

    const handleChangeHeaderInput = (event) => {
        setTaskHeader(event.target.value)
    }

    const handleUpdateHeaderInputBlur = () => {
        setTaskGroupServer({name: taskHeader})
    }

    const handleUpdateHeaderInputEnter = (event) => {
        if ('!isUpdated.current') {
            if (event.keyCode === 13) {
                event.preventDefault()
                setTaskGroupServer({name: taskHeader})
            }
        }
    }

    const handleAddTaskItem = async (newTaskItem) => {
        const newTaskBody = {
            ...mapTaskItemObjectToTaskItemServer(newTaskItem),
            task_group: props.location.state.taskGroupId
        }
        await setTaskItemServer(newTaskBody)
        await hydrateTaskProcessServer()
    }

    const handleSetTaskItem = async (updatedTaskItem, index) => {
        await setTaskItemServer(mapTaskItemObjectToTaskItemServer(updatedTaskItem), updatedTaskItem.id)
        await hydrateTaskProcessServer()
    }
    const handleChangeNote = (event) => {
        setTaskNote(event.target.value)
        noteFetchCache.current = event.target.value
    }

    const handleOpenEditTaskForm = () => {
        setShowEditTask(true)
    }

    const handleUpdateTaskForm = async (uuid, taskObj) => {
        setShowEditTask(false)
        let manager
        if (taskObj.managerId) {
            manager = taskObj.managerId
        } else {
            manager = null
        }
        await setTaskGroupServer({manager: manager, date: taskObj.date})
        await setTaskProcessServer({process: taskObj.processId})
        await hydrateTaskProcessServer()
    }

    const handleDeleteTaskForm = async (index) => {
        const instanceUUID = processTask.id
        await setTaskProcessServer(null, instanceUUID)
        props.history.push(`/task/list/`)
        setShowEditTask(false)
    }

    const handleCloseEditTaskForm = () => {
        setShowEditTask(false)
    }


    const DocReferenceList = Object.keys(docReferences).map((docRefKey, i) => {
        return (
            <DocRefItem
                key={`${docRefKey}_${i}`}
                docRefObj={docReferences[docRefKey]}
            />
        )
    })

    const ContactsTab = (
        <DataBlockLowerInnerWrapper>

        </DataBlockLowerInnerWrapper>
    )

    const DocumentTab = (
        <DataBlockLowerInnerWrapper>
            <LoaderWrapper
                isLoaded={true}
            >
                <InnerWrapper>
                    {DocReferenceList}
                </InnerWrapper>
            </LoaderWrapper>
        </DataBlockLowerInnerWrapper>
    )

    const NoteTab = (
        <DataBlockLowerInnerWrapper>
            <StyledTextArea value={taskNote} onChange={handleChangeNote}/>
        </DataBlockLowerInnerWrapper>
    )

    const tabArray = [
        {title: 'Contacts', component: ContactsTab},
        {title: 'Documents', component: DocumentTab},
        {title: 'Note', component: NoteTab}

    ]

    const DraggableListFrame = (props) => {
        return (
            <ListFrameWrapper>
                {props.children}
            </ListFrameWrapper>
        )
    }

    const DraggableListItem = (props) => {
        return (
            <ListFrameWrapper/>
        )
    }

    const handleDeleteTodoItem = async (uuid) => {
        await setRemoveItemUuid(uuid)
        await setShowDeleteTodoItemDialog(true)

    }

    const handleConfirmDeleteTodoItem = async () => {
        await setShowDeleteTodoItemDialog(false)
        await setTaskItemServer(null, removeItemUuid)
        await hydrateTaskProcessServer()
    }

    const handleCancelDeleteTodoItem = async () => {
        await setShowDeleteTodoItemDialog(false)
    }

    return (
        <>
            <ContentBanners
                headerTitle={'Action Viewer'}
                TopLeftElement={{component: BackButton, props: {
                        label: 'Task List',
                        output: returnToList
                    }}}
                TopRightElement={{component: TextButton, props: {
                        label: 'Edit',
                        output: handleOpenEditTaskForm
                    }}}
                BottomLeftElement={{component: TextButton, props: {
                        label: 'Delete'
                    }}}
                BottomCenterElement={{component: TextButton, props: {
                        label: 'Share'
                    }}}
                BottomRightElement={{component: TextButton, props: {
                        label: 'Print'
                    }}}
                topLeftOut
                topRightOut
                bottomLeftOut
                bottomCenterOut
                bottomRightOut
            >
                <FlexRowWrapper divHeight={'100%'} divWidth={'100%'} flexJustifyContent={'space-between'}>
                    <TaskListWrapper>
                        <ShadowBox>
                            <TaskListInnerWrapper>
                                <TaskListHeaderWrapper>
                                    <TodoHeaderCheckbox>
                                        <CircleProgress
                                            className={'CircleProgress'}
                                            percent={percentageComplete}
                                        />
                                    </TodoHeaderCheckbox>
                                    <TodoHeaderInput
                                        value={taskHeader}
                                        onChange={handleChangeHeaderInput}
                                        onBlur={handleUpdateHeaderInputBlur}
                                        onKeyDown={handleUpdateHeaderInputEnter}
                                    />
                                </TaskListHeaderWrapper>
                                <TaskListItemsOuterWrapper>
                                    <TaskListItemsWrapper>
                                        <TaskListItemsInnerWrapper>
                                            {/*<SimpleCheckboxList*/}
                                            {/*    checkboxItemArray={taskItemList}*/}
                                            {/*    onAddItemOut={handleAddTaskItem}*/}
                                            {/*    onSetItemOut={handleSetTaskItem}*/}
                                            {/*    onDeleteItemOut={handleDeleteTaskItem}*/}
                                            {/*    padding={'10px 20px 75px 20px'}*/}
                                            {/*/>*/}
                                            <DraggableCheckboxList
                                                checkboxItemArray={taskItemList}
                                                ListItemComponent={DraggableListItem}
                                                ListFrameComponent={DraggableListFrame}
                                                    onAddItemOut={handleAddTaskItem}
                                                    onSetItemOut={handleSetTaskItem}
                                                    onDeleteItemOut={handleDeleteTodoItem}
                                            />
                                        </TaskListItemsInnerWrapper>

                                    </TaskListItemsWrapper>
                                </TaskListItemsOuterWrapper>

                            </TaskListInnerWrapper>
                        </ShadowBox>
                    </TaskListWrapper>
                    <TaskDataWrapper>
                        <TaskDataUpperWrapper>
                            <ShadowBox>
                                <DataBlockUpperInnerWrapper>
                                    <DataBlockUpperInnerUpperWrapper>
                                        <TextPairWrapper>
                                            <StyledH5>Project: </StyledH5>
                                            <StyledP>{processTask.project}</StyledP>
                                        </TextPairWrapper>
                                        <TextPairWrapper>
                                            <StyledH5>Process: </StyledH5>
                                            <StyledP>{processTask.process}</StyledP>
                                        </TextPairWrapper>
                                        <TextPairWrapper>
                                            <StyledH5>Process Type: </StyledH5>
                                            <StyledP>{processTask.processType}</StyledP>
                                        </TextPairWrapper>
                                    </DataBlockUpperInnerUpperWrapper>
                                    <UpperDataBlockLineSpacer/>
                                    <DataBlockUpperInnerLowerWrapper>
                                        {/*<TextPairWrapper>*/}
                                        {/*    <StyledH5>Task Manager: </StyledH5>*/}
                                        {/*    <StyledP>{processTask.manager}</StyledP>*/}
                                        {/*</TextPairWrapper>*/}
                                        <TextPairWrapper>
                                            <StyledH5>Date Due: </StyledH5>
                                            <StyledP>{processTask.date}</StyledP>
                                        </TextPairWrapper>
                                    </DataBlockUpperInnerLowerWrapper>
                                </DataBlockUpperInnerWrapper>
                            </ShadowBox>
                        </TaskDataUpperWrapper>
                        <TaskDataLowerWrapper>
                            <ShadowBox>
                                <TabBox tabArray={tabArray}/>
                            </ShadowBox>
                        </TaskDataLowerWrapper>
                    </TaskDataWrapper>
                </FlexRowWrapper>
            </ContentBanners>
            <EditTaskForm
                showDialogIn={showEditTask}
                taskFormObjectIn={processTask}
                updateTaskOut={handleUpdateTaskForm}
                deleteTaskOut={handleDeleteTaskForm}
                closeDialogOut={handleCloseEditTaskForm}
            />
            <ConfirmDeleteDialog
                dialogText={"Delete Item?"}
                onCancel={handleCancelDeleteTodoItem}
                onDelete={handleConfirmDeleteTodoItem}
                showDialog={showDeleteTodoItemDialog}
            />


        </>

    )
}

export default withRouter(Task)