import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {withRouter} from "react-router";
import LoaderWrapper from "../../../templates/wrappers/LoaderWrapper";
import Document from "./Document";
import useRESTCRUD from "../../../../appModules/StateBot/library/helpers/hooks/useRESTCRUD";
import {mapProcessDocumentServerToProcessDocumentObject} from "../../../../library/helpers/functions/dataMaps";

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const DocumentLoader = (props) => {

    let tempState = null

    if (props?.location?.state) {
        tempState = props.location.state
    }

    const [procDocState, setProcDocState] = useState(tempState)

    const [processDocumentServer, hydrateProcessDocumentServer, setProcessDocumentServer, isProcessDocumentServerFetching] = useRESTCRUD({
        endpoint: 'processDocument',
        instance: props.match.params.uuid,
        options: {
            initFetch: false
        }
    })

    useEffect(() => {
        if (!procDocState) {
            hydrateProcessDocumentServer()
        }
    }, [procDocState, hydrateProcessDocumentServer])

    useEffect(() => {
        if (processDocumentServer) {
            setProcDocState(mapProcessDocumentServerToProcessDocumentObject(processDocumentServer))
        }

    }, [processDocumentServer])


    return (
        <LoaderWrapper
            isLoaded={procDocState}
        >
            <Document
                procDoc={procDocState}
            />
        </LoaderWrapper>
    )

};

export default withRouter(DocumentLoader);