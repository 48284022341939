


const fetchAsync = async ({apiBaseUrl, requestUrl, method, headers, body, mode = {}}, loadFunc = null) => {
    try {
        const response = await fetch(`${apiBaseUrl}${requestUrl}`, {method, headers, body});
        const data = await response.json();
        return [response, data]
    }
    catch (error){
        return ['error', error]
    }

};

export default fetchAsync

