import React, {useEffect} from "react";
import {Route, withRouter} from "react-router-dom";
import AppFrame from "../frames/AppFrame";


const Lemmman = (props) => {

    useEffect(() => {
        props.history.push('/')
    }, []);

    return (
        <Route
            path={'/'}
            render={(props) => <AppFrame {...props} />}
        />
    )
};

export default withRouter(Lemmman);