import React, {useState, useEffect, useRef} from 'react'
import styled from "styled-components"
import {Button, Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import SimpleDropdown from "../../dropdowns/SimpleDropdown/SimpleDropdown";


const AlertButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  button {
    width: 50px;
  }
`

const CenteredDialogTitleWrapper = styled(DialogTitle)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const EmailDialogContentWrapper = styled.div`
  width: 500px;
  height: 100%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .MuiFormControl-root {
    width: 100%;
  }
  p {
    margin: 20px 0 0 0;
    font-weight: bold;
  }
`

const ContactGroupWrapper = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ContactButtonWrapper = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  border-radius: 10px;
  overflow: hidden;


`

const StyledTextArea = styled.textarea`
  height: 100px;
  width: 100%;
  resize: none;
  outline: none;
  border: none;
  margin-top: 7px;
`

const ContactDialogContentWrapper = styled.div`
  width: 350px;
  height: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .MuiFormControl-root {
    width: 100%;
  }
  p {
    margin: 20px 0 0 0;
    font-weight: bold;
  }
`

const initEmailObject = {
    to: '',
    cc: '',
    subject: '',
    message: '',
}

const EmailFileBlob = ({
        blobFileIn,
        showDialogIn = false,
        setShowDialogOut = () => {},
        fetchContactsOut = () => [],
        sendEmailOut = () => {},
    }) => {

    const [emailObject, setEmailObject] = useState(initEmailObject)
    const [contactList, setContactList] = useState([])
    const [showChooseContact, setShowChooseContact] = useState(false)
    const [selectContactIndex, setSelectContactIndex] = useState(false)
    const contactSelectType = useRef('')

    useEffect(() => {
    }, [emailObject])


    const handleOpenSelectContact = async () => {
        const updatedContacts = await fetchContactsOut()
        const filteredUpdatedContacts = updatedContacts.filter((contact) => {
            const returnSet = contact.data.contactpoint_set.filter((contactPoint) => {
                return contactPoint.type === 'EMAIL'
            })
            return returnSet.length > 0

        }).map((contact, i) => {
            return {
                label: contact.label,
                value: i,
                data: {
                    id: contact.data.id,
                    email: contact.data.contactpoint_set.filter((contactPoint) => {
                        return contactPoint.type === 'EMAIL'
                    })[0].contact_point
                }
            }
        })
        await setContactList(filteredUpdatedContacts)
        await setShowChooseContact(true)
    }

    const handleClickSelectToContact = async (event) => {
        contactSelectType.current = 'to'
        await handleOpenSelectContact()
    }

    const handleClickSelectCcContact = async (event) => {
        contactSelectType.current = 'cc'
        await handleOpenSelectContact()
    }

    const handleCloseContactDialog = (event) => {
        setShowChooseContact(false)
    }

    const handleSelectContactDropdown = (index) => {
        setSelectContactIndex(index)
    }

    const handleClickSelectContactDialog = () => {
        if (selectContactIndex !== false) {
            setEmailObject(prev => {
                return {
                    ...prev,
                    [contactSelectType.current]: contactList[selectContactIndex].data.email
                }
            })
            setSelectContactIndex(false)
            setShowChooseContact(false)
        }
    }

    const handleChangeEmailDialog = (event, key) => {
        setEmailObject(prev => {
            return {
                ...prev,
                [key]: event.target.value
            }
        })
        setShowChooseContact(false)
    }

    const handleSendEmailDialog = async () => {
        const formData = new FormData()
        formData.append("to", emailObject.to)
        formData.append("cc", emailObject.cc)
        formData.append("subject", emailObject.subject)
        formData.append("message", emailObject.message)
        formData.append("blob_file", blobFileIn, 'lemmman.pdf')
        await sendEmailOut(formData)
        setEmailObject(initEmailObject)
        setShowDialogOut(false)
    }

    const handleCloseEmailDialog = (event) => {
        setEmailObject(initEmailObject)
        setShowDialogOut(false)
    }

    return (
        <>
            <Dialog
                open={showDialogIn}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CenteredDialogTitleWrapper id="form-dialog-title" >EMAIL DOCUMENT</CenteredDialogTitleWrapper>
                <DialogContent>
                    <EmailDialogContentWrapper>

                        <p>To:</p>
                        <ContactGroupWrapper>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={emailObject.to}
                                onChange={(event) => handleChangeEmailDialog(event, 'to')}
                            />
                            <ContactButtonWrapper
                                onClick={handleClickSelectToContact}
                            >
                                <PersonIcon/>
                            </ContactButtonWrapper>
                        </ContactGroupWrapper>
                        <p>CC:</p>
                        <ContactGroupWrapper>
                            <TextField
                                inputProps={{ 'aria-label': 'naked' }}
                                value={emailObject.cc}
                                onChange={(event) => handleChangeEmailDialog(event, 'cc')}
                            />
                            <ContactButtonWrapper
                                onClick={handleClickSelectCcContact}
                            >
                                <PersonIcon/>
                            </ContactButtonWrapper>
                        </ContactGroupWrapper>
                        <p>Subject:</p>
                        <TextField
                            inputProps={{ 'aria-label': 'naked' }}
                            value={emailObject.subject}
                            onChange={(event) => handleChangeEmailDialog(event, 'subject')}
                        />
                        <p>Message:</p>
                        <StyledTextArea
                            value={emailObject.message}
                            onChange={(event) => handleChangeEmailDialog(event, 'message')}

                        />

                    </EmailDialogContentWrapper>
                </DialogContent>
                <AlertButtonsWrapper>
                    <Button onClick={handleCloseEmailDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSendEmailDialog} color="primary" autoFocus>
                        Send
                    </Button>
                </AlertButtonsWrapper>
            </Dialog>
        <Dialog
            open={showChooseContact}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <CenteredDialogTitleWrapper id="form-dialog-title" >SELECT CONTACT</CenteredDialogTitleWrapper>
            <DialogContent>
                <ContactDialogContentWrapper>
                    <SimpleDropdown
                        inputArray={contactList}
                        onSelect ={handleSelectContactDropdown}
                    />
                </ContactDialogContentWrapper>
            </DialogContent>
            <AlertButtonsWrapper>
                <Button onClick={handleCloseContactDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleClickSelectContactDialog} color="primary" autoFocus>
                    Select
                </Button>
            </AlertButtonsWrapper>
        </Dialog>
        </>
    )

}

export default EmailFileBlob