import React from "react";
import styled from "styled-components";

import colors from "../../../library/objects/theme/definitions/colors";
import {Route} from "react-router-dom";
import Dashboard from "../../modules/Dashboard/Dashboard";
import DocumentList from "../../modules/Document/DocumentList";
import Document from "../../modules/Document/components/Document";
import ContactList from "../../modules/Contact/ContactList";
import Contact from "../../modules/Contact/ContactFeature";
import TaskList from "../../modules/Task/TaskList";
import Task from "../../modules/Task/Task";
import ProjectList from "../../modules/Project/ProjectList";
import Process from "../../modules/Process/Process";
import ProcessList from "../../modules/Process/ProcessList";
import Project from "../../modules/Project/Project";
import DocumentLoader from "../../modules/Document/components/DocumentLoader";


const BodyDiv = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: ${colors.mainPane[1].frames[1].contentBackground[0]};
`;

const ContentRoutes = (props) => {

    return (
        <BodyDiv>
            <Route
                exact path={'/'}
                component={(props) => <Dashboard {...props} />}
            />
            <Route
                exact path={'/document/list/'}
                component={(props) => <DocumentList {...props} />}
            />
            <Route
                exact path={'/document/view/:uuid'}
                component={(props) => <DocumentLoader {...props} />}
            />
            <Route
                exact path={'/contact/list/'}
                component={(props) => <ContactList {...props} />}
            />
            <Route
                exact path={'/task/list/'}
                component={(props) => <TaskList {...props} />}
            />
            <Route
                exact path={'/task/view/:uuid'}
                component={(props) => <Task {...props} />}
            />
            <Route
                exact path={'/project/list/'}
                component={(props) => <ProjectList {...props} />}
            />
            <Route
                exact path={'/project/view/:uuid'}
                component={(props) => <Project {...props} />}
            />
            <Route
                exact path={'/process/list/'}
                component={(props) => <ProcessList {...props} />}
            />
            <Route
                exact path={'/process/view/:uuid'}
                component={(props) => <Process {...props} />}
            />
            {/*<Route*/}
            {/*    exact path={'/noteList'}*/}
            {/*    component={(props) => <InvoicesList {...props} />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*    exact path={'/noteDetail'}*/}
            {/*    component={(props) => <Invoices {...props} />}*/}
            {/*/>*/}
            {/*<Route*/}
            {/*    exact path={'/main/insign/contactsList'}*/}
            {/*    component={(props) => <ContactsList {...props} />}*/}
            {/*/>*/}
            {/*    <Route*/}
            {/*        exact path={'/main/insign/contactDetail'}*/}
            {/*        component={(props) => <Contacts {...props} />}*/}
            {/*    />*/}
        </BodyDiv>
    )
}


export default ContentRoutes