import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import LoaderWrapper from "../../wrappers/LoaderWrapper";
import styled from "styled-components";

const StyledDropdown = styled(FormControl)`
  width: 100%;
`

const StyledP = styled.div`
  p {
    width: 350px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`

const SimpleDropdown = (props) => {

    const {
        classes = {},
        title = '',
        inputArray = [],
        initSelection = '',
        onSelect = (event) => {},
        selectNone = false,
        isLoaded = true,
        isDisabled = false
    } = props

    const [valueArray, setValueArray] = useState(inputArray)

    const [selectionIndex, setSelectionIndex] = useState(initSelection)

    useEffect(() => {
        setValueArray(inputArray)
    }, [inputArray])

    const handleItemSelect = (event) => {
        if (event.target.value === -1) {
            setSelectionIndex(-1)
            onSelect(-1)
        } else {
            setSelectionIndex(event.target.value)
            onSelect(valueArray[event.target.value].value)
        }
    }

    let DropdownRows = []

    if (valueArray.length === 0) {
        DropdownRows = (
            <MenuItem
                disabled
            >
                {'no items in list...'}
            </MenuItem>
        )
    } else {

        DropdownRows = valueArray.map((inputItem, i) => {
            return <MenuItem
                key={`${inputItem.value}_${i}`}
                value={i}
            >
                <StyledP><p>{inputItem.label}</p></StyledP>
            </MenuItem>

        })

        if (selectNone) {
            const index = DropdownRows.length
            DropdownRows.unshift((
                <MenuItem
                    key={`blah_${index}`}
                    value={-1}
                >
                    <StyledP><p>{'Show All'}</p></StyledP>
                </MenuItem>
            ))
        }
    }

    return (
            <LoaderWrapper isLoaded={isLoaded}>
                <StyledDropdown className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                    <Select

                        labelId="demo-simple-select-label"
                        inputProps={{ 'aria-label': 'Without label' }}
                        id="demo-simple-select"
                        value={selectionIndex}
                        onChange={handleItemSelect}
                        // displayEmpty
                        disabled={isDisabled}


                    >
                        { DropdownRows }
                    </Select>
                </StyledDropdown>
            </LoaderWrapper>
    )

}

export default SimpleDropdown